import kubernetesLogo from '@iso/assets/images/icon/kubernetes-logo.png';
// import projectLogo from '../../assets/images/icon/project-icon.svg';
import projectLogo from '@iso/assets/images/icon/deployment-logo.png';
import deploymentLogo from '@iso/assets/images/icon/environment-icon.svg';
import logoFav from '@iso/assets/images/logoFav.svg';
import atmoslyLogo from '@iso/assets/images/atmoslyLogo.svg';
import refreshButton from '@iso/assets/images/refresh-button.svg';
import awsIcon from '@iso/assets/images/aws.svg';
import { enums, timeSince } from './helper';
import { dummyYamlDifference } from './tryWithoutLoginYamlDifference';


export const dummyProfileData = {
  id: "",
  firstName: "Guest",
  lastName: "User",
  email: "guest.user@atmosly.com",
  phone: "",
  organisation: "atmosly",
  roles: ["guest"],
  super: false,
  permissions: [
    // "Can change user",
    // "Can delete user",
    // "Can view user",
    // "Can invite user",
    "Can view dashboard",
    // "Can add role",
    // "Can change role",
    // "Can delete role",
    // "Can view role",
    // "Can assign role",
    // "Can delete cluster",
    "Can view cluster",
    // "Can create cluster",
    // "Can do security scan",
    // "Can integrate account",
    "Can view integrate account",
    // "Can delete integrate account",
    "Can view notifications subscription",
    // "Can edit notifications subscription",
    // "Can integrate channel",
    "Can view integrated channel",
    // "Can add project",
    // "Can delete project",
    "Can view project",
    // "Can deploy project",
    // "Can delete environment",
    // "Can add pipeline",
    "Can view pipeline",
    // "Can manage marketplace",
    // "Can only create, update and delete marketplace repositories",
    "Can view marketplace",
    "Can view security scan",
    "Can view guardrail"
  ]
}

export const dummyDashboardData = {
  integrationData: {
    data: {
      integration_details: [
        {
          name: "AWS",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          integration_id: 1,
          count: 1
        },
        {
          name: "Azure",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/azure.svg",
          integration_id: 2,
          count: 0
        },
        {
          name: "GCP",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/gcp.svg",
          integration_id: 3,
          count: 0
        },
        {
          name: "GitHub",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/github.svg",
          integration_id: 4,
          count: 1
        },
        {
          name: "Bitbucket",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/bitbucket.svg",
          integration_id: 9,
          count: 0
        },
        {
          name: "GitLab",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/gitlab.svg",
          integration_id: 5,
          count: 0
        },
        {
          name: "DockerHub",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg",
          integration_id: 6,
          count: 1
        },
        {
          name: "ECR",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg",
          integration_id: 7,
          count: 1
        },
        {
          name: "CloudFlare",
          logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/cloudflare.svg",
          integration_id: 8,
          count: 0
        }
      ],
      total_cost: "0",
    }
  },
  clusterWidget: [
    {
      number: 2,
      text: 'Clusters',
      icon: kubernetesLogo,
      width: "21px",
      height: "21px",
    },
    {
      number: 3,
      text: 'Projects',
      icon: projectLogo,
      width: "21px",
      height: "21px",
    },
    {
      number: 1,
      text: 'Environments',
      icon: deploymentLogo,
      width: "18px",
      height: "19px",
    }
  ],
  saleWidget: [
    {
      label: 'widget.cost.label',
      price: `$0`,
      details: 'widget.cost.details',
      fontColor: '#F75D81',
    },
  ],
  dataSource: [
    {
      id: 0,
      key: 0,
      firstName: "Emily",
      lastName: "Dickinson",
      role: ["read-only"]
    },
    {
      id: 1,
      key: 1,
      firstName: "Raj",
      lastName: "Verma",
      role: ["read-only"]
    },
  ],
  pipelineSources: [
    {
      id: 0,
      key: 0,
      buildNo: "xgsibgca",
      releaseNumber: "B5C97949fF",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      service: "frontend",
      cluster: "web-apps",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "This is my first commit",
      commitId: "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
    },
    {
      id: 1,
      key: 1,
      buildNo: "nhtibgf",
      releaseNumber: "3FdBC7e715",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      service: "backend",
      cluster: "web-apps",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Added fix for signin.",
      commitId: "deec0004e871ae24a30e1bd49f20cebd703ba072",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
    },
    {
      id: 2,
      key: 2,
      buildNo: "nhtibgf",
      releaseNumber: "7KdBA8v686",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      service: "wordpress",
      cluster: "web-apps",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Fixed table format",
      commitId: "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
    },
    {
      id: 3,
      key: 3,
      buildNo: "nhtibgf",
      releaseNumber: "34FGB7e237",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      service: "fixture-app",
      cluster: "web-apps",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Fixed localhost changes.",
      commitId: "lnvv0967f297lo24a30e1bd49f20byls703lv592",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
    },
    {
      id: 4,
      key: 4,
      buildNo: "nhtibgf",
      releaseNumber: "3BdCc0x082",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      service: "delivery-app",
      cluster: "web-apps",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "optimized the components",
      commitId: "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
    },
  ],
}

export const dummyIntegrationData = {
  allData: [
    {
      id: 8,
      org: 2,
      integration: 1,
      status: true,
      created_at: "2024-02-19T13:03:45.889908Z",
      updated_at: "2024-02-19T13:03:45.889908Z",
      created_by: "Abhishek Sahu",
      integration_type: "aws",
      alias: "atmosly-devops",
      region: "",
      category_id: 1,
      account_id: "897398831588",
      category: "Cloud Account",
      integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg"
    },
    {
      id: 10,
      org: 2,
      integration: 4,
      status: true,
      created_at: "2024-02-19T13:03:45.889908Z",
      updated_at: "2024-02-19T13:03:45.889908Z",
      created_by: "Abhishek Sahu",
      integration_type: "github",
      alias: "abhi-dev91",
      region: "",
      category_id: 2,
      account_id: null,
      category: "Version Control System",
      integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/github.svg"
    },
    {
      id: 9,
      org: 2,
      integration: 7,
      status: true,
      created_at: "2024-02-19T13:03:45.889908Z",
      updated_at: "2024-02-19T13:03:45.889908Z",
      created_by: "Abhiram Nair",
      integration_type: "ecr",
      alias: "atmosly-devops",
      region: "us-east-2",
      category_id: 3,
      account_id: "897398831588",
      category: "Container Registry",
      integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
    },
    {
      id: 11,
      org: 2,
      integration: 8,
      status: true,
      created_at: "2024-02-19T13:03:45.889908Z",
      updated_at: "2024-02-19T13:03:45.889908Z",
      created_by: "Riya Gupta",
      integration_type: "cloudflare",
      alias: [
        "atmosly.com",
        "atmosly.in"
      ],
      region: "",
      category_id: 4,
      account_id: null,
      category: "Domain Registrar",
      integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/cloudflare.svg"
    },
    {
      id: 3,
      org: 2,
      integration: 4,
      status: false,
      created_at: "2024-02-19T13:03:45.889908Z",
      updated_at: "2024-02-19T13:03:45.889908Z",
      created_by: "Vaibhav Dashore",
      integration_type: "github",
      alias: "vaibhav023",
      region: "",
      category_id: 2,
      account_id: null,
      category: "Version Control System",
      integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/github.svg"
    },
    {
      id: 2,
      org: 2,
      integration: 4,
      status: false,
      created_at: "2024-02-19T13:03:45.889908Z",
      updated_at: "2024-02-19T13:03:45.889908Z",
      created_by: "Ankush Madaan",
      integration_type: "github",
      alias: "4ankush",
      region: "",
      category_id: 2,
      account_id: null,
      category: "Version Control System",
      integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/github.svg"
    },
    {
      id: 28,
      key: 2,
      integration: 7,
      status: true,
      created_at: "2024-02-08T07:26:29.666411Z",
      updated_at: "2024-02-08T07:26:29.666411Z",
      created_by: "Raj Verma",
      integration_type: "ecr",
      alias: "atmosly-devops",
      region: "us-east-2",
      category_id: 3,
      account_id: 897398831588,
      category: "Container Registry",
      integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg"
    },
  ],
  dataSources: [
    {
      id: 0,
      key: 0,
      platform: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      accountDetails: "897398831588 (atmosly-devops)",
      createdAt: "2024-02-19T13:03:45.889908Z",
      updatedAt: "2024-02-19T13:03:45.889908Z",
      createdBy: "Raj Verma",
      organisation: "",
      status: 'Connected',
    },
    {
      id: 0,
      key: 0,
      platform: "https://atmosly-media.s3.amazonaws.com/integration/logos/github.svg",
      accountDetails: "raj-verma",
      createdAt: "2024-02-19T13:03:45.889908Z",
      updatedAt: "2024-02-19T13:03:45.889908Z",
      createdBy: "Raj Verma",
      organisation: "",
      status: 'Connected',
    },
    {
      id: 0,
      key: 0,
      platform: "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg",
      accountDetails: "raj-verma",
      createdAt: "2024-02-19T13:03:45.889908Z",
      updatedAt: "2024-02-19T13:03:45.889908Z",
      createdBy: "Raj Verma",
      organisation: "",
      status: 'Connected',
    },
    {
      id: 0,
      key: 0,
      platform: "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg",
      accountDetails: "897398831588 (atmosly-devops)",
      region: "us-east-2",
      createdAt: "2024-02-19T13:03:45.889908Z",
      updatedAt: "2024-02-19T13:03:45.889908Z",
      createdBy: "Raj Verma",
      organisation: "",
      status: 'Connected',
    },
    {
      id: 0,
      key: 0,
      platform: "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg",
      accountDetails: "897398831588 (atmosly-devops)",
      region: "us-east-1",
      createdAt: "2024-02-19T13:03:45.889908Z",
      updatedAt: "2024-02-19T13:03:45.889908Z",
      createdBy: "Raj Verma",
      organisation: "",
      status: 'Disconnected',
    }
  ],
  integrationCount: {
    aws: 1,
    azure: 0,
    gcp: 0,
    github: 1,
    gitlab: 0,
    dockerhub: 1,
    ecr: 0,
    cloudflare: 0,
  },
  stickerWidget: [
    {
      name: "aws",
      text: "AWS",
      subText: "Connect your AWS account to securely and quickly create/manage your infrastructure. <b><a>Click here</a></b> to know more.",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      navigate: "/integrations/aws",
      cloudProvidersData: {
        id: 1,
        name: "AWS",
        type: "aws",
        category: 1,
        description: "Connect your AWS account to securely and quickly create/manage your infrastructure. <b><a>Click here</a></b> to know more.",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg"
      },
    },
    {
      name: "azure",
      text: "Azure",
      subText: "Connect your Azure account to securely and quickly create/manage your infrastructure. <b><a>Click here</a></b> to know more.",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/azure.svg",
      navigate: "/integrations/azure",
      cloudProvidersData: {
        id: 2,
        name: "Azure",
        type: "azure",
        category: 1,
        description: "Connect your Azure account to securely and quickly create/manage your infrastructure. <b><a>Click here</a></b> to know more.",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/azure.svg"
      },
    },
    {
      name: "gcp",
      text: "GCP",
      subText: "Connect your GCP account to securely and quickly create/manage your infrastructure. <b><a>Click here</a></b> to know more.",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/gcp.svg",
      navigate: "/integrations/gcp",
      cloudProvidersData: {
        id: 3,
        name: "GCP",
        type: "gcp",
        category: 1,
        description: "Connect your GCP account to securely and quickly create/manage your infrastructure. <b><a>Click here</a></b> to know more.",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/gcp.svg"
      },
    },
    {
      name: "github",
      text: "GitHub",
      subText: "Connect to GitHub to deploy your code.",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/github.svg",
      navigate: "/integrations/github",
      cloudProvidersData: {
        id: 4,
        name: "GitHub",
        type: "github",
        category: 2,
        description: "Connect to GitHub to deploy your code.",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/github.svg"
      },
    },
    {
      name: "bitbucket",
      text: "Bitbucket",
      subText: "Connect to Bitbucket to deploy your code.",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/bitbucket.svg",
      navigate: "/integrations/bitbucket",
      cloudProvidersData: {
        id: 9,
        name: "Bitbucket",
        type: "bitbucket",
        category: 2,
        description: "Connect to Bitbucket to deploy your code.",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/bitbucket.svg"
      },
    },
    {
      name: "gitlab",
      text: "GitLab",
      subText: "Connect to GitLab to deploy your code.",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/gitlab.svg",
      navigate: "/integrations/github",
      cloudProvidersData: {
        id: 5,
        name: "GitLab",
        type: "gitlab",
        category: 2,
        description: "Connect to GitLab to deploy your code.",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/gitlab.svg"
      },
    },
    {
      name: "dockerhub",
      text: "DockerHub",
      subText: "Manage container images by connecting to DockerHub",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg",
      navigate: "/integrations/dockerhub",
      cloudProvidersData: {
        id: 6,
        name: "DockerHub",
        type: "dockerhub",
        category: 3,
        description: "Manage container images by connecting to DockerHub",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg"
      }
    },
    {
      name: "ecr",
      text: "ECR",
      subText: "Manage container images by connecting to ECR",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg",
      navigate: "/integrations/ecr",
      cloudProvidersData: {
        id: 7,
        name: "ECR",
        type: "ecr",
        category: 3,
        description: "Manage container images by connecting to ECR",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
      }
    },
    {
      name: "cloudflare",
      text: "CloudFlare",
      subText: "Experience seamless DNS management by connecting to Cloudflare.",
      icon: "https://atmosly-media.s3.amazonaws.com/integration/logos/cloudflare.svg",
      navigate: "/integrations/cloudflare",
      cloudProvidersData: {
        id: 8,
        name: "CloudFlare",
        type: "cloudflare",
        category: 4,
        description: "Experience seamless DNS management by connecting to Cloudflare.",
        logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/cloudflare.svg"
      }
    }
  ]
}

export const dummyClusterData = {
  clusterData: [
    {
      id: 0,
      title: "897398831588",
      name: "web-apps",
      region: "us-east-1",
      type: "prod",
      isStarred: false,
      updatedAt: "2024-02-20T13:03:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:03:45.889908Z"))} ago`,
      createdAt: "2024-02-20T13:03:45.889908Z",
      createdBy: "Raj Verma",
      status: "BOOTSTRAP_INITIALIZED",   // BOOTSTRAP_INITIALIZED
      version: "1.27",
      img: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      organisation: "",
      userManagedNodegroupsExist: false
    },
    {
      id: 1,
      title: "897398831588",
      name: "app-server",
      region: "ap-south-1",
      type: "non-prod",
      isStarred: false,
      updatedAt: "2024-02-20T13:03:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:03:45.889908Z"))} ago`,
      createdAt: "2024-02-20T13:03:45.889908Z",
      createdBy: "Emily Dickinson",
      status: "BOOTSTRAP_INITIALIZED",   // CLUSTER_FAILED
      version: "1.27",
      img: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      organisation: "",
      userManagedNodegroupsExist: false
    },
    {
      id: 2,
      title: "897398831588",
      name: "observability_cluster",
      region: "us-west-2",
      type: "prod",
      isStarred: false,
      updatedAt: "2024-02-21T01:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T01:05:45.889908Z"))} ago`,
      createdAt: "2024-02-21T01:05:45.889908Z",
      createdBy: "Emily Dickinson",
      status: "CLUSTER_DESTROYED",   // CLUSTER_FAILED
      version: "1.26",
      img: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      organisation: "",
      userManagedNodegroupsExist: false
    },
  ],
  clusterOverview: {
    0: {
      data: {
        integration_type: 1,
        display_name: "web-apps",
        cluster_data: {
          cluster_name: "prod-web-apps",
          cluster_endpoint: "https://CDA89A32ADC03CUGBV3C53A7AC7F32999.gr7.us-east-1.eks.amazonaws.com",
          cluster_endpoint_public_access: true,
          openid_connect_url: "https://oidc.eks.us-east-1.amazonaws.com/id/CDA89A32ADC03CUGBV3C53A7AC7F32999",
          cluster_arn: "arn:aws:eks:us-east-1:897398831588:cluster/prod-web-apps",
          vpc_id: "vpc-04c79d92436df7fc1",
          vpc_cidr_block: "10.0.0.0/16",
          kubernetes_version: "1.27",
          cluster_iam_role_arn: "arn:aws:iam::897398831588:role/ATMOSLY-f71afe66-c692-41fe-b69c-53ce6eded228",
          environment: "prod",
          name: "Dummy 1",
          region: "us-east-1",
          kms_key_arn: "arn:aws:kms:us-east-1:897398831588:key/79c10e77-ab17-4c3b-9732-9df0150c5706",
          kms_policy_arn: "arn:aws:iam::897398831588:policy/prod-web-apps-kubernetes-pvc-kms-policy",
          worker_iam_role_name: "prod-web-apps-node-role",
          tags: [{
            "name": "prod",
            "value": "true"
          }],
          private_subnet_ids: [
            "subnet-063990851ff0a310c",
            "subnet-0de91a4ad6998edb0"
          ],
          database_subnet_ids: "['subnet-063f5d35e6d65e5a4', 'subnet-00b328e88195ed0b2']",
          worker_node_security_group_id: "sg-0febd962d97d4a901",
          dynamo_db_table: "atmosly-dynamodb-lock-897398831588",
          display_name: "web-apps",
          created: "2024-02-20T13:03:45.889908Z",
          tfstate_bucket: "atmosly-tfstate-897398831588",
          service_ipv4_range: "",
          created_at: "2024-02-20T13:03:45.889908Z",
          availability_zones: [
            "us-east-1a",
            "us-east-1b"
          ],
          kubernetes_log_retention_period: "-",
          vpc_flow_log_retention_period: "-",
          vpc_flow_logs: "false"
        },
        addons_list: [
          {
            name: "Nginx Ingress Controller",
            description: "Handles routing of external HTTP/S traffic to kubernetes services.",
            is_recommended: false,
            value: "a70b15e1e164849d79c6a83699e11b93-c815c54780ce3840.elb.us-east-1.amazonaws.com"
          },
          {
            name: "Cert Manager",
            description: "Automates management and issuance of TLS certificates within your cluster",
            is_recommended: false,
            value: "true"
          },
          {
            name: "ArgoFlow",
            description: "Argo Workflow + ArgoCD",
            is_recommended: true,
            value: "true"
          },
          {
            name: "PGL stack",
            description: "Contains Prometheus for monitoring, Grafana for visualization and Loki for log aggregation.",
            is_recommended: true,
            value: {
              loki: "true",
              cloudwatch_collector: "false",
              s3_object_expiration: "90",
              dashboard_refresh_interval: "30s",
              url: "12march-test-host-50e5b46d92.atmosly.in",
              password: "wPnRkAcGeLk2BRp9gasc",
              username: "admin"
            }
          }
        ],
        status: "BOOTSTRAP_INITIALIZED",
        created_by: "raj.verma@atmosly.com",
        updated_at: "2024-02-20T13:03:45.889908Z",
        target_account_id: "897398831588",
        target_account_alias: "atmosly-devops",
      }
    },
    1: {
      data: {
        integration_type: 1,
        display_name: "app-server",
        cluster_data: {
          cluster_name: "non-prod-app-server",
          cluster_endpoint: "https://CDA89A32ADC03CECA3C53A7AC7F32988.gr7.ap-south-1.eks.amazonaws.com",
          cluster_endpoint_public_access: true,
          openid_connect_url: "https://oidc.eks.ap-south-1.amazonaws.com/id/CDA89A32ADC03CECA3C53A7AC7F32988",
          cluster_arn: "arn:aws:eks:ap-south-1:897398831588:cluster/non-prod-app-server",
          vpc_id: "vpc-04c79d92436df7fc1",
          vpc_cidr_block: "10.0.0.0/16",
          kubernetes_version: "1.27",
          cluster_iam_role_arn: "arn:aws:iam::897398831588:role/ATMOSLY-f71afe66-c692-41fe-b69c-53ce6eded228",
          environment: "non-prod",
          name: "app-server",
          region: "ap-south-1",
          kms_key_arn: "arn:aws:kms:ap-south-1:897398831588:key/79c10e77-ab17-4c3b-9732-9df0150c5706",
          kms_policy_arn: "arn:aws:iam::897398831588:policy/non-prod-app-server-kubernetes-pvc-kms-policy",
          worker_iam_role_name: "non-prod-app-server-node-role",
          tags: [{
            "name": "non-prod",
            "value": "true"
          }],
          private_subnet_ids: [
            "subnet-063990851ff0a310c",
            "subnet-0de91a4ad6998edb0"
          ],
          database_subnet_ids: "['subnet-063f5d35e6d65e5a4', 'subnet-00b328e88195ed0b2']",
          worker_node_security_group_id: "sg-0febd962d97d4a901",
          dynamo_db_table: "atmosly-dynamodb-lock-897398831588",
          display_name: "app-server",
          created: "2024-02-20T13:03:45.889908Z",
          tfstate_bucket: "atmosly-tfstate-897398831588",
          service_ipv4_range: "",
          created_at: "2024-02-20T13:03:45.889908Z",
          availability_zones: [
            "ap-south-1a",
            "ap-south-1b"
          ],
          kubernetes_log_retention_period: "-",
          vpc_flow_log_retention_period: "-",
          vpc_flow_logs: "false"
        },
        addons_list: [
          {
            name: "Ingress ALB Controller",
            description: "Manages AWS ALB instances for routing external traffic to services.",
            is_recommended: false,
            value: "k8s-appservices-df824b645c-1302907514.us-east-2.elb.amazonaws.com"
          },
          {
            name: "ArgoFlow",
            description: "Argo Workflow + ArgoCD",
            is_recommended: true,
            value: "true"
          },
        ],
        status: "BOOTSTRAP_INITIALIZED",
        created_by: "emily@atmosly.com",
        updated_at: "2024-02-20T13:03:45.889908Z",
        target_account_id: "897398831588",
        target_account_alias: "atmosly-devops",
      }
    },
    2: {
      data: {
        integration_type: 1,
        display_name: "observability_cluster",
        cluster_data: {
          cluster_name: "prod-observability_cluster",
          cluster_endpoint: "https://CDA89A32ADC03CECA3C53A7AC7F32988.gr7.us-west-2.eks.amazonaws.com",
          cluster_endpoint_public_access: true,
          openid_connect_url: "https://oidc.eks.us-west-2.amazonaws.com/id/DAC89A32ADC03CECA3C53A7AC7F56799",
          cluster_arn: "arn:aws:eks:us-west-2:897398831588:cluster/prod-observability_cluster",
          vpc_id: "vpc-04c79d92436df7fc1",
          vpc_cidr_block: "10.0.0.0/16",
          kubernetes_version: "1.26",
          cluster_iam_role_arn: "arn:aws:iam::897398831588:role/ATMOSLY-g51afe68-c599-51fy-h67j-44yu6ehyh556",
          environment: "prod",
          name: "observability_cluster",
          region: "us-west-2",
          kms_key_arn: "arn:aws:kms:us-west-2:897398831588:key/79c10e77-ab17-4c3b-9732-9df0150c5706",
          kms_policy_arn: "arn:aws:iam::897398831588:policy/prod-observability_cluster-kubernetes-pvc-kms-policy",
          worker_iam_role_name: "prod-observability_cluster-node-role",
          tags: [{
            "name": "prod",
            "value": "true"
          }],
          private_subnet_ids: [
            "subnet-456990851ff0a544p",
            "subnet-8fr91a4ad6998egt6"
          ],
          database_subnet_ids: "['subnet-456990851ff0a544p', 'subnet-8fr91a4ad6998egt6']",
          worker_node_security_group_id: "sg-0febd962d97d4a901",
          dynamo_db_table: "atmosly-dynamodb-lock-897398831588",
          display_name: "observability_cluster",
          created: "2024-02-21T01:05:45.889908Z",
          tfstate_bucket: "atmosly-tfstate-897398831588",
          service_ipv4_range: "",
          created_at: "2024-02-21T01:05:45.889908Z",
          availability_zones: [
            "us-west-2a",
            "us-west-2b"
          ],
          kubernetes_log_retention_period: "-",
          vpc_flow_log_retention_period: "-",
          vpc_flow_logs: "false"
        },
        addons_list: [
          {
            name: "Ingress ALB Controller",
            description: "Manages AWS ALB instances for routing external traffic to services.",
            is_recommended: false,
            value: "k8s-appservices-df824b645c-1302907514.us-east-2.elb.amazonaws.com"
          }
        ],
        status: "CLUSTER_FAILED",
        created_by: "emily@atmosly.com",
        updated_at: "2024-02-21T01:05:45.889908Z",
        target_account_id: "897398831588",
        target_account_alias: "atmosly-devops",
      }
    }
  }
}

export const dummyNodegroupsList = {
  0: [
    {
      name: "atmosly-addons",
      uniqueId: 0,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "aws-managed",
      status: "Active",
      familyInstanceType: "t3",
      instanceType: "t3.medium",
      size: "3",
      discSize: "50",
      capacityType: enums["ON_DEMAND"],
      minNodSize: "1",
      maxNodeSize: "5",
      labels: [
        {
          name: "Infra-services",
          value: "true",
        },
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 0,
      createdBy: "atmosly-bot",
      toggleOpen: false
    },
    {
      name: "default",
      uniqueId: 1,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "karpenter-managed",
      status: "Active",
      familyInstanceType: "",
      instanceType: "",
      size: "",
      discSize: "",
      capacityType: enums["SPOT"],
      minNodSize: "",
      maxNodeSize: "",
      labels: [
        {
          name: "application",
          value: "xyz"
        }
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 1,
      createdBy: "atmosly-bot",
      toggleOpen: false
    },
    {
      name: "observability",
      uniqueId: 2,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "karpenter-managed",
      status: "Active",
      familyInstanceType: ["t3"],
      instanceType: [
        "t3.nano",
        "t3.micro",
        "t3.small",
        "t3.medium"
      ],
      size: "",
      discSize: "50",
      capacityType: enums["ON_DEMAND"],
      minNodSize: "",
      maxNodeSize: "",
      labels: [
        {
          name: "Monitor-Services",
          value: "true"
        }
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 0,
      createdBy: "atmosly-bot",
      toggleOpen: false
    },
    {
      name: "edge-service",
      uniqueId: 3,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "aws-managed",
      status: "Active",
      familyInstanceType: "t3",
      instanceType: "t3.small",
      size: "3",
      discSize: "50",
      capacityType: enums["ON_DEMAND"],
      minNodSize: "1",
      maxNodeSize: "5",
      labels: [
        {
          name: "infra-services",
          value: "false",
        },
        {
          name: "member",
          value: "true"
        }
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 2,
      createdBy: "Raj Verma",
      toggleOpen: false
    },
    {
      name: "compute-heavy",
      uniqueId: 4,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "aws-managed",
      status: "Failed",
      familyInstanceType: "t3",
      instanceType: "t3.large",
      size: "5",
      discSize: "20",
      capacityType: enums["SPOT"],
      minNodSize: "0",
      maxNodeSize: "2",
      labels: [
        {
          name: "node",
          value: "prod",
        },
        {
          name: "type",
          value: "aws-managed"
        }
      ],
      createdAt: "2024-02-20T13:07:45.889908Z",
      updatedAt: "2024-02-20T13:07:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:07:45.889908Z"))} ago`,
      managedBy: 2,
      createdBy: "Raj Verma",
      toggleOpen: false
    },
    {
      name: "dynamo",
      uniqueId: 5,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "karpenter-managed",
      status: "Failed",
      familyInstanceType: ["t3", "t3a"],
      instanceType: ["t3.large", "t3a.small"],
      size: "",
      discSize: "20",
      capacityType: enums["SPOT"],
      minNodSize: "",
      maxNodeSize: "",
      labels: [
        {
          name: "node",
          value: "prod",
        },
        {
          name: "type",
          value: "aws-managed"
        }
      ],
      createdAt: "2024-02-20T13:07:45.889908Z",
      updatedAt: "2024-02-20T13:07:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:07:45.889908Z"))} ago`,
      managedBy: 2,
      createdBy: "Raj Verma",
      toggleOpen: false
    },
  ],
  1: [
    {
      name: "atmosly-addons",
      uniqueId: 0,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "aws-managed",
      status: "Active",
      familyInstanceType: "t3",
      instanceType: "t3.medium",
      size: "3",
      discSize: "50",
      capacityType: enums["ON_DEMAND"],
      minNodSize: "1",
      maxNodeSize: "5",
      labels: [
        {
          name: "Infra-services",
          value: "true",
        },
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 0,
      createdBy: "atmosly-bot",
      toggleOpen: false
    },
    {
      name: "default",
      uniqueId: 1,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "karpenter-managed",
      status: "Active",
      familyInstanceType: "",
      instanceType: "",
      size: "",
      discSize: "",
      capacityType: enums["SPOT"],
      minNodSize: "",
      maxNodeSize: "",
      labels: [
        {
          name: "application",
          value: "xyz"
        }
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 1,
      createdBy: "atmosly-bot",
      toggleOpen: false
    },
    // {
    //   name: "observability",
    //   uniqueId: 2,
    //   arn: "",
    //   k8sVersion: "1.27",
    //   nodegroupType: "karpenter-managed",
    //   status: "Active",
    //   familyInstanceType: ["t3"],
    //   instanceType: [
    //     "t3.nano",
    //     "t3.micro",
    //     "t3.small",
    //     "t3.medium"
    //   ],
    //   size: "",
    //   discSize: "50",
    //   capacityType: enums["ON_DEMAND"],
    //   minNodSize: "",
    //   maxNodeSize: "",
    //   labels: [
    //     {
    //       name: "Monitor-Services",
    //       value: "true"
    //     }
    //   ],
    //   createdAt: "2024-02-20T13:05:45.889908Z",
    //   updatedAt: "2024-02-20T13:05:45.889908Z",
    //   calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    //   managedBy: 0,
    //   createdBy: "atmosly-bot",
    //   toggleOpen: false
    // },
    {
      name: "release-group",
      uniqueId: 3,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "aws-managed",
      status: "Failed",
      familyInstanceType: "t3",
      instanceType: "t3.small",
      size: "3",
      discSize: "50",
      capacityType: enums["ON_DEMAND"],
      minNodSize: "1",
      maxNodeSize: "5",
      labels: [
        {
          name: "catalyst",
          value: "false",
        },
        {
          name: "member",
          value: "true"
        }
      ],
      createdAt: "2024-02-20T13:08:45.889908Z",
      updatedAt: "2024-02-20T13:08:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:08:45.889908Z"))} ago`,
      managedBy: 2,
      createdBy: "Emily Dickinson",
      toggleOpen: false
    },
    {
      name: "data-store",
      uniqueId: 4,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "aws-managed",
      status: "Failed",
      familyInstanceType: "t3",
      instanceType: "t3.large",
      size: "5",
      discSize: "20",
      capacityType: enums["SPOT"],
      minNodSize: "0",
      maxNodeSize: "2",
      labels: [
        {
          name: "catalyst",
          value: "true",
        },
        {
          name: "type",
          value: "aws-managed"
        }
      ],
      createdAt: "2024-02-20T13:09:45.889908Z",
      updatedAt: "2024-02-20T13:09:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:09:45.889908Z"))} ago`,
      managedBy: 2,
      createdBy: "Emily Dickinson",
      toggleOpen: false
    }
  ],
  2: [
    {
      name: "atmosly-addons",
      uniqueId: 0,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "aws-managed",
      status: "Active",
      familyInstanceType: "t3",
      instanceType: "t3.medium",
      size: "3",
      discSize: "50",
      capacityType: enums["ON_DEMAND"],
      minNodSize: "1",
      maxNodeSize: "5",
      labels: [
        {
          name: "Infra-services",
          value: "true",
        },
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 0,
      createdBy: "atmosly-bot",
      toggleOpen: false
    },
    {
      name: "default",
      uniqueId: 1,
      arn: "",
      k8sVersion: "1.27",
      nodegroupType: "karpenter-managed",
      status: "Active",
      familyInstanceType: "",
      instanceType: "",
      size: "",
      discSize: "",
      capacityType: enums["SPOT"],
      minNodSize: "",
      maxNodeSize: "",
      labels: [
        {
          name: "application",
          value: "xyz"
        }
      ],
      createdAt: "2024-02-20T13:05:45.889908Z",
      updatedAt: "2024-02-20T13:05:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
      managedBy: 1,
      createdBy: "atmosly-bot",
      toggleOpen: false
    },
    // {
    //   name: "observability",
    //   uniqueId: 2,
    //   arn: "",
    //   k8sVersion: "1.27",
    //   nodegroupType: "karpenter-managed",
    //   status: "Active",
    //   familyInstanceType: ["t3"],
    //   instanceType: [
    //     "t3.nano",
    //     "t3.micro",
    //     "t3.small",
    //     "t3.medium"
    //   ],
    //   size: "",
    //   discSize: "50",
    //   capacityType: enums["ON_DEMAND"],
    //   minNodSize: "",
    //   maxNodeSize: "",
    //   labels: [
    //     {
    //       name: "Monitor-Services",
    //       value: "true"
    //     }
    //   ],
    //   createdAt: "2024-02-20T13:05:45.889908Z",
    //   updatedAt: "2024-02-20T13:05:45.889908Z",
    //   calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    //   managedBy: 0,
    //   createdBy: "atmosly-bot",
    //   toggleOpen: false
    // },
    {
      name: "stream-flow",
      uniqueId: 3,
      arn: "",
      k8sVersion: "1.26",
      nodegroupType: "aws-managed",
      status: "Failed",
      familyInstanceType: "t3",
      instanceType: "t3.large",
      size: "3",
      discSize: "50",
      capacityType: enums["SPOT"],
      minNodSize: "0",
      maxNodeSize: "2",
      labels: [
        {
          name: "catalyst",
          value: "false",
        },
        {
          name: "member",
          value: "true"
        }
      ],
      createdAt: "2024-02-21T01:08:45.889908Z",
      updatedAt: "2024-02-21T01:08:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T01:08:45.889908Z"))} ago`,
      managedBy: 2,
      createdBy: "Emily Dickinson",
      toggleOpen: false
    },
    {
      name: "quartz",
      uniqueId: 4,
      arn: "",
      k8sVersion: "1.26",
      nodegroupType: "aws-managed",
      status: "Failed",
      familyInstanceType: "t3",
      instanceType: "t3.medium",
      size: "2",
      discSize: "50",
      capacityType: enums["SPOT"],
      minNodSize: "2",
      maxNodeSize: "5",
      labels: [
        {
          name: "catalyst",
          value: "true",
        },
        {
          name: "type",
          value: "aws-managed"
        }
      ],
      createdAt: "2024-02-21T01:08:45.889908Z",
      updatedAt: "2024-02-21T01:08:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T01:08:45.889908Z"))} ago`,
      managedBy: 2,
      createdBy: "Emily Dickinson",
      toggleOpen: false
    }
  ],
}

export const dummyClusterLogsHeadings = [
  [
    {
      "id": 1000,
      "job_name": "cluster-1518-5ts6w",
      "job_type": "create_cluster",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "job_status": "Completed",
      "created_by": null
    },
    {
      "id": 1001,
      "job_name": "bootstrap-cluster-1518-5ts6w",
      "job_type": "create_bootstrap",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "job_status": "Completed",
      "created_by": null
    },
    {
      "id": 1002,
      "job_name": "nodegroup--002-yqkxo",
      "job_type": "create_nodegroup",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "job_status": "Completed",
      "created_by": null
    }
  ],
  [
    {
      "id": 1000,
      "job_name": "cluster-1518-5ts6w",
      "job_type": "create_cluster",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "job_status": "Completed",
      "created_by": null
    },
    {
      "id": 1001,
      "job_name": "bootstrap-cluster-1518-5ts6w",
      "job_type": "create_bootstrap",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "job_status": "Completed",
      "created_by": null
    },
    {
      "id": 1002,
      "job_name": "nodegroup--002-yqkxo",
      "job_type": "create_nodegroup",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "job_status": "Completed",
      "created_by": null
    }
  ]
]

export const dummyMarketplaceListData = {
  0: {
    "atmosly-managed": [
      {
        id: "xn35gt5t",
        chartProvider: "atmosly",
        chartName: "apm-attacher",
        appName: "atmosly-helm",
        chartType: "atmosly-managed",
        status: "DEPLOYED",
        appHealth: "HEALTHY",
        logo: "",
        url: "https://helm.elastic.co",
        version: "1.0.0",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
    ],
    others: [
      {
        id: "3bo89d5s",
        chartProvider: "bitnami",
        chartName: "apache",
        appName: "feature-flag-service",
        chartType: "others",
        status: "DEPLOYED",
        appHealth: "HEALTHY",
        logo: "",
        url: "https://charts.bitnami.com/bitnami",
        version: "16.8.3",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
      {
        id: "jb32k45c",
        chartProvider: "bitnami",
        chartName: "airflow",
        appName: "trace-collector",
        chartType: "others",
        status: "DEPLOY_FAILED",
        appHealth: "UNHEALTHY",
        logo: "",
        url: "https://charts.bitnami.com/bitnami",
        version: "10.6.2",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
    ],
    "user-managed": [
      {
        id: "2e34v445",
        chartProvider: "bitnami",
        chartName: "common",
        appName: "system-health",
        chartType: "user-managed",
        status: "DEPLOY_FAILED",
        appHealth: "UNHEALTHY",
        logo: "",
        url: "https://charts.bitnami.com/bitnami",
        version: "2.16.1",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
    ],
  },
  1: {
    "atmosly-managed": [
      {
        id: "2bhn8nds",
        chartProvider: "atmosly",
        chartName: "apm-attacher",
        appName: "load-tester",
        chartType: "atmosly-managed",
        status: "DEPLOYED",
        appHealth: "HEALTHY",
        logo: "",
        url: "https://helm.elastic.co",
        version: "1.0.0",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
    ],
    others: [
      {
        id: "d45cjd3a",
        chartProvider: "bitnami",
        chartName: "apache",
        appName: "inventory-service",
        chartType: "others",
        status: "DEPLOYED",
        appHealth: "HEALTHY",
        logo: "",
        url: "https://charts.bitnami.com/bitnami",
        version: "16.8.3",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
      {
        id: "9cc54fss",
        chartProvider: "bitnami",
        chartName: "airflow",
        appName: "data-lake",
        chartType: "others",
        status: "DEPLOY_FAILED",
        appHealth: "UNHEALTHY",
        logo: "",
        url: "https://charts.bitnami.com/bitnami",
        version: "10.6.2",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
    ],
    "user-managed": [
      {
        id: "fvf6n4ks",
        chartProvider: "bitnami",
        chartName: "common",
        appName: "etl-jobs",
        chartType: "user-managed",
        status: "DEPLOY_FAILED",
        appHealth: "UNHEALTHY",
        logo: "",
        url: "https://charts.bitnami.com/bitnami",
        version: "2.16.1",
        namespaceName: "atmosly.in",
        deployDate: "2024-02-20T13:05:45.889908Z",
        deployUpdatedDate: "2024-02-20T13:05:45.889908Z",
        createdBy: "Raj Verma",
      },
    ],
  },
  2: {
    "atmosly-managed": [],
    others: [],
    "user-managed": [],
  }
}

export const dummyMarketplaceListOverviewData = {
  "xn35gt5t": {
    app_name: "atmosly-helm",
    status: "DEPLOYED",
    app_status: "HEALTHY",
    chart_version: "1.0.0",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["xn35gt5t"].new,
    values: dummyYamlDifference["xn35gt5t"].old,
  },
  "3bo89d5s": {
    app_name: "feature-flag-service",
    status: "DEPLOYED",
    app_status: "HEALTHY",
    chart_version: "16.8.3",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["3bo89d5s"].new,
    values: dummyYamlDifference["3bo89d5s"].old,
  },
  "jb32k45c": {
    app_name: "trace-collector",
    status: "DEPLOY_FAILED",
    app_status: "UNHEALTHY",
    chart_version: "10.6.2",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["jb32k45c"].new,
    values: dummyYamlDifference["jb32k45c"].old,
  },
  "2e34v445": {
    app_name: "system-health",
    status: "DEPLOY_FAILED",
    app_status: "UNHEALTHY",
    chart_version: "2.16.1",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["2e34v445"].new,
    values: dummyYamlDifference["2e34v445"].old,
  },
  "2bhn8nds": {
    app_name: "load-tester",
    status: "DEPLOYED",
    app_status: "HEALTHY",
    chart_version: "1.0.0",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["2bhn8nds"].new,
    values: dummyYamlDifference["2bhn8nds"].old,
  },
  "d45cjd3a": {
    app_name: "inventory-service",
    status: "DEPLOYED",
    app_status: "HEALTHY",
    chart_version: "16.8.3",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["d45cjd3a"].new,
    values: dummyYamlDifference["d45cjd3a"].old,
  },
  "9cc54fss": {
    app_name: "data-lake",
    status: "DEPLOY_FAILED",
    app_status: "UNHEALTHY",
    chart_version: "10.6.2",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["9cc54fss"].new,
    values: dummyYamlDifference["9cc54fss"].old,
  },
  "fvf6n4ks": {
    app_name: "etl-jobs",
    status: "DEPLOY_FAILED",
    app_status: "UNHEALTHY",
    chart_version: "2.16.1",
    created_at: "2024-02-20T13:05:45.889908Z",
    created_by: "Raj Verma",
    namespace: "atmosly.in",
    overridden_values: dummyYamlDifference["fvf6n4ks"].new,
    values: dummyYamlDifference["fvf6n4ks"].old,
  }
}

export const dummyProjectsData = {
  projectData: [
    {
      id: 0,
      name: "warehousing",
      title: "warehousing",
      img: awsIcon,
      services: 7,
      starred: false,
      updatedAt: "2024-02-20T13:03:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:03:45.889908Z"))} ago`,
      createdBy: "Raj Verma",
      isOpen: false,            // this is for 3 dots open/close
      tasks: [
        {
          "id": 0,
          "serviceId": 64,
          "serviceCategory": 1,
          "name": "mysql-database",
          "description": null,
          "columnId": "dataSource",
          "source": "MySQL",
          "file": null,
          "service_category": 1,
          "dump_action": null
        },
        {
          "id": 1,
          "serviceId": 63,
          "serviceCategory": 1,
          "name": "redis-database",
          "description": null,
          "columnId": "dataSource",
          "source": "Redis",
          "file": null,
          "service_category": 1,
          "dump_action": null
        },
        {
          "id": 2,
          "name": "frontend",
          "title": "frontend",
          "description": "This is a react app",
          "columnId": "app",
          "serviceId": 66,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/react_app.git",
          "repo": "raj-verma/react_app",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
          "workflows": [
            {
              id: "0",
              workflowName: "frontend-workflow",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            },
            {
              id: "1",
              workflowName: "warehousing-frontend",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            }
          ]
        },
        {
          "id": 3,
          "name": "backend",
          "title": "backend",
          "description": "This is a django app",
          "columnId": "app",
          "serviceId": 67,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/django_app.git",
          "repo": "raj-verma/django_app",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
          "workflows": [
            {
              id: "2",
              workflowName: "backend-workflow",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            },
            {
              id: "3",
              workflowName: "warehousing-backend",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            }
          ]
        },
        {
          "id": 4,
          "name": "wordpress",
          "title": "wordpress",
          "description": "This is a wordpress app",
          "columnId": "app",
          "serviceId": 68,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/word-app.git",
          "repo": "raj-verma/word-app",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
          "workflows": [
            {
              id: "4",
              workflowName: "wordpress-workflow",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            },
            {
              id: "5",
              workflowName: "warehousing-wordpress",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            }
          ]
        },
        {
          "id": 5,
          "name": "fixture-app",
          "title": "fixture-app",
          "description": "This is a fixture app",
          "columnId": "app",
          "serviceId": 69,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/fixture-app.git",
          "repo": "raj-verma/fixture-app",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
          "workflows": [
            {
              id: "6",
              workflowName: "fixture-app-workflow",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            },
            {
              id: "7",
              workflowName: "warehousing-fixture-app",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            }
          ]
        },
        {
          "id": 6,
          "name": "delivery-app",
          "title": "delivery-app",
          "description": "This is a delivery app",
          "columnId": "app",
          "serviceId": 70,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/delivery-app.git",
          "repo": "raj-verma/delivery-app",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
          "workflows": [
            {
              id: "8",
              workflowName: "delivery-app-workflow",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            },
            {
              id: "9",
              workflowName: "warehousing-delivery-app",
              environments: [
                {
                  'environment_name': 'atmosly-application'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            }
          ]
        },
      ],
      columns: {
        "app": {
          "id": "app",
          "task_orders": [
            2, 3, 4, 5, 6
          ],
          "title": "APPLICATIONS",
          "editing": false
        },
        "dataSource": {
          "id": "dataSource",
          "task_orders": [
            0, 1
          ],
          "title": "DATA SOURCES",
          "editing": false
        },
        // "queues": {
        //   "id": "queues",
        //   "task_orders": [],
        //   "title": "QUEUES",
        //   "editing": false
        // }
      },
      serviceCategories: [
        {
          "id": 1,
          "name": "MySQL",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mysql.svg"
        },
        {
          "id": 2,
          "name": "Redis",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/redis.svg"
        },
        {
          "id": 3,
          "name": "MongoDB",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mongo.svg"
        },
        {
          "id": 4,
          "name": "AWS S3",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/aws-s3.svg"
        },
        {
          "id": 5,
          "name": "RabbitMQ",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/rabbit-mq.svg"
        },
        {
          "id": 6,
          "name": "Kafka",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/kafka.svg"
        },
        {
          "id": 7,
          "name": "AmazonMQ",
          "type": "queue",
          "logo": null
        },
        {
          "id": 8,
          "name": "App",
          "type": "application",
          "logo": null
        },
        {
          "id": 9,
          "name": "Postgres",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/postgres.svg"
        }
      ]
    },
    {
      id: 1,
      name: "analytics",
      title: "analytics",
      img: awsIcon,
      services: 3,
      starred: false,
      updatedAt: "2024-02-20T13:03:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:03:45.889908Z"))} ago`,
      createdBy: "Raj Verma",
      isOpen: false,            // this is for 3 dots open/close
      tasks: [
        {
          "id": 0,
          "serviceId": 11,
          "serviceCategory": 1,
          "name": "test-sql",
          "description": null,
          "columnId": "dataSource",
          "source": "MySQL",
          "file": null,
          "service_category": 1,
          "dump_action": null
        },
        {
          "id": 1,
          "name": "social-app",
          "title": "social-app",
          "description": "Notepad app",
          "columnId": "app",
          "serviceId": 22,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/notepad_app.git",
          "repo": "raj-verma/notepad_app",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
        },
        {
          "id": 2,
          "name": "survey",
          "title": "survey",
          "description": "This is a survey app",
          "columnId": "app",
          "serviceId": 33,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/survey.git",
          "repo": "raj-verma/survey",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
        },
      ],
      columns: {
        "app": {
          "id": "app",
          "task_orders": [
            1, 2
          ],
          "title": "APPLICATIONS",
          "editing": false
        },
        "dataSource": {
          "id": "dataSource",
          "task_orders": [
            0
          ],
          "title": "DATA SOURCES",
          "editing": false
        },
        // "queues": {
        //   "id": "queues",
        //   "task_orders": [],
        //   "title": "QUEUES",
        //   "editing": false
        // }
      },
      serviceCategories: [
        {
          "id": 1,
          "name": "MySQL",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mysql.svg"
        },
        {
          "id": 2,
          "name": "Redis",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/redis.svg"
        },
        {
          "id": 3,
          "name": "MongoDB",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mongo.svg"
        },
        {
          "id": 4,
          "name": "AWS S3",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/aws-s3.svg"
        },
        {
          "id": 5,
          "name": "RabbitMQ",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/rabbit-mq.svg"
        },
        {
          "id": 6,
          "name": "Kafka",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/kafka.svg"
        },
        {
          "id": 7,
          "name": "AmazonMQ",
          "type": "queue",
          "logo": null
        },
        {
          "id": 8,
          "name": "App",
          "type": "application",
          "logo": null
        },
        {
          "id": 9,
          "name": "Postgres",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/postgres.svg"
        }
      ]
    },
    {
      id: 2,
      name: "todo",
      title: "todo",
      img: awsIcon,
      services: 1,
      starred: false,
      updatedAt: "2024-02-20T13:03:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:03:45.889908Z"))} ago`,
      createdBy: "Raj Verma",
      isOpen: false,            // this is for 3 dots open/close
      tasks: [
        {
          "id": 0,
          "name": "node-app",
          "title": "node-app",
          "description": "This is a node application.",
          "columnId": "app",
          "serviceId": 20,
          "serviceCategory": 8,
          "accountId": 10,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/node-1.git",
          "repo": "raj-verma/node-1",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "is_owner": true,
          "webhook_info": true,
          "cpu": 500,
          "ram": 1000,
          "workflows": [
            {
              id: "10",
              workflowName: "node-app-workflow",
              environments: [
                {
                  'environment_name': 'inventory-app'
                }
              ],
              createdBy: "Raj Verma",
              logo: ""
            },
          ]
        },
      ],
      columns: {
        "app": {
          "id": "app",
          "task_orders": [
            0
          ],
          "title": "APPLICATIONS",
          "editing": false
        },
        "dataSource": {
          "id": "dataSource",
          "task_orders": [

          ],
          "title": "DATA SOURCES",
          "editing": false
        },
        // "queues": {
        //   "id": "queues",
        //   "task_orders": [],
        //   "title": "QUEUES",
        //   "editing": false
        // }
      },
      serviceCategories: [
        {
          "id": 1,
          "name": "MySQL",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mysql.svg"
        },
        {
          "id": 2,
          "name": "Redis",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/redis.svg"
        },
        {
          "id": 3,
          "name": "MongoDB",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mongo.svg"
        },
        {
          "id": 4,
          "name": "AWS S3",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/aws-s3.svg"
        },
        {
          "id": 5,
          "name": "RabbitMQ",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/rabbit-mq.svg"
        },
        {
          "id": 6,
          "name": "Kafka",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/kafka.svg"
        },
        {
          "id": 7,
          "name": "AmazonMQ",
          "type": "queue",
          "logo": null
        },
        {
          "id": 8,
          "name": "App",
          "type": "application",
          "logo": null
        },
        {
          "id": 9,
          "name": "Postgres",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/postgres.svg"
        }
      ]
    },
    {
      id: 3,
      name: "Roboshop",
      title: "Roboshop",
      img: awsIcon,
      services: 5,
      starred: false,
      updatedAt: "2024-02-20T13:03:45.889908Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:03:45.889908Z"))} ago`,
      createdBy: "Raj Verma",
      isOpen: false,
      tasks: [
        {
          "id": 0,
          "name": "backend",
          "title": "backend",
          "description": null,
          "columnId": "app",
          "serviceId": 23,
          "serviceCategory": 8,
          "accountId": 44,
          "accountName": "raj-verma",
          "app_source_type": 1,
          "containerRegistry": "raj-verma",
          "sourceType": "Git Repositories",
          "repoUrl": "https://github.com/raj-verma/roboshop-backend.git",
          "repo": "raj-verma/roboshop-backend",
          "isOwner": true,
          "isPrivate": true,
          "source": "github",
          "webhook_info": true,
          "cpu": 1000,
          "ram": 2000,
          "workflows": [
            {
              "id": "11",
              "workflowName": "envv-backend",
              "environments": [
                {
                  "environment_name": "roboshop-env"
                }
              ],
              "createdBy": "Raj Verma",
              "logo": ""
            }
          ]
        },
        {
          "id": 1,
          "name": "frontend",
          "servicecategory": 8,
          "description": null,
          "columnId": "app",
          "serviceId": 24,
          "app_source_type": 0,
          "sourceType": "Container Registry",
          "containerRegistry": {
            id: 28,
            key: 2,
            integration: 7,
            status: true,
            created_at: "2024-02-08T07:26:29.666411Z",
            updated_at: "2024-02-08T07:26:29.666411Z",
            created_by: "Raj Verma",
            integration_type: "ecr",
            alias: "atmosly-devops",
            region: "us-east-2",
            category_id: 3,
            account_id: 897398831588,
            category: "Container Registry",
            integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg"
          },
          "container_registry_id": 28,
          "imageRepo": "roboshop-frontend",
          "container_registry_type": 1,
          "cpu": 1000,
          "ram": 2000,
          "service_icon": null,
          "type": "application",
          "workflows": [
            {
              "id": "12",
              "workflowName": "envv-frontend",
              "environments": [
                {
                  "environment_name": "roboshop-env"
                }
              ],
              "createdBy": "Raj Verma",
              "logo": ""
            }
          ]
        },
        {
          "id": 2,
          "name": "wordpress",
          "servicecategory": 8,
          "description": null,
          "columnId": "app",
          "serviceId": 25,
          "app_source_type": 0,
          "sourceType": "Container Registry",
          "containerRegistry": {
            id: 28,
            key: 2,
            integration: 7,
            status: true,
            created_at: "2024-02-08T07:26:29.666411Z",
            updated_at: "2024-02-08T07:26:29.666411Z",
            created_by: "Raj Verma",
            integration_type: "ecr",
            alias: "atmosly-devops",
            region: "us-east-2",
            category_id: 3,
            account_id: 897398831588,
            category: "Container Registry",
            integration_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg"
          },
          "container_registry_id": 28,
          "imageRepo": "sample-wordpress",
          "container_registry_type": 1,
          "cpu": 100,
          "ram": 500,

          "service_icon": null,
          "type": "application",
          "workflows": []
        },
        {
          "id": 3,
          "serviceId": 64,
          "serviceCategory": 1,
          "name": "mysql-database",
          "description": null,
          "columnId": "dataSource",
          "source": "MySQL",
          "file": null,
          "service_category": 1,
          "dump_action": null
        },
        {
          "id": 4,
          "serviceId": 63,
          "serviceCategory": 1,
          "name": "redis-database",
          "description": null,
          "columnId": "dataSource",
          "source": "Redis",
          "file": null,
          "service_category": 1,
          "dump_action": null
        },
      ],
      columns: {
        "app": {
          "id": "app",
          "task_orders": [
            0, 1, 2
          ],
          "title": "APPLICATIONS",
          "editing": false
        },
        "dataSource": {
          "id": "dataSource",
          "task_orders": [
            3, 4
          ],
          "title": "DATA SOURCES",
          "editing": false
        },
        // "queues": {
        //   "id": "queues",
        //   "task_orders": [],
        //   "title": "QUEUES",
        //   "editing": false
        // }
      },
      serviceCategories: [
        {
          "id": 1,
          "name": "MySQL",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mysql.svg"
        },
        {
          "id": 2,
          "name": "Redis",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/redis.svg"
        },
        {
          "id": 3,
          "name": "MongoDB",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/mongo.svg"
        },
        {
          "id": 4,
          "name": "AWS S3",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/aws-s3.svg"
        },
        {
          "id": 5,
          "name": "RabbitMQ",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/rabbit-mq.svg"
        },
        {
          "id": 6,
          "name": "Kafka",
          "type": "queue",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/kafka.svg"
        },
        {
          "id": 7,
          "name": "AmazonMQ",
          "type": "queue",
          "logo": null
        },
        {
          "id": 8,
          "name": "App",
          "type": "application",
          "logo": null
        },
        {
          "id": 9,
          "name": "Postgres",
          "type": "data_source",
          "logo": "https://atmosly-media.s3.amazonaws.com/project_environment/logos/postgres.svg"
        }
      ]
    }
  ],
}

export const dummyWorkflowSteps = [
  {
    "workflow_details": {
      "workflow_name": "frontend-workflow",
      "workflow_description": null,
      "workflow_unique_id": "0",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "B5C97949fF",
        "commit_id": "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
        "commit_message": "This is my first commit"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": [
          {
            "name": "trivy",
            "config": {
              "max_critical_vulnerability": "2",
              "max_high_vulnerability": "5"
            },
            "enabled": true
          }
        ]
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "frontend"
            }
          ]
        },
        "tools": []
      }
    ]
  },//0
  {
    "workflow_details": {
      "workflow_name": "warehousing-frontend",
      "workflow_description": null,
      "workflow_unique_id": "1",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "B5C97949fF",
        "commit_id": "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
        "commit_message": "This is my first commit"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": [
          {
            "name": "trivy",
            "config": {
              "max_critical_vulnerability": "2",
              "max_high_vulnerability": "5"
            },
            "enabled": true
          }
        ]
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "frontend"
            }
          ]
        },
        "tools": []
      }
    ]
  },//1
  {
    "workflow_details": {
      "workflow_name": "backend-workflow",
      "workflow_description": null,
      "workflow_unique_id": "2",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "3FdBC7e715",
        "commit_id": "deec0004e871ae24a30e1bd49f20cebd703ba072",
        "commit_message": "Added fix for signin."
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": [
          {
            "name": "trivy",
            "config": {
              "max_critical_vulnerability": "2",
              "max_high_vulnerability": "5"
            },
            "enabled": true
          }
        ]
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "backend"
            }
          ]
        },
        "tools": []
      }
    ]
  },//2
  {
    "workflow_details": {
      "workflow_name": "warehousing-backend",
      "workflow_description": null,
      "workflow_unique_id": "3",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "3FdBC7e715",
        "commit_id": "deec0004e871ae24a30e1bd49f20cebd703ba072",
        "commit_message": "Added fix for signin."
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "81CF2c4911",
              "env_name": "backend"
            }
          ]
        },
        "tools": []
      }
    ]
  },//3
  {
    "workflow_details": {
      "workflow_name": "wordpress-workflow",
      "workflow_description": null,
      "workflow_unique_id": "4",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "7KdBA8v686",
        "commit_id": "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
        "commit_message": "Fixed table format"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "wordpress"
            }
          ]
        },
        "tools": []
      }
    ]
  },//4
  {
    "workflow_details": {
      "workflow_name": "warehousing-wordpress",
      "workflow_description": null,
      "workflow_unique_id": "5",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "7KdBA8v686",
        "commit_id": "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
        "commit_message": "Fixed table format"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "wordpress"
            }
          ]
        },
        "tools": []
      }
    ]
  },//5
  {
    "workflow_details": {
      "workflow_name": "fixture-app-workflow",
      "workflow_description": null,
      "workflow_unique_id": "6",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "34FGB7e237",
        "commit_id": "lnvv0967f297lo24a30e1bd49f20byls703lv592",
        "commit_message": "Fixed localhost changes"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "fixture"
            }
          ]
        },
        "tools": []
      }
    ]
  },//6
  {
    "workflow_details": {
      "workflow_name": "warehousing-fixture-app",
      "workflow_description": null,
      "workflow_unique_id": "7",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "34FGB7e237",
        "commit_id": "lnvv0967f297lo24a30e1bd49f20byls703lv592",
        "commit_message": "Fixed localhost changes"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "fixture"
            }
          ]
        },
        "tools": []
      }
    ]
  },//7
  {
    "workflow_details": {
      "workflow_name": "delivery-app-workflow",
      "workflow_description": null,
      "workflow_unique_id": "8",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "3BdCc0x082",
        "commit_id": "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
        "commit_message": "optimized the components"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "delivery-app"
            }
          ]
        },
        "tools": []
      }
    ]
  },//8
  {
    "workflow_details": {
      "workflow_name": "warehousing-delivery-app",
      "workflow_description": null,
      "workflow_unique_id": "9",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 29,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "3BdCc0x082",
        "commit_id": "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
        "commit_message": "optimized the components"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/atmosly/voting-app-atmosly.git",
          "repo_name": "atmosly/voting-app-atmosly",
          "repo_id": "",
          "branch": "main",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": false,
          "app_source_type": 1
        },
        "tools": [
          {
            "name": "detect-secrets",
            "config": {},
            "enabled": true
          }
        ]
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "main",
          "dockerfile": "./result/Dockerfile",
          "context": ".",
          "image_name": "qa-result",
          "image_tag": "1",
          "docker_repo_name": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "docker_repo_url": "767398031518.dkr.ecr.us-east-2.amazonaws.com",
          "git_integration_type": "github",
          "container_registry_id": 27,
          "container_registry_logo": "integration/logos/ecr.svg",
          "account_id": "767398031518",
          "alias": "atmosly-testing",
          "region": "us-east-2",
          "integration_type": "ECR"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "0",
              "env_name": "delivery-app"
            }
          ]
        },
        "tools": []
      }
    ]
  },//9
  {
    "workflow_details": {
      "workflow_name": "node-app-workflow",
      "workflow_description": null,
      "workflow_unique_id": "10",
      "cluster_name": "app-server",
      "cluster_unique_id": "QjMxZDdGZTY3Mw==",
      "is_execute": false,
      "service_id": 12,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "9HtKN7e0j8",
        "commit_id": "kdc90004i971ae78x30e1bd49lp90ebd703ws867",
        "commit_message": "Update Dockerfile"
      },
      "pass_env_vars_at_build_time": false,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://squareops_test@bitbucket.org/atmosly/backend-mern.git",
          "repo_name": "atmosly/backend-mern",
          "repo_id": "",
          "branch": "main",
          "branch_config": "branch",
          "branch_config_value": [
            "main"
          ],
          "build_type": "automatic",
          "git_account_id": 19,
          "auto_trigger": true,
          "git_integration_type": "bitbucket",
          "app_source_type": 1,
          "container_registry_id": "",
          "container_registry_type": "",
          "repository_name": "",
          "image_tag": ""
        },
        "tools": []
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "dockerfile": "./Dockerfile",
          "context": ".",
          "image_name": "envv-21-ewgwef",
          "image_tag": 3,
          "docker_repo_name": "vampireking",
          "docker_repo_url": "docker.io",
          "container_registry_id": 29,
          "container_registry_logo": "integration/logos/dockerhub.svg",
          "account_id": "",
          "alias": "",
          "region": "",
          "integration_type": "DockerHub"
        },
        "tools": [
          {
            "name": "trivy",
            "config": {
              "max_critical_vulnerability": "2",
              "max_high_vulnerability": "5"
            },
            "enabled": true
          }
        ]
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "3",
              "env_name": "envv-21"
            },
            {
              "env_unique_id": "3",
              "env_name": "ddd-21",
              "build_type": "automatic"
            }
          ]
        },
        "tools": [
          {
            "name": "environment",
            "config": {
              "env_unique_id": "3",
              "env_name": "ddd-21",
              "build_type": "automatic"
            },
            "enabled": true
          }
        ]
      }
    ]
  },//10
  {
    "workflow_details": {
      "workflow_name": "envv-backend",
      "workflow_description": null,
      "workflow_unique_id": "11",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 23,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "d73dfe9b0d",
        "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
        "commit_message": "remove print statement"
      },
      "pass_env_vars_at_build_time": true,
      "deploy_only": null
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "repo_url": "https://github.com/raj-verma/roboshop-backend.git",
          "repo_name": "raj-verma/roboshop-backend",
          "repo_id": "",
          "branch": "dev",
          "git_account_id": 44,
          "git_integration_type": "github",
          "auto_trigger": true,
          "app_source_type": 1
        },
        "tools": []
      },
      {
        "name": "build",
        "enabled": true,
        "config": {
          "git_branch": "dev",
          "dockerfile": "./Dockerfile",
          "context": ".",
          "image_name": "envv-backend",
          "image_tag": "1",
          "docker_repo_name": "vampireking",
          "docker_repo_url": "docker.io",
          "git_integration_type": "github",
          "container_registry_id": 29,
          "container_registry_logo": "integration/logos/dockerhub.svg",
          "account_id": "",
          "alias": "",
          "region": "",
          "integration_type": "DockerHub"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "3",
              "env_name": "roboshop-env"
            }
          ]
        },
        "tools": []
      }
    ]
  },//11
  {
    "workflow_details": {
      "workflow_name": "envv-frontend",
      "workflow_description": null,
      "workflow_unique_id": "12",
      "cluster_name": "web-apps",
      "cluster_unique_id": "0",
      "is_execute": true,
      "service_id": 24,
      "cloud_account_id": "767398031518",
      "latest_release_details": {
        "release_number": "2EadFb17bB",
        "commit_id": "",
        "commit_message": ""
      },
      "pass_env_vars_at_build_time": false,
      "deploy_only": true
    },
    "steps": [
      {
        "name": "source",
        "enabled": true,
        "config": {
          "app_source_type": 0,
          "container_registry_id": 28,
          "repository_name": "atmosly-frontend",
          "image_tag": "1"
        },
        "tools": []
      },
      {
        "name": "deploy",
        "enabled": true,
        "config": {
          "environments": [
            {
              "env_unique_id": "3",
              "env_name": "roboshop-env"
            }
          ]
        },
        "tools": []
      }
    ]
  }//12
]

export const dummyWorkflowsProject = {
  66: [
    {
      "id": 0,
      "created_at": "2024-08-22T07:48:31.400333Z",
      "created_by": "Nikhil Kadiyan ",
      "updated_at": "2024-08-22T07:48:31.400333Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "0",
      "name": "frontend-workflow",
      "description": null,
      "service": 66
    },
    {
      "id": 1,
      "created_at": "2024-08-09T13:05:39.235311Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T13:05:39.235311Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "1",
      "name": "warehousing-frontend",
      "description": null,
      "service": 66
    }
  ],
  67: [
    {
      "id": 2,
      "created_at": "2024-08-09T13:05:39.165359Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T13:05:39.165359Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "is_deleted": false,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "meta_data": {},
      "workflow_unique_id": "2",
      "name": "backend-workflow",
      "description": null,
      "service": 67
    },
    {
      "id": 3,
      "created_at": "2024-08-09T13:05:39.093826Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T13:05:39.093826Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "3",
      "name": "warehousing-backend",
      "description": null,
      "service": 67
    }
  ],
  68: [
    {
      "id": 4,
      "created_at": "2024-08-09T11:23:26.049818Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T11:23:26.049818Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "is_deleted": false,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "meta_data": {},
      "workflow_unique_id": "4",
      "name": "wordpress-workflow",
      "description": null,
      "service": 68
    },
    {
      "id": 5,
      "created_at": "2024-08-09T11:23:25.979213Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T11:23:25.979213Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "is_deleted": false,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "meta_data": {},
      "workflow_unique_id": "5",
      "name": "warehousing-wordpress",
      "description": null,
      "service": 68
    }
  ],
  69: [
    {
      "id": 6,
      "created_at": "2024-08-09T11:23:25.900722Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T11:23:25.900722Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "6",
      "name": "fixture-app-workflow",
      "description": null,
      "service": 69
    },
    {
      "id": 7,
      "created_at": "2024-08-09T11:23:25.900722Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T11:23:25.900722Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "7",
      "name": "warehousing-fixture-app",
      "description": null,
      "service": 69
    }
  ],
  70: [
    {
      "id": 8,
      "created_at": "2024-08-09T11:23:25.900722Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T11:23:25.900722Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "8",
      "name": "delivery-app-workflow",
      "description": null,
      "service": 70
    },
    {
      "id": 9,
      "created_at": "2024-08-09T11:23:25.900722Z",
      "created_by": "Shashank Shrivastava ",
      "updated_at": "2024-08-09T11:23:25.900722Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 0,
        "name": "atmosly-application"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "9",
      "name": "warehousing-delivery-app",
      "description": null,
      "service": 70
    }
  ],
  20: [
    {
      "id": 10,
      "created_at": "2024-08-22T07:48:31.400333Z",
      "created_by": "Nikhil Kadiyan ",
      "updated_at": "2024-08-22T07:48:31.400333Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 2,
        "name": "inventory-app"
      },
      "workflow_status": "Failed",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "10",
      "name": "node-app-workflow",
      "description": null,
      "service": 20
    },
  ],
  23: [
    {
      "id": 11,
      "created_at": "2024-09-03T07:11:32.003922Z",
      "created_by": "Nikhil Kadiyan ",
      "updated_at": "2024-09-03T07:11:32.003922Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 3,
        "name": "roboshop-env"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "11",
      "name": "envv-backend",
      "description": null,
      "service": 23
    }
  ],
  24: [
    {
      "id": 12,
      "created_at": "2024-09-03T07:11:32.003922Z",
      "created_by": "Nikhil Kadiyan ",
      "updated_at": "2024-09-03T07:11:32.003922Z",
      "updated_by": null,
      "deleted_at": null,
      "deleted_by": null,
      "environment_details": {
        "id": 3,
        "name": "roboshop-env"
      },
      "workflow_status": "Succeeded",
      "is_deleted": false,
      "meta_data": {},
      "workflow_unique_id": "12",
      "name": "envv-frontend",
      "description": null,
      "service": 24
    }
  ]
}

export const dummyWorkflowsChartData = [
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 2,
      "failed": 0,
      "total": 2,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-09-23T07:18:08.460279Z"
    },
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 2,
      "failed": 0,
      "total": 2,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 1,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
  [
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-09-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-10-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-11-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2023-12-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-01-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 1,
      "failed": 0,
      "total": 1,
      "date": "2024-02-29T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-03-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-04-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-05-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-06-30T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-07-31T07:18:08.460279Z"
    },
    {
      "running": 0,
      "success": 0,
      "failed": 0,
      "total": 0,
      "date": "2024-08-29T07:18:08.460279Z"
    }
  ],
]

export const dummyWorkflowsReport = [
  [
    {
      "execution_id": "B5C97949fF",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    },
    {
      "execution_id": "M9V28462pa",
      "step_name": "Trivy",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-5918961-trivy-report.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPHKT7J3OM%2F20240830%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240830T062923Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEE8aDEkWapGBLa16Vg90xyKxASwNBjqjrhkFg8h3wu2BgQV7VJoDpcEkcP%2BRXA37ysU%2FnYczZXJLfEmhB3KuSd%2Fjukb05wOVunIoNsxE%2FN2qaq%2FKwENOVd2G6Zr72pIrjeghMtfaz3wdz9Oh46nKLnsXy%2FaheURfpphv%2F92kumiRJ65NotjziQtYNjTQlZjR6YGOhS7xB0nc6kSBZpk%2FqiXoFR38ZJEF1kcfmVD4fbRyCivT25l0Ke06XrCLZkHfcOWK0SiKssW2BjItQ2QLjtwlNbgpUwtFHZ34bBnpKsITMDK5L6Y4mCgZl7iNz1vDxJ94SZToIhJi&X-Amz-Signature=981883b46c469d760d5c878d35e1453313567c6651a1a3254bd39040ca4208f7",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "post-build",
      "trigger_type": "Manual",
      "environment_unique_id": "Ffd45C86C7"
    },
  ],//0
  [
    {
      "execution_id": "B5C97949fF",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    },
  ],//1
  [
    {
      "execution_id": "3FdBC7e715",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    },
    {
      "execution_id": "7gnBC3o737",
      "step_name": "Trivy",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-5918961-trivy-report.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPHKT7J3OM%2F20240830%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240830T062923Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEE8aDEkWapGBLa16Vg90xyKxASwNBjqjrhkFg8h3wu2BgQV7VJoDpcEkcP%2BRXA37ysU%2FnYczZXJLfEmhB3KuSd%2Fjukb05wOVunIoNsxE%2FN2qaq%2FKwENOVd2G6Zr72pIrjeghMtfaz3wdz9Oh46nKLnsXy%2FaheURfpphv%2F92kumiRJ65NotjziQtYNjTQlZjR6YGOhS7xB0nc6kSBZpk%2FqiXoFR38ZJEF1kcfmVD4fbRyCivT25l0Ke06XrCLZkHfcOWK0SiKssW2BjItQ2QLjtwlNbgpUwtFHZ34bBnpKsITMDK5L6Y4mCgZl7iNz1vDxJ94SZToIhJi&X-Amz-Signature=981883b46c469d760d5c878d35e1453313567c6651a1a3254bd39040ca4208f7",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "post-build",
      "trigger_type": "Manual",
      "environment_unique_id": "Ffd45C86C7"
    },
  ],//2
  [
    {
      "execution_id": "3FdBC7e715",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    },
  ],//3
  [
    {
      "execution_id": "7KdBA8v686",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    }
  ],//4
  [
    {
      "execution_id": "7KdBA8v686",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    }
  ],//5
  [
    {
      "execution_id": "34FGB7e237",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    }
  ],//6
  [
    {
      "execution_id": "34FGB7e237",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    }
  ],//7
  [
    {
      "execution_id": " 3BdCc0x082",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    }
  ],//8
  [
    {
      "execution_id": " 3BdCc0x082",
      "step_name": "Secret Detection",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Succeeded",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    }
  ],//9
  [
    {
      "execution_id": "  9HtKN7e0j8",
      "step_name": "Trivy",
      "report_link": "https://atmworkflows-767398031518.s3.amazonaws.com/ci-1168407-detect-secrets.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3FLD4WCPC2EC3VMH%2F20240829%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240829T111427Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=FwoGZXIvYXdzEDkaDMjFU60vxrJtQARpriKxAdCLqh4Xnl6ALJ6Ch2STSb%2BFSItju2L9WVuYBRzOSH%2BD%2Fp2ZMPdplzqsA5MwT%2FkIcCm90KfUSHBC5ykE%2Fyy2zG%2BSw1m0So8wpRWVtapDHQMezX%2Ft5A8T0YXDbN%2BZwQeX76WUSGuZz3vcXQz9xdPR0w%2Fb4ES%2BzRiaecu5HJRnpUU7XRD1zY9YXyaPUuYmaKqj0S7RGSUYFg9TPahkdvdBP6AsjElqcdZjf5BR8PcXtJEFoCisxsC2BjItD8xNI1mE%2FUnXDQHBCee5OYET8BBBdZ3GtXqLbgcuL%2FwDcrslzTO7VZizQs3b&X-Amz-Signature=c421c385583da2c7ce702c97200ac1a0f9b78d081f39274c994dfc30dadfa968",
      "status": "Failed",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "stage": "pre-build",
      "trigger_type": "Manual",
      "environment_unique_id": "CaB13c016E"
    }
  ],//10
]

export const dummyWorkflowExecutionList = [
  [
    {
      "execution_id": "B5C97949fF",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "0",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
    {
      "execution_id": "M9V28462pa",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "0",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    }
  ],
  [
    {
      "execution_id": "B5C97949fF",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "1",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "3FdBC7e715",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "2",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
    {
      "execution_id": "7gnBC3o737",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "2",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    }
  ],
  [
    {
      "execution_id": "3FdBC7e715",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "3",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "7KdBA8v686",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "4",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "7KdBA8v686",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "5",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "34FGB7e237",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "6",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "34FGB7e237",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "7",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "3BdCc0x082",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "8",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "3BdCc0x082",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "9",
      "environment_unique_id": "CaB13c016E",
      "status": "Succeeded"
    },
  ],
  [
    {
      "execution_id": "9HtKN7e0j8",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "10",
      "environment_unique_id": "CaB13c016E",
      "status": "Failed"
    },
  ],
  [
    {
      "execution_id": "d73dfe9b0d",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "11",
      "environment_unique_id": "3",
      "status": "Succeeded"
    }
  ],
  [
    {
      "execution_id": "2EadFb17bB",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "workflow_unique_id": "12",
      "environment_unique_id": "3",
      "status": "Succeeded"
    },
  ]
]

export const dummyWorkflowPipeline = [
  [
    {
      "id": 326,
      "release_number": "B5C97949fF",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "pipeline": [
        {
          "id": 2726,
          "build_number": "ci-5367454",
          "type": 1,
          "pipeline_branch": "main",
          "commit_message": "Update Dockerfile",
          "commit_id": "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
          "tags": null,
          "status": "Failed"
        }
      ],
      "workflow": {
        "workflow_name": "voting-22aug-result",
        "workflow_unique_id": "7318e93aDd"
      },
      "status": "Failed",
      "updated_at": "2024-08-22T07:48:40.421552Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 185,
        "name": "voting-22aug",
        "project": 10,
        "namespace": "voting-push",
        "env_unique_id": "0",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-2",
        "cluster_name": "aug22",
        "cluster_unique_id": "NDBBYmZmNUFCOQ=="
      },
      "name": "result",
      "service_id": 29,
      "service_name": "result",
      "health_status": "release_failed",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    },
    {
      "id": 326,
      "release_number": "M9V28462pa",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "pipeline": [
        {
          "id": 2726,
          "build_number": "ci-5367454",
          "type": 1,
          "pipeline_branch": "main",
          "commit_message": "Update Dockerfile",
          "commit_id": "7ce855e6392957f4237d68b4da3b35dd8jd3b95u",
          "tags": null,
          "status": "Failed"
        }
      ],
      "workflow": {
        "workflow_name": "voting-22aug-result",
        "workflow_unique_id": "7318e93aDd"
      },
      "status": "Failed",
      "updated_at": "2024-08-22T07:48:40.421552Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 185,
        "name": "voting-22aug",
        "project": 10,
        "namespace": "voting-push",
        "env_unique_id": "E118fD4dEb",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-2",
        "cluster_name": "aug22",
        "cluster_unique_id": "NDBBYmZmNUFCOQ=="
      },
      "name": "result",
      "service_id": 29,
      "service_name": "result",
      "health_status": "release_failed",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    }
  ],
  [
    {
      "id": 255,
      "release_number": "B5C97949fF",
      "created_at": "2024-08-09T12:49:26.639724Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2598,
          "build_number": "ci-4152530",
          "type": 1,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2599,
          "build_number": "cd-5630463",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-result",
        "workflow_unique_id": "E29EA29c74"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T12:49:26.639724Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 109,
        "name": "qa",
        "project": 10,
        "namespace": "testapp",
        "env_unique_id": "81CF2c4911",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "result",
      "service_id": 29,
      "service_name": "result",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "https://result.7b2d902137.atmosly.in/",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    },
  ],
  [
    {
      "id": 264,
      "release_number": "3FdBC7e715",
      "created_at": "2024-08-09T13:30:28.525466Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2611,
          "build_number": "cd-5898665",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "deec0004e871ae24a30e1bd49f20cebd703ba072",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-clone-result",
        "workflow_unique_id": "9231CeCC7a"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T13:30:28.525466Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 115,
        "name": "qa-clone",
        "project": 10,
        "namespace": "testapp-clone",
        "env_unique_id": "E12C1b5a17",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "result",
      "service_id": 29,
      "service_name": "result",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "https://result.7b2d902137.bd63d8db2d.atmosly.in/",
      "branch": "main",
      "total_replicas": "2",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    },
    {
      "id": 264,
      "release_number": "7gnBC3o737",
      "created_at": "2024-08-09T13:30:28.525466Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2611,
          "build_number": "cd-5898665",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "bpsv3782p208ae19f30e1bd49f20cebd286cl351",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-clone-result",
        "workflow_unique_id": "9231CeCC7a"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T13:30:28.525466Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 115,
        "name": "qa-clone",
        "project": 10,
        "namespace": "testapp-clone",
        "env_unique_id": "E12C1b5a17",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "result",
      "service_id": 29,
      "service_name": "result",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "https://result.7b2d902137.bd63d8db2d.atmosly.in/",
      "branch": "main",
      "total_replicas": "2",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    },
  ],
  [
    {
      "id": 258,
      "release_number": "3FdBC7e715",
      "created_at": "2024-08-09T12:56:53.133021Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2602,
          "build_number": "cd-1403993",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "deec0004e871ae24a30e1bd49f20cebd703ba072",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-vote",
        "workflow_unique_id": "c5Ab0c4150"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T12:56:53.133021Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 109,
        "name": "qa",
        "project": 10,
        "namespace": "testapp",
        "env_unique_id": "81CF2c4911",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "vote",
      "service_id": 28,
      "service_name": "vote",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "https://result.99c61f1de8.atmosly.in/",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    },
  ],
  [
    {
      "id": 260,
      "release_number": "7KdBA8v686",
      "created_at": "2024-08-09T13:05:53.378419Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2604,
          "build_number": "ci-2974688",
          "type": 1,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2606,
          "build_number": "cd-1449399",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-clone-vote",
        "workflow_unique_id": "160839Bce8"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T13:05:53.378419Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 115,
        "name": "qa-clone",
        "project": 10,
        "namespace": "testapp-clone",
        "env_unique_id": "E12C1b5a17",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "vote",
      "service_id": 28,
      "service_name": "vote",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "https://result.99c61f1de8.6faebb5ba1.atmosly.in/",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    }
  ],
  [
    {
      "id": 240,
      "release_number": "7KdBA8v686",
      "created_at": "2024-08-09T11:32:05.555644Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2587,
          "build_number": "cd-9173442",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-worker",
        "workflow_unique_id": "faBBfeDc17"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T11:32:05.555644Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 109,
        "name": "qa",
        "project": 10,
        "namespace": "testapp",
        "env_unique_id": "81CF2c4911",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "worker",
      "service_id": 31,
      "service_name": "worker",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    },
  ],
  [
    {
      "id": 261,
      "release_number": "34FGB7e237",
      "created_at": "2024-08-09T13:05:59.078483Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2605,
          "build_number": "ci-707923",
          "type": 1,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "lnvv0967f297lo24a30e1bd49f20byls703lv592",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2608,
          "build_number": "cd-1254600",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-clone-worker",
        "workflow_unique_id": "FBE8DDe9f0"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T13:05:59.079413Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 115,
        "name": "qa-clone",
        "project": 10,
        "namespace": "testapp-clone",
        "env_unique_id": "E12C1b5a17",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "worker",
      "service_id": 31,
      "service_name": "worker",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    }
  ],
  [
    {
      "id": 267,
      "release_number": "34FGB7e237",
      "created_at": "2024-08-10T08:32:40.943072Z",
      "created_by": "Gautam Kumar ",
      "pipeline": [
        {
          "id": 2614,
          "build_number": "ci-7504037",
          "type": 1,
          "pipeline_branch": "beta",
          "commit_message": "Update Dockerfile",
          "commit_id": "lnvv0967f297lo24a30e1bd49f20byls703lv592",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2616,
          "build_number": "cd-3838335",
          "type": 2,
          "pipeline_branch": "beta",
          "commit_message": "what if new line added",
          "commit_id": "d8848d1b1dc793bf185d0892d088db2835cfb601",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "own-10-ewgwef",
        "workflow_unique_id": "53cde14fcf"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-10T08:32:40.943072Z",
      "commit_id": "d8848d1b1dc793bf185d0892d088db2835cfb601",
      "commit_message": "what if new line added",
      "environment": {
        "id": 129,
        "name": "own-10",
        "project": 6,
        "namespace": "own-10",
        "env_unique_id": "73adCE2A02",
        "project_name": "auto-trigger-project",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-2",
        "cluster_name": "test10aug",
        "cluster_unique_id": "RTE0QmNkNGRmZQ=="
      },
      "name": "ewgwef",
      "service_id": 12,
      "service_name": "ewgwef",
      "health_status": "degraded",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "beta",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    }
  ],
  [
    {
      "id": 268,
      "release_number": "3BdCc0x082",
      "created_at": "2024-08-10T08:34:45.957165Z",
      "created_by": "Gautam Kumar ",
      "pipeline": [
        {
          "id": 2615,
          "build_number": "ci-996276",
          "type": 1,
          "pipeline_branch": "beta",
          "commit_message": "what if new line added",
          "commit_id": "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2617,
          "build_number": "cd-1304657",
          "type": 2,
          "pipeline_branch": "beta",
          "commit_message": "what if new line added",
          "commit_id": "d8848d1b1dc793bf185d0892d088db2835cfb601",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "null-10-ewgwef",
        "workflow_unique_id": "25994Cce1a"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-10T08:34:45.957165Z",
      "commit_id": "d8848d1b1dc793bf185d0892d088db2835cfb601",
      "commit_message": "what if new line added",
      "environment": {
        "id": 131,
        "name": "null-10",
        "project": 6,
        "namespace": "null-10",
        "env_unique_id": "aF0fbF8Ec5",
        "project_name": "auto-trigger-project",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-2",
        "cluster_name": "test10aug",
        "cluster_unique_id": "RTE0QmNkNGRmZQ=="
      },
      "name": "ewgwef",
      "service_id": 12,
      "service_name": "ewgwef",
      "health_status": "degraded",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "beta",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    }
  ],
  [
    {
      "id": 304,
      "release_number": "3BdCc0x082",
      "created_at": "2024-08-14T09:38:15.031006Z",
      "created_by": "Gautam Kumar ",
      "pipeline": [
        {
          "id": 2684,
          "build_number": "ci-6261669",
          "type": 1,
          "pipeline_branch": "main",
          "commit_message": "what if new line added",
          "commit_id": "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2686,
          "build_number": "cd-6474980",
          "type": 2,
          "pipeline_branch": "main",
          "commit_message": "what if new line added",
          "commit_id": "d8848d1b1dc793bf185d0892d088db2835cfb601",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "ugc-14-ewgwef",
        "workflow_unique_id": "b42a3C43a6"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-14T09:38:15.031006Z",
      "commit_id": "d8848d1b1dc793bf185d0892d088db2835cfb601",
      "commit_message": "what if new line added",
      "environment": {
        "id": 166,
        "name": "ugc-14",
        "project": 6,
        "namespace": "ugc-14",
        "env_unique_id": "5b0c1CCb2d",
        "project_name": "auto-trigger-project",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-2",
        "cluster_name": "test14aug",
        "cluster_unique_id": "YjhFMjE4YjE4MQ=="
      },
      "name": "ewgwef",
      "service_id": 12,
      "service_name": "ewgwef",
      "health_status": "progressing",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "main",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    }
  ],
  [
    {
      "id": 264,
      "release_number": "9HtKN7e0j8",
      "created_at": "2024-08-09T13:30:28.525466Z",
      "created_by": "Shashank Shrivastava ",
      "pipeline": [
        {
          "id": 2611,
          "build_number": "cd-5898665",
          "type": 2,
          "pipeline_branch": null,
          "commit_message": "Update Dockerfile",
          "commit_id": "kdc90004i971ae78x30e1bd49lp90ebd703ws867",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "qa-clone-result",
        "workflow_unique_id": "10"
      },
      "status": "Succeeded",
      "updated_at": "2024-08-09T13:30:28.525466Z",
      "commit_id": "09e845f806939dc7144eab65b7253b5db8d372ee",
      "commit_message": "Update Dockerfile",
      "environment": {
        "id": 115,
        "name": "qa-clone",
        "project": 10,
        "namespace": "testapp-clone",
        "env_unique_id": "E12C1b5a17",
        "project_name": "voting",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-1",
        "cluster_name": "9august",
        "cluster_unique_id": "NjJBNWI0ZUIwRA=="
      },
      "name": "result",
      "service_id": 29,
      "service_name": "result",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "https://result.7b2d902137.bd63d8db2d.atmosly.in/",
      "branch": "main",
      "total_replicas": "2",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    },
  ],
  [
    {
      "id": 3,
      "release_number": "d73dfe9b0d",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "pipeline": [
        {
          "id": 2876,
          "build_number": "ci-8700802",
          "type": 1,
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2877,
          "build_number": "cd-5176368",
          "type": 2,
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2878,
          "build_number": "cd-6505767",
          "type": 4,
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "tags": null,
          "status": "Succeeded"
        },
        {
          "id": 2879,
          "build_number": "cd-1260992",
          "type": 3,
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "envv-backend",
        "workflow_unique_id": "11"
      },
      "status": "Succeeded",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
      "commit_message": "remove print statement",
      "environment": {
        "id": 3,
        "name": "roboshop-env",
        "project": 3,
        "namespace": "roboshop-env",
        "env_unique_id": "3",
        "project_name": "roboshop",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-2",
        "cluster_name": "web-apps",
        "cluster_unique_id": "0"
      },
      "name": "backend",
      "service_id": 23,
      "service_name": "backend",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": "dev",
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "true",
      "create_cron": "true"
    }
  ],
  [
    {
      "id": 458,
      "release_number": "2EadFb17bB",
      "created_at": "2024-02-21T16:07:44.135360Z",
      "created_by": "Raj Verma",
      "pipeline": [
        {
          "id": 2924,
          "build_number": "cd-2789757",
          "type": 2,
          "pipeline_branch": "",
          "commit_message": "",
          "commit_id": "",
          "tags": null,
          "status": "Succeeded"
        }
      ],
      "workflow": {
        "workflow_name": "envv-frontend",
        "workflow_unique_id": "12"
      },
      "status": "Succeeded",
      "updated_at": "2024-02-21T16:07:44.135360Z",
      "commit_id": "",
      "commit_message": "",
      "environment": {
        "id": 271,
        "name": "roboshop-env",
        "project": 3,
        "namespace": "roboshop-env",
        "env_unique_id": "3",
        "project_name": "roboshop",
        "account_id": "767398031518",
        "account_name": "atmosly-testing",
        "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
        "cluster_region": "us-east-2",
        "cluster_name": "web-apps",
        "cluster_unique_id": "0"
      },
      "name": "frontend",
      "service_id": 24,
      "service_name": "frontend",
      "health_status": "healthy",
      "to_destroy": false,
      "app_endpoint": "-",
      "branch": null,
      "total_replicas": "1",
      "active_replicas": null,
      "create_queue_worker": "false",
      "create_cron": "false"
    }
  ]
]

export const dummyEnvironmentData = {
  envData: [
    {
      id: 0,
      img: awsIcon,
      name: "atmosly-application",
      title: "atmosly-application",
      envId: "0",
      cluster: "web-apps",
      //created_at: new Date(),
      lastRelease: "2024-02-21T16:07:44.135360Z",
      calculatedLastRelease: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      cloudAccount: "897398831588",
      clusterUniqueId: 0,
      projectId: 0,
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      clusterNamespace: "atmosly-app",
      status: "Active",
      //toDestroy: val.to_destroy,
      project: "warehousing",
      createdBy: "Raj Verma",
      services: 2, //no of services
      progress: "50%", //currently hard coded
      category: "Admin", //currently hard coded
      open_to_company: "squareops", //currently hard coded Deployment
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 1,
      img: awsIcon,
      name: "atmosly-sources",
      title: "atmosly-sources",
      envId: "1",
      cluster: "app-server",
      //created_at: new Date(),
      lastRelease: "2024-02-21T16:07:44.135360Z",
      calculatedLastRelease: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      cloudAccount: "897398831588",
      clusterUniqueId: 1,
      projectId: 0,
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      clusterNamespace: "source",
      status: "Error",
      //toDestroy: val.to_destroy,
      project: "warehousing",
      createdBy: "Emily Dickinson",
      services: 1, //no of services
      progress: "50%", //currently hard coded
      category: "Admin", //currently hard coded
      open_to_company: "squareops", //currently hard coded Deployment
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 2,
      img: awsIcon,
      name: "inventory-app",
      title: "inventory-app",
      envId: "2",
      cluster: "observability_cluster",
      //created_at: new Date(),
      lastRelease: "2024-02-22T16:07:44.135360Z",
      calculatedLastRelease: `${timeSince(new Date("2024-02-22T16:07:44.135360Z"))} ago`,
      cloudAccount: "897398831588",
      clusterUniqueId: 2,
      projectId: 2,
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      clusterNamespace: "inventory-app",
      status: "Destroyed",
      //toDestroy: val.to_destroy,
      project: "todo",
      createdBy: "Raj Verma",
      services: 1, //no of services
      progress: "50%", //currently hard coded
      category: "Admin", //currently hard coded
      open_to_company: "squareops", //currently hard coded Deployment
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 3,
      img: awsIcon,
      name: "roboshop-env",
      title: "roboshop-env",
      envId: "3",
      cluster: "web-apps",
      //created_at: new Date(),
      lastRelease: "2024-02-21T16:07:44.135360Z",
      calculatedLastRelease: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      cloudAccount: "897398831588",
      clusterUniqueId: 0,
      projectId: 3,
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      clusterNamespace: "web-apps",
      status: "Active",
      //toDestroy: val.to_destroy,
      project: "roboshop",
      createdBy: "Raj Verma",
      services: 5, //no of services
      progress: "50%", //currently hard coded
      category: "Admin", //currently hard coded
      open_to_company: "squareops", //currently hard coded Deployment
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
  ],
  totalAppReleases: {
    0: {
      totalApplications: 5,
      totalReleases: 5,
      healthyReleases: 5,
      unhealthyReleases: 0,
      runningReleases: 0,
      progressingReleases: 0,
    },
    1: {
      totalApplications: 0,
      totalReleases: 0,
      healthyReleases: 0,
      unhealthyReleases: 0,
      runningReleases: 0,
      progressingReleases: 0,
    },
    3: {
      totalApplications: 4,
      totalReleases: 4,
      unhealthyReleases: 0,
      healthyReleases: 4,
      runningReleases: 0,
      progressingReleases: 0,
    }
  },
  depDataServices: {
    0: {
      totalServices: 7,
      healthyServices: 7,
      unhealthyServices: 0,
      runningServices: 0,
      progressingServices: 0,
    },
    1: {
      totalServices: 1,
      healthyServices: 0,
      unhealthyServices: 1,
      runningServices: 0,
      progressingServices: 0,
    },
    3: {
      totalServices: 4,
      healthyServices: 4,
      unhealthyServices: 0,
      runningServices: 0,
      progressingServices: 0,
    },
  },
  envInfo: {
    0: {
      totalCpuUsage: "0.29",
      totalMemUsage: "0.32",
      totalCpuRequest: "0.85",
      totalMemRequest: "1.07",
      totalPods: "",
      totalNodes: "",
      totalLabelNodes: "",
    },
    1: {
      totalCpuUsage: "0.01",
      totalMemUsage: "0.79",
      totalCpuRequest: "0.35",
      totalMemRequest: "0.98",
      totalPods: "",
      totalNodes: "",
      totalLabelNodes: "",
    },
    3: {
      totalCpuUsage: "0.01",
      totalMemUsage: "0.79",
      totalCpuRequest: "0.35",
      totalMemRequest: "0.98",
      totalPods: "",
      totalNodes: "",
      totalLabelNodes: "",
    },
  },
  serviceList: {
    0: [
      {
        service_id: 66,
        name: "frontend",
        service_name: "frontend",
        service_type: "application",
        service_category: 8,
        to_destroy: false
      },
      {
        service_id: 67,
        name: "backend",
        service_name: "backend",
        service_type: "application",
        service_category: 8,
        to_destroy: false
      },
      {
        service_id: 68,
        name: "wordpress",
        service_name: "wordpress",
        service_type: "application",
        service_category: 8,
        to_destroy: false
      },
      {
        service_id: 69,
        name: "fixture-app",
        service_name: "fixture-app",
        service_type: "application",
        service_category: 8,
        to_destroy: false
      },
      {
        service_id: 70,
        name: "delivery-app",
        service_name: "delivery-app",
        service_type: "application",
        service_category: 8,
        to_destroy: false
      },
      {
        service_id: 64,
        name: "mysql-database",
        service_name: "mysql-database",
        service_type: "data_source",
        service_category: 1,
        to_destroy: false
      },
      {
        service_id: 63,
        name: "redis-database",
        service_name: "redis-database",
        service_type: "data_source",
        service_category: 1,
        to_destroy: false
      },
    ],
    1: [
      {
        service_id: 64,
        name: "mysql-database",
        service_name: "mysql-database",
        service_type: "data_source",
        service_category: 1,
        to_destroy: false
      },
    ],
    3: [
      {
        "service_id": 23,
        "name": "backend",
        "service_name": "backend",
        "service_type": "application",
        "service_category": 8,
        "to_destroy": false
      },
      {
        "service_id": 24,
        "name": "frontend",
        "service_name": "frontend",
        "service_type": "application",
        "service_category": 8,
        "to_destroy": false
      },
      {
        service_id: 64,
        name: "mysql-database",
        service_name: "mysql-database",
        service_type: "data_source",
        service_category: 1,
        to_destroy: false
      },
      {
        service_id: 63,
        name: "redis-database",
        service_name: "redis-database",
        service_type: "data_source",
        service_category: 1,
        to_destroy: false
      },
    ]
  },
  envDeploymentData: {
    0: {
      id: 0,
      envName: "atmosly-application",
      project: "warehousing",
      namespace: "atmosly-app",
      uniqueId: 0,
      clusterId: 0,
      projectName: "warehousing",
      cloudAccountNo: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      cloudAccount: "atmosly-devops",
      clusterRegion: "us-east-1",
      kubernetesVersion: "1.27",
      serviceDetails: "",
      totalServices: 7,
      healthyServices: 7,
      unhealthyServices: 0,
      totalReleases: 5,
      healthyReleases: 5,
      unhealthyReleases: 0,
      clusterName: "web-apps",
      availabilityZones: ["us-east-1a", "us-east-1b"],
      // dns: "test.atmosly.in",
      // hostedAccount: "55bdb37f956bdc882494bc86d8db2efd",
      // recordType: "ecr",
      clusterIntegrationId: 0,
      projectId: 0,
    },
    1: {
      id: 0,
      envName: "atmosly-sources",
      project: "warehousing",
      namespace: "source",
      uniqueId: 1,
      clusterId: 1,
      projectName: "warehousing",
      cloudAccountNo: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      cloudAccount: "atmosly-devops",
      clusterRegion: "ap-south-1",
      kubernetesVersion: "1.27",
      serviceDetails: "",
      totalServices: 1,
      healthyServices: 0,
      unhealthyServices: 1,
      totalReleases: 1,
      healthyReleases: 0,
      unhealthyReleases: 1,
      clusterName: "app-server",
      availabilityZones: ["ap-south-1a", "ap-south-1b"],
      // dns: "source.atmosly.in",
      // hostedAccount: "79d91c13161b92af19665b68dd0a4218",
      // recordType: "ecr",
      clusterIntegrationId: 0,
      projectId: 0,
    },
    3: {
      id: 0,
      envName: "roboshop-env",
      namespace: "roboshop-env",
      uniqueId: 3,
      clusterId: 0,
      projectName: "roboshop",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      cloudAccount: "atmosly-devops",
      clusterRegion: "us-east-1",
      kubernetesVersion: "1.28",
      serviceDetails: "",
      totalServices: 4,
      healthyServices: 4,
      unhealthyServices: 0,
      totalReleases: 4,
      healthyReleases: 4,
      unhealthyReleases: 0,
      clusterName: "web-apps",
      availabilityZones: ["us-east-2a", "us-east-2b"],
      // dns: "/",
      // hostedAccount: "55bdb37f956bdc882494bc86d8db2efd",
      // recordType: "ecr",
      clusterIntegrationId: 0,
      projectId: 3,
    }
  },
  serviceData: {
    0: [
      {
        "id": 0,
        "release_number": "B5C97949fF",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 0,
            "build_number": "ci-9381909",
            "type": 1,
            "commit_message": "This is my first commit",
            "commit_id": "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "frontend-workflow",
          "workflow_unique_id": "0"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_message": "This is my first commit",
        "commit_id": "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
        "environment": {
          "id": 0,
          "name": "atmosly-application",
          "project": 0,
          "namespace": "atmosly-app",
          "env_unique_id": 0,
          "project_name": "warehousing",
          "account_id": "897398831588",
          "account_name": "atmosly-devops",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-1",
          "cluster_name": "web-apps",
          "cluster_unique_id": 0
        },
        "name": "frontend",
        "service_id": 66,
        "service_name": "frontend",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "",
        "branch": "main",
        "total_replicas": "1",
        "active_replicas": null
      },
      {
        "id": "0-1",
        "release_number": "M9V28462pa",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 1,
            "build_number": "ci-9562942",
            "type": 1,
            "commit_message": "Added error context.",
            "commit_id": "7ce855e6392957f4237d68b4da3b35dd8jd3b95u",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "frontend-workflow",
          "workflow_unique_id": "0"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_message": "Added error context.",
        "commit_id": "7ce855e6392957f4237d68b4da3b35dd8jd3b95u",
        "environment": {
          "id": 0,
          "name": "atmosly-application",
          "project": 0,
          "namespace": "atmosly-app",
          "env_unique_id": 0,
          "project_name": "warehousing",
          "account_id": "897398831588",
          "account_name": "atmosly-devops",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-1",
          "cluster_name": "web-apps",
          "cluster_unique_id": 0
        },
        "name": "frontend",
        "service_id": 66,
        "service_name": "frontend",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "",
        "branch": "main",
        "total_replicas": "1",
        "active_replicas": null
      },
      {
        "id": 1,
        "release_number": "3FdBC7e715",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 2,
            "build_number": "ci-9381909",
            "type": 1,
            "commit_message": "Added fix for signin.",
            "commit_id": "deec0004e871ae24a30e1bd49f20cebd703ba072",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "backend-workflow",
          "workflow_unique_id": "2"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_message": "Added fix for signin.",
        "commit_id": "deec0004e871ae24a30e1bd49f20cebd703ba072",
        "environment": {
          "id": 0,
          "name": "atmosly-application",
          "project": 0,
          "namespace": "atmosly-app",
          "env_unique_id": 0,
          "project_name": "warehousing",
          "account_id": "897398831588",
          "account_name": "atmosly-devops",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-1",
          "cluster_name": "web-apps",
          "cluster_unique_id": 0
        },
        "name": "backend",
        "service_id": 67,
        "service_name": "backend",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "",
        "branch": "main",
        "total_replicas": "1",
        "active_replicas": null
      },
      {
        "id": "1-1",
        "release_number": "7gnBC3o737",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 3,
            "build_number": "ci-9706521",
            "type": 1,
            "commit_message": "Added lazy loading.",
            "commit_id": "bpsv3782p208ae19f30e1bd49f20cebd286cl351",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "backend-workflow",
          "workflow_unique_id": "2"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_message": "Added lazy loading.",
        "commit_id": "bpsv3782p208ae19f30e1bd49f20cebd286cl351",
        "environment": {
          "id": 0,
          "name": "atmosly-application",
          "project": 0,
          "namespace": "atmosly-app",
          "env_unique_id": 0,
          "project_name": "warehousing",
          "account_id": "897398831588",
          "account_name": "atmosly-devops",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-1",
          "cluster_name": "web-apps",
          "cluster_unique_id": 0
        },
        "name": "backend",
        "service_id": 67,
        "service_name": "backend",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "",
        "branch": "main",
        "total_replicas": "1",
        "active_replicas": null
      },
      {
        "id": 2,
        "release_number": "7KdBA8v686",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 4,
            "build_number": "ci-6746634",
            "type": 1,
            "commit_message": "Fixed table format",
            "commit_id": "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "wordpress-workflow",
          "workflow_unique_id": "4"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_message": "Fixed table format",
        "commit_id": "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
        "environment": {
          "id": 0,
          "name": "atmosly-application",
          "project": 0,
          "namespace": "atmosly-app",
          "env_unique_id": 0,
          "project_name": "warehousing",
          "account_id": "897398831588",
          "account_name": "atmosly-devops",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-1",
          "cluster_name": "web-apps",
          "cluster_unique_id": 0
        },
        "name": "wordpress",
        "service_id": 68,
        "service_name": "wordpress",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "",
        "branch": "main",
        "total_replicas": "1",
        "active_replicas": null
      },
      {
        "id": 3,
        "release_number": "34FGB7e237",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 5,
            "build_number": "ci-3720783",
            "type": 1,
            "commit_message": "Fixed localhost changes.",
            "commit_id": "lnvv0967f297lo24a30e1bd49f20byls703lv592",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "fixture-app-workflow",
          "workflow_unique_id": "6"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_message": "Fixed localhost changes.",
        "commit_id": "lnvv0967f297lo24a30e1bd49f20byls703lv592",
        "environment": {
          "id": 0,
          "name": "atmosly-application",
          "project": 0,
          "namespace": "atmosly-app",
          "env_unique_id": 0,
          "project_name": "warehousing",
          "account_id": "897398831588",
          "account_name": "atmosly-devops",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-1",
          "cluster_name": "web-apps",
          "cluster_unique_id": 0
        },
        "name": "fixture-app",
        "service_id": 69,
        "service_name": "fixture-app",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "",
        "branch": "main",
        "total_replicas": "1",
        "active_replicas": null
      },
      {
        "id": 4,
        "release_number": "3BdCc0x082",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 6,
            "build_number": "ci-6492759",
            "type": 1,
            "commit_message": "optimized the components",
            "commit_id": "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "delivery-app-workflow",
          "workflow_unique_id": "8"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_message": "optimized the components",
        "commit_id": "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
        "environment": {
          "id": 0,
          "name": "atmosly-application",
          "project": 0,
          "namespace": "atmosly-app",
          "env_unique_id": 0,
          "project_name": "warehousing",
          "account_id": "897398831588",
          "account_name": "atmosly-devops",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-1",
          "cluster_name": "web-apps",
          "cluster_unique_id": 0
        },
        "name": "delivery-app",
        "service_id": 70,
        "service_name": "delivery-app",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "",
        "branch": "main",
        "total_replicas": "1",
        "active_replicas": null
      },
    ],
    1: [], // id only data_source deployed
    3: [
      {
        "id": 3,
        "release_number": "d73dfe9b0d",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 2876,
            "build_number": "ci-8700802",
            "type": 1,
            "pipeline_branch": "dev",
            "commit_message": "remove print statement",
            "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
            "tags": null,
            "status": "Succeeded"
          },
          {
            "id": 2877,
            "build_number": "cd-5176368",
            "type": 2,
            "pipeline_branch": "dev",
            "commit_message": "remove print statement",
            "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
            "tags": null,
            "status": "Succeeded"
          },
          {
            "id": 2878,
            "build_number": "cd-6505767",
            "type": 4,
            "pipeline_branch": "dev",
            "commit_message": "remove print statement",
            "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
            "tags": null,
            "status": "Succeeded"
          },
          {
            "id": 2879,
            "build_number": "cd-1260992",
            "type": 3,
            "pipeline_branch": "dev",
            "commit_message": "remove print statement",
            "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "envv-backend",
          "workflow_unique_id": "11"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
        "commit_message": "remove print statement",
        "environment": {
          "id": 3,
          "name": "roboshop-env",
          "project": 3,
          "namespace": "roboshop-env",
          "env_unique_id": "3",
          "project_name": "roboshop",
          "account_id": "767398031518",
          "account_name": "atmosly-testing",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-2",
          "cluster_name": "web-apps",
          "cluster_unique_id": "0"
        },
        "name": "backend",
        "service_id": 23,
        "service_name": "backend",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "-",
        "branch": "dev",
        "total_replicas": "1",
        "active_replicas": null,
        "create_queue_worker": "true",
        "create_cron": "true"
      },
      {
        "id": 3,
        "release_number": "2EadFb17bB",
        "created_at": "2024-02-21T16:07:44.135360Z",
        "created_by": "Raj Verma",
        "pipeline": [
          {
            "id": 2924,
            "build_number": "cd-2789757",
            "type": 2,
            "pipeline_branch": "",
            "commit_message": "",
            "commit_id": "",
            "tags": null,
            "status": "Succeeded"
          }
        ],
        "workflow": {
          "workflow_name": "envv-frontend",
          "workflow_unique_id": "12"
        },
        "status": "Succeeded",
        "updated_at": "2024-02-21T16:07:44.135360Z",
        "commit_id": "",
        "commit_message": "",
        "environment": {
          "id": 271,
          "name": "roboshop-env",
          "project": 3,
          "namespace": "roboshop-env",
          "env_unique_id": "3",
          "project_name": "roboshop",
          "account_id": "767398031518",
          "account_name": "atmosly-testing",
          "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
          "cluster_region": "us-east-2",
          "cluster_name": "web-apps",
          "cluster_unique_id": "0"
        },
        "name": "frontend",
        "service_id": 24,
        "service_name": "frontend",
        "health_status": "healthy",
        "to_destroy": false,
        "app_endpoint": "-",
        "branch": null,
        "total_replicas": "1",
        "active_replicas": null,
        "create_queue_worker": "false",
        "create_cron": "false"
      }
    ]
  },
  dataSourceServiceData: {
    0: [
      {
        "tf_state_bucket_path": "dB9A213EE9/mysql-database/datasource/self-managed-mysql.tfstate",
        "job_name": "mysql-sm-1518-rb8rr",
        "TF_VAR_root_password": "2e779py0bc8e",
        "TF_VAR_replication_password": "loot5onld349",
        "TF_VAR_exporter_password": "1zf3saj3q0zf",
        "TF_VAR_exporter_user": "mysqld_exporter",
        "TF_VAR_replication_user": "replicator",
        "TF_VAR_root_user": "root",
        "TF_VAR_backup_cron": "",
        "TF_VAR_architecture": "replication",
        "TF_VAR_store_password_to_secret_manager": "false",
        "TF_VAR_bucket_uri": "s3://",
        "TF_VAR_bucket_region": "",
        "TF_VAR_restore_bucket_region": "",
        "TF_VAR_restore_bucket_uri": "",
        "TF_VAR_restore_file_name": "",
        "file_name": "-",
        "TF_VAR_name": "mysql-database",
        "TF_VAR_namespace": "testing",
        "TF_VAR_create_namespace": "false",
        "TF_VAR_environment": "datasource",
        "tf_state_bucket_region": "ap-south-1",
        "TF_VAR_role_arn": "arn:aws:iam::897398831588:role/ATMOSLY-f71afe66-c692-41fe-b69c-53ce6eded228",
        "TF_VAR_external_id": "f71afe66-c692-41fe-b69c-53ce6eded228",
        "tf_state_bucket_name": "atmosly-tfstate-897398831588",
        "dynamodb_table": "atmosly-dynamodb-lock-897398831588",
        "TF_VAR_session_name": "mysql-database",
        "TF_VAR_region": "ap-south-1",
        "TF_VAR_cluster_name": "non-prod-cluster10",
        "name": "mysql-database",
        "TF_VAR_exporter_enable": "false",
        "enable_cross_region_backup": "false",
        "TF_VAR_custom_credentials_enabled": "false",
        "TF_VAR_restore_db": "false",
        "TF_VAR_backup_enabled": "false",
        "TF_VAR_app_version": "8.1.0",
        "TF_VAR_storage_class_name": "ebs",
        "TF_VAR_master_db_volume_size": "10Gi",
        "TF_VAR_secondary_db_volume_size": "10Gi",
        "TF_VAR_secondary_db_replica_count": "1",
        "TF_VAR_database_name": "atm_dbtesting",
        "TF_VAR_custom_user_password": "",
        "TF_VAR_custom_usernames_password": "xyzajk123@",
        "TF_VAR_username": "admin",
        "TF_VAR_restore_db": "true",
        "db_type": "self-managed-mysql",
        "MYSQL_HOST": "mysqldb-primary-headless.testing.svc.cluster.local",
        "MYSQL_USER": "admin",
        "MYSQL_PASSWORD": "ccLPPicgcqBgyMPCkblw",
        "MYSQL_DATABASE_NAME": "atm_dbtesting",
        "MYSQL_PORT": "3306",
        "MYSQL_ROOT_PASSWORD": "InrQZbqRIc1BOM25xhOu",
        "MYSQL_ROOT_USERNAME": "root",
        "MYSQL_EXPORTER_USERNAME": "mysqld_exporter",
        "MYSQL_EXPORTER_PASSWORD": "h1KQT959QNtDVE5WTaXN",
        "MYSQL_REPLICATION_USERNAME": "replicator",
        "MYSQL_REPLICATION_USER_PASSWORD": "OIGv4sn8yV1JJcrShAmy",
        "MYSQL_CONNECTION_STRING": "mysqldb://mysqldb-primary-headless.testing.svc.cluster.local:3306",
        "job_status": "Healthy",
        "database_type": "mysql",
        "created_by": "Raj Verma",
        "to_destroy": false,
        "service_id": 64,
        "updated_at": "2024-02-21T16:07:44.135360Z"
      },
      {
        "tf_state_bucket_path": "dB9A213EE9/redis-database/datasource/self-managed-redis.tfstate",
        "job_name": "redis-sm-1518-rb8rr",
        "TF_VAR_root_password": "2e779py0bc8e",
        "TF_VAR_replication_password": "loot5onld349",
        "TF_VAR_exporter_password": "1zf3saj3q0zf",
        "TF_VAR_exporter_user": "redisd_exporter",
        "TF_VAR_replication_user": "replicator",
        "TF_VAR_root_user": "root",
        "TF_VAR_backup_cron": "",
        "TF_VAR_architecture": "replication",
        "TF_VAR_store_password_to_secret_manager": "false",
        "TF_VAR_bucket_uri": "s3://",
        "TF_VAR_bucket_region": "",
        "TF_VAR_restore_bucket_region": "",
        "TF_VAR_restore_bucket_uri": "",
        "TF_VAR_restore_file_name": "",
        "TF_VAR_name": "redis-database",
        "TF_VAR_namespace": "testing",
        "TF_VAR_create_namespace": "false",
        "TF_VAR_environment": "datasource",
        "tf_state_bucket_region": "ap-south-1",
        "TF_VAR_role_arn": "arn:aws:iam::897398831588:role/ATMOSLY-f71afe66-c692-41fe-b69c-53ce6eded228",
        "TF_VAR_external_id": "f71afe66-c692-41fe-b69c-53ce6eded228",
        "tf_state_bucket_name": "atmosly-tfstate-897398831588",
        "dynamodb_table": "atmosly-dynamodb-lock-897398831588",
        "TF_VAR_session_name": "redis-database",
        "TF_VAR_region": "ap-south-1",
        "TF_VAR_cluster_name": "non-prod-cluster10",
        "name": "redis-database",
        "TF_VAR_exporter_enable": "false",
        "enable_cross_region_backup": "false",
        "TF_VAR_custom_credentials_enabled": "false",
        "TF_VAR_restore_db": "false",
        "TF_VAR_backup_enabled": "false",
        "TF_VAR_app_version": "8.1.0",
        "TF_VAR_storage_class_name": "ebs",
        "TF_VAR_master_volume_size": "10Gi",
        "TF_VAR_secondary_db_volume_size": "10Gi",
        "TF_VAR_slave_replica_count": "1",
        "TF_VAR_database_name": "atm_dbtesting",
        "TF_VAR_custom_user_password": "",
        "TF_VAR_grafana_monitoring_enabled": "false",
        "TF_VAR_username": "admin",
        "TF_VAR_custom_usernames_password": "g546yyhb44334",
        "db_type": "self-managed-redis",
        "MYSQL_HOST": "redisdb-primary-headless.testing.svc.cluster.local",
        "MYSQL_USER": "admin",
        "MYSQL_PASSWORD": "ccLPPicgcqBgyMPCkblw",
        "MYSQL_DATABASE_NAME": "atm_dbtesting",
        "MYSQL_PORT": "6379",
        "MYSQL_ROOT_PASSWORD": "InrQZbqRIc1BOM25xhOu",
        "MYSQL_ROOT_USERNAME": "root",
        "MYSQL_EXPORTER_USERNAME": "redisd_exporter",
        "MYSQL_EXPORTER_PASSWORD": "h1KQT959QNtDVE5WTaXN",
        "MYSQL_REPLICATION_USERNAME": "replicator",
        "MYSQL_REPLICATION_USER_PASSWORD": "OIGv4sn8yV1JJcrShAmy",
        "MYSQL_CONNECTION_STRING": "redisdb://redisdb-primary-headless.testing.svc.cluster.local:6379",
        "job_status": "Healthy",
        "database_type": "redis",
        "created_by": "Raj Verma",
        "auth_enabled": 'true',
        "to_destroy": false,
        "service_id": 63,
        "updated_at": "2024-02-21T16:07:44.135360Z"
      },
    ],
    1: [
      {
        "tf_state_bucket_path": "dB9A213EE9/mysql-database/datasource/self-managed-mysql.tfstate",
        "job_name": "mysql-sm-1518-rb8rr",
        "TF_VAR_root_password": "2e779py0bc8e",
        "TF_VAR_replication_password": "loot5onld349",
        "TF_VAR_exporter_password": "1zf3saj3q0zf",
        "TF_VAR_exporter_user": "mysqld_exporter",
        "TF_VAR_replication_user": "replicator",
        "TF_VAR_root_user": "root",
        "TF_VAR_backup_cron": "",
        "TF_VAR_architecture": "replication",
        "TF_VAR_store_password_to_secret_manager": "false",
        "TF_VAR_bucket_uri": "s3://",
        "TF_VAR_bucket_region": "",
        "TF_VAR_restore_bucket_region": "",
        "TF_VAR_restore_bucket_uri": "",
        "TF_VAR_restore_file_name": "",
        "TF_VAR_name": "mysql-database",
        "TF_VAR_namespace": "testing",
        "TF_VAR_create_namespace": "false",
        "TF_VAR_environment": "datasource",
        "tf_state_bucket_region": "ap-south-1",
        "TF_VAR_role_arn": "arn:aws:iam::897398831588:role/ATMOSLY-f71afe66-c692-41fe-b69c-53ce6eded228",
        "TF_VAR_external_id": "f71afe66-c692-41fe-b69c-53ce6eded228",
        "tf_state_bucket_name": "atmosly-tfstate-897398831588",
        "dynamodb_table": "atmosly-dynamodb-lock-897398831588",
        "TF_VAR_session_name": "mysql-database",
        "TF_VAR_region": "ap-south-1",
        "TF_VAR_cluster_name": "non-prod-cluster10",
        "name": "mysql-database",
        "TF_VAR_exporter_enable": "false",
        "enable_cross_region_backup": "false",
        "TF_VAR_custom_credentials_enabled": "false",
        "TF_VAR_restore_db": "false",
        "TF_VAR_backup_enabled": "false",
        "TF_VAR_app_version": "8.1.0",
        "TF_VAR_storage_class_name": "ebs",
        "TF_VAR_master_db_volume_size": "10Gi",
        "TF_VAR_secondary_db_volume_size": "10Gi",
        "TF_VAR_secondary_db_replica_count": "1",
        "TF_VAR_database_name": "atm_dbtesting",
        "TF_VAR_custom_user_password": "",
        "TF_VAR_username": "admin",
        "TF_VAR_custom_usernames_password": "dcd566cdsvsfv8",
        "db_type": "self-managed-mysql",
        "MYSQL_HOST": "mysqldb-primary-headless.testing.svc.cluster.local",
        "MYSQL_USER": "admin",
        "MYSQL_PASSWORD": "ccLPPicgcqBgyMPCkblw",
        "MYSQL_DATABASE_NAME": "atm_dbtesting",
        "MYSQL_PORT": "3306",
        "MYSQL_ROOT_PASSWORD": "InrQZbqRIc1BOM25xhOu",
        "MYSQL_ROOT_USERNAME": "root",
        "MYSQL_EXPORTER_USERNAME": "mysqld_exporter",
        "MYSQL_EXPORTER_PASSWORD": "h1KQT959QNtDVE5WTaXN",
        "MYSQL_REPLICATION_USERNAME": "replicator",
        "MYSQL_REPLICATION_USER_PASSWORD": "OIGv4sn8yV1JJcrShAmy",
        "MYSQL_CONNECTION_STRING": "mysqldb://mysqldb-primary-headless.testing.svc.cluster.local:3306",
        "job_status": "Failed",
        "database_type": "mysql",
        "created_by": "Emily Dickinson",
        "to_destroy": false,
        "auth_enabled": true,
        "service_id": 64,
        "updated_at": "2024-02-22T16:07:44.135360Z"
      },
    ], // id only data_source deployed
    3: [
      {
        "tf_state_bucket_path": "dB9A213EE9/mysql-database/datasource/self-managed-mysql.tfstate",
        "job_name": "mysql-sm-1518-rb8rr",
        "TF_VAR_root_password": "2e779py0bc8e",
        "TF_VAR_replication_password": "loot5onld349",
        "TF_VAR_exporter_password": "1zf3saj3q0zf",
        "TF_VAR_exporter_user": "mysqld_exporter",
        "TF_VAR_replication_user": "replicator",
        "TF_VAR_root_user": "root",
        "TF_VAR_backup_cron": "",
        "TF_VAR_architecture": "replication",
        "TF_VAR_store_password_to_secret_manager": "false",
        "TF_VAR_bucket_uri": "s3://",
        "TF_VAR_bucket_region": "",
        "TF_VAR_restore_bucket_region": "",
        "TF_VAR_restore_bucket_uri": "",
        "TF_VAR_restore_file_name": "",
        "TF_VAR_name": "mysql-database",
        "TF_VAR_namespace": "testing",
        "TF_VAR_create_namespace": "false",
        "TF_VAR_environment": "datasource",
        "tf_state_bucket_region": "ap-south-1",
        "TF_VAR_role_arn": "arn:aws:iam::897398831588:role/ATMOSLY-f71afe66-c692-41fe-b69c-53ce6eded228",
        "TF_VAR_external_id": "f71afe66-c692-41fe-b69c-53ce6eded228",
        "tf_state_bucket_name": "atmosly-tfstate-897398831588",
        "dynamodb_table": "atmosly-dynamodb-lock-897398831588",
        "TF_VAR_session_name": "mysql-database",
        "TF_VAR_region": "ap-south-1",
        "TF_VAR_cluster_name": "non-prod-cluster10",
        "name": "mysql-database",
        "TF_VAR_exporter_enable": "false",
        "enable_cross_region_backup": "false",
        "TF_VAR_custom_credentials_enabled": "false",
        "TF_VAR_restore_db": "false",
        "TF_VAR_backup_enabled": "false",
        "TF_VAR_app_version": "8.1.0",
        "TF_VAR_storage_class_name": "ebs",
        "TF_VAR_master_db_volume_size": "10Gi",
        "TF_VAR_secondary_db_volume_size": "10Gi",
        "TF_VAR_secondary_db_replica_count": "1",
        "TF_VAR_database_name": "atm_dbtesting",
        "TF_VAR_custom_user_password": "",
        "TF_VAR_username": "admin",
        "TF_VAR_custom_usernames_password": "dcd566cdsvsfv8",
        "db_type": "self-managed-mysql",
        "MYSQL_HOST": "mysqldb-primary-headless.testing.svc.cluster.local",
        "MYSQL_USER": "admin",
        "MYSQL_PASSWORD": "ccLPPicgcqBgyMPCkblw",
        "MYSQL_DATABASE_NAME": "atm_dbtesting",
        "MYSQL_PORT": "3306",
        "MYSQL_ROOT_PASSWORD": "InrQZbqRIc1BOM25xhOu",
        "MYSQL_ROOT_USERNAME": "root",
        "MYSQL_EXPORTER_USERNAME": "mysqld_exporter",
        "MYSQL_EXPORTER_PASSWORD": "h1KQT959QNtDVE5WTaXN",
        "MYSQL_REPLICATION_USERNAME": "replicator",
        "MYSQL_REPLICATION_USER_PASSWORD": "OIGv4sn8yV1JJcrShAmy",
        "MYSQL_CONNECTION_STRING": "mysqldb://mysqldb-primary-headless.testing.svc.cluster.local:3306",
        "job_status": "Healthy",
        "database_type": "mysql",
        "created_by": "Raj Verma",
        "to_destroy": false,
        "service_id": 64,
        "updated_at": "2024-02-21T16:07:44.135360Z"
      },
      {
        "tf_state_bucket_path": "dB9A213EE9/redis-database/datasource/self-managed-redis.tfstate",
        "job_name": "redis-sm-1518-rb8rr",
        "TF_VAR_root_password": "2e779py0bc8e",
        "TF_VAR_replication_password": "loot5onld349",
        "TF_VAR_exporter_password": "1zf3saj3q0zf",
        "TF_VAR_exporter_user": "redisd_exporter",
        "TF_VAR_replication_user": "replicator",
        "TF_VAR_root_user": "root",
        "TF_VAR_backup_cron": "",
        "TF_VAR_architecture": "replication",
        "TF_VAR_store_password_to_secret_manager": "false",
        "TF_VAR_bucket_uri": "s3://",
        "TF_VAR_bucket_region": "",
        "TF_VAR_restore_bucket_region": "",
        "TF_VAR_restore_bucket_uri": "",
        "TF_VAR_restore_file_name": "",
        "TF_VAR_name": "redis-database",
        "TF_VAR_namespace": "testing",
        "TF_VAR_create_namespace": "false",
        "TF_VAR_environment": "datasource",
        "tf_state_bucket_region": "ap-south-1",
        "TF_VAR_role_arn": "arn:aws:iam::897398831588:role/ATMOSLY-f71afe66-c692-41fe-b69c-53ce6eded228",
        "TF_VAR_external_id": "f71afe66-c692-41fe-b69c-53ce6eded228",
        "tf_state_bucket_name": "atmosly-tfstate-897398831588",
        "dynamodb_table": "atmosly-dynamodb-lock-897398831588",
        "TF_VAR_session_name": "redis-database",
        "TF_VAR_region": "ap-south-1",
        "TF_VAR_cluster_name": "non-prod-cluster10",
        "name": "redis-database",
        "TF_VAR_exporter_enable": "false",
        "enable_cross_region_backup": "false",
        "TF_VAR_custom_credentials_enabled": "false",
        "TF_VAR_restore_db": "false",
        "TF_VAR_backup_enabled": "false",
        "TF_VAR_app_version": "8.1.0",
        "TF_VAR_storage_class_name": "ebs",
        "TF_VAR_master_volume_size": "10Gi",
        "TF_VAR_secondary_db_volume_size": "10Gi",
        "TF_VAR_slave_replica_count": "1",
        "TF_VAR_database_name": "atm_dbtesting",
        "TF_VAR_custom_user_password": "",
        "TF_VAR_grafana_monitoring_enabled": "false",
        "TF_VAR_username": "admin",
        "TF_VAR_custom_usernames_password": "g546yyhb44334",
        "db_type": "self-managed-redis",
        "MYSQL_HOST": "redisdb-primary-headless.testing.svc.cluster.local",
        "MYSQL_USER": "admin",
        "MYSQL_PASSWORD": "ccLPPicgcqBgyMPCkblw",
        "MYSQL_DATABASE_NAME": "atm_dbtesting",
        "MYSQL_PORT": "6379",
        "MYSQL_ROOT_PASSWORD": "InrQZbqRIc1BOM25xhOu",
        "MYSQL_ROOT_USERNAME": "root",
        "MYSQL_EXPORTER_USERNAME": "redisd_exporter",
        "MYSQL_EXPORTER_PASSWORD": "h1KQT959QNtDVE5WTaXN",
        "MYSQL_REPLICATION_USERNAME": "replicator",
        "MYSQL_REPLICATION_USER_PASSWORD": "OIGv4sn8yV1JJcrShAmy",
        "MYSQL_CONNECTION_STRING": "redisdb://redisdb-primary-headless.testing.svc.cluster.local:6379",
        "job_status": "Healthy",
        "database_type": "redis",
        "created_by": "Raj Verma",
        "to_destroy": false,
        "service_id": 63,
        "updated_at": "2024-02-21T16:07:44.135360Z"
      },
    ],
  },
  serviceDetailsData: {
    0: {
      66: {
        "account_id": "raj-verma",
        "repo_url": "https://github.com/raj-verma/react_app.git",
        "repo_name": "raj-verma/react_app",
        "app_source_type": 1,
        "is_private": false,
        "source": "github",
        "envs": [],
        "branch": "main",
        "dockerFilePath": "",
        "cpu": null,
        "ram": null,
        "editing": false,
        "depends_on_app_ids": [],
        "depends_on_data_source_ids": [],
        "depends_on_queue_ids": [],
        "is_owner": false,
        "account_name": null,
        "pass_env_vars_at_build_time": true,
        "name": "frontend",
        "auto_trigger": false,
        "dependent_app_ids": [],
        "container_registry_id": 18,
        "vcs_branch": "main",
        "docker_file": "./Dockerfile",
        "port": "3000",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "1",
        "maximum_replicas": "2",
        "ingress_enabled": "false",
        "record_prefix": ".5a5d6b0f1c",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "false",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "100m",
        "cpu_limit": "500m",
        "memory_request": "100Mi",
        "memory_limit": "500Mi",
        "env_variables": [
          {
            "key": "REACT_APP_AWS_REGION",
            "is_secret": true,
            "value": "us-east-1",
            "value_depend_on": null
          },
          {
            "key": "SKIP_PREFLIGHT_CHECK",
            "is_secret": true,
            "value": "true",
            "value_depend_on": null
          }
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "false",
        "create_cron": "false",
        "queue_worker_command": "",
        "cron_command": "",
        "health_check_path": "",
        "service_type": "ClusterIP",
        "target_cpu_utilization": "0",
        "target_memory_utilization": "0",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "aws-managed",
            "nodegroup_name": "edge-service"
          }
        ],
        nodegroupsList: ["edge-service"],
        "app_host": "frontend.svc",
        "app_endpoint": "frontend.svc:3000",
        "app_endpoint_without_path": "frontend.svc:3000",
        "service_id": "152",
        "tag": 2,
        "repository_name": "raj-verma/react_app",
        "DOCKER_REPO_NAME": "",
        "cluster_unique_id": 0,
        "project_id": 0,
        "health_status": "release_failed",
        "container_registry_details": {
          "id": 18,
          "org": 2,
          "integration": 6,
          "status": true,
          "created_at": "2024-02-19T13:03:45.889908Z",
          "updated_at": "2024-02-19T13:03:45.889908Z",
          "created_by": "Raj Verma",
          "integration_type": "ecr",
          "alias": "atmosly-devops",
          "region": "us-east-2",
          "category_id": 3,
          "account_id": "897398831588",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
        },
        "endpoint_type": "",
        "dependent_apps_name": [],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              keys: "REACT_APP_AWS_REGION",
              value: "us-east-1",
              isSecret: true,
            },
            {
              id: 1,
              key: 1,
              keys: "SKIP_PREFLIGHT_CHECK",
              value: "true",
              isSecret: true,
            },
          ],
          config: [
            {
              id: 0,
              key: 0,
              name: "config1",
              mountPath: "/"
            },
            {
              id: 1,
              key: 1,
              name: "config2",
              mountPath: ""
            },
          ],
          secrets: [],
          storage: [],
          tags: [
            {
              id: 0,
              key: 0,
              keys: "env",
              value: "prod"
            },
            {
              id: 1,
              key: 1,
              keys: "type",
              value: "application"
            },
          ]
        },
      },
      67: {
        "account_id": "raj-verma",
        "repo_url": "https://github.com/raj-verma/django_app.git",
        "repo_name": "raj-verma/django_app",
        "app_source_type": 1,
        "is_private": false,
        "source": "github",
        "envs": [],
        "branch": "main",
        "dockerFilePath": "",
        "cpu": null,
        "ram": null,
        "editing": false,
        "depends_on_app_ids": [],
        "depends_on_data_source_ids": [],
        "depends_on_queue_ids": [],
        "is_owner": false,
        "account_name": null,
        "pass_env_vars_at_build_time": true,
        "name": "backend",
        "auto_trigger": false,
        "dependent_app_ids": [],
        "container_registry_id": 18,
        "vcs_branch": "main",
        "docker_file": "./Dockerfile",
        "port": "8000",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "2",
        "maximum_replicas": "3",
        "ingress_enabled": "false",
        "record_prefix": ".efed6b0e2f",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "false",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "100m",
        "cpu_limit": "500m",
        "memory_request": "100Mi",
        "memory_limit": "500Mi",
        "env_variables": [
          {
            "key": "REACT_APP_AWS_KEY",
            "is_secret": true,
            "value": "ddfrfervf.765cscd",
            "value_depend_on": null
          },
          {
            "key": "SKIP_PREFLIGHT_CHECK",
            "is_secret": true,
            "value": "false",
            "value_depend_on": null
          }
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "false",
        "create_cron": "false",
        "queue_worker_command": "",
        "cron_command": "",
        "health_check_path": "",
        "service_type": "ClusterIP",
        "target_cpu_utilization": "0",
        "target_memory_utilization": "0",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "aws-managed",
            "nodegroup_name": "edge-service"
          }
        ],
        nodegroupsList: ["edge-service"],
        "app_host": "backend.svc",
        "app_endpoint": "backend.svc:8000",
        "app_endpoint_without_path": "backend.svc:8000",
        "service_id": "152",
        "tag": 2,
        "repository_name": "raj-verma/django_app",
        "DOCKER_REPO_NAME": "",
        "cluster_unique_id": 0,
        "project_id": 0,
        "health_status": "release_failed",
        "container_registry_details": {
          "id": 18,
          "org": 2,
          "integration": 6,
          "status": true,
          "created_at": "2024-02-19T13:03:45.889908Z",
          "updated_at": "2024-02-19T13:03:45.889908Z",
          "created_by": "Raj Verma",
          "integration_type": "ecr",
          "alias": "atmosly-devops",
          "region": "us-east-2",
          "category_id": 3,
          "account_id": "897398831588",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
        },
        "endpoint_type": "",
        "dependent_apps_name": [],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              keys: "REACT_APP_AWS_REGION",
              value: "ap-south-1",
              isSecret: true,
            },
            {
              id: 1,
              key: 1,
              keys: "SKIP_PREFLIGHT_CHECK",
              value: "true",
              isSecret: true,
            },
          ],
          config: [
            {
              id: 0,
              key: 0,
              name: "config1",
              mountPath: "test"
            },
            {
              id: 1,
              key: 1,
              name: "config2",
              mountPath: ""
            },
          ],
          secrets: [],
          storage: [],
          tags: [
            {
              id: 0,
              key: 0,
              keys: "env",
              value: "prod"
            },
            {
              id: 1,
              key: 1,
              keys: "app-type",
              value: "backend"
            },
          ]
        },
      },
      68: {
        "account_id": "raj-verma",
        "repo_url": "https://github.com/raj-verma/word-app.git",
        "repo_name": "raj-verma/word-app",
        "app_source_type": 1,
        "is_private": false,
        "source": "github",
        "envs": [],
        "branch": "main",
        "dockerFilePath": "",
        "cpu": null,
        "ram": null,
        "editing": false,
        "depends_on_app_ids": [],
        "depends_on_data_source_ids": [],
        "depends_on_queue_ids": [],
        "is_owner": false,
        "account_name": null,
        "pass_env_vars_at_build_time": true,
        "name": "word-app",
        "auto_trigger": false,
        "dependent_app_ids": [],
        "container_registry_id": 18,
        "vcs_branch": "main",
        "docker_file": "./Dockerfile",
        "port": "8080",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "2",
        "maximum_replicas": "3",
        "ingress_enabled": "false",
        "record_prefix": ".efed6b0e2f",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "false",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "100m",
        "cpu_limit": "500m",
        "memory_request": "100Mi",
        "memory_limit": "500Mi",
        "env_variables": [
          {
            "key": "REACT_APP_AWS_KEY",
            "is_secret": true,
            "value": "ddfrfervf.765cscd",
            "value_depend_on": null
          },
          {
            "key": "SKIP_PREFLIGHT_CHECK",
            "is_secret": true,
            "value": "false",
            "value_depend_on": null
          }
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "false",
        "create_cron": "false",
        "queue_worker_command": "",
        "cron_command": "",
        "health_check_path": "",
        "service_type": "existing load balancer (ALB)",
        "target_cpu_utilization": "0",
        "target_memory_utilization": "0",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "aws-managed",
            "nodegroup_name": "edge-service"
          }
        ],
        nodegroupsList: ["edge-service"],
        "app_host": "word-app.svc",
        "app_endpoint": "word-app.svc:8080",
        "app_endpoint_without_path": "word-app.svc:8080",
        "service_id": "152",
        "tag": 2,
        "repository_name": "raj-verma/word-app",
        "DOCKER_REPO_NAME": "",
        "cluster_unique_id": 0,
        "project_id": 0,
        "health_status": "release_failed",
        "container_registry_details": {
          "id": 18,
          "org": 2,
          "integration": 6,
          "status": true,
          "created_at": "2024-02-19T13:03:45.889908Z",
          "updated_at": "2024-02-19T13:03:45.889908Z",
          "created_by": "Raj Verma",
          "integration_type": "ecr",
          "alias": "atmosly-devops",
          "region": "us-east-2",
          "category_id": 3,
          "account_id": "897398831588",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
        },
        "endpoint_type": "Cluster IP",
        "dependent_apps_name": [],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              keys: "REACT_APP_AWS_REGION",
              value: "ap-south-1",
              isSecret: true,
            },
            {
              id: 1,
              key: 1,
              keys: "SKIP_PREFLIGHT_CHECK",
              value: "true",
              isSecret: true,
            },
          ],
          config: [
            {
              id: 0,
              key: 0,
              name: "config1",
              mountPath: "test"
            },
            {
              id: 1,
              key: 1,
              name: "config2",
              mountPath: ""
            },
          ],
          secrets: [],
          storage: [],
          tags: [
            {
              id: 0,
              key: 0,
              keys: "env",
              value: "prod"
            },
            {
              id: 1,
              key: 1,
              keys: "app-type",
              value: "word-app"
            },
          ]
        },
      },
      69: {
        "account_id": "raj-verma",
        "repo_url": "https://github.com/raj-verma/fixture-app.git",
        "repo_name": "raj-verma/fixture-app",
        "app_source_type": 1,
        "is_private": false,
        "source": "github",
        "envs": [],
        "branch": "main",
        "dockerFilePath": "",
        "cpu": null,
        "ram": null,
        "editing": false,
        "depends_on_app_ids": [],
        "depends_on_data_source_ids": [],
        "depends_on_queue_ids": [],
        "is_owner": false,
        "account_name": null,
        "pass_env_vars_at_build_time": true,
        "name": "fixture-app",
        "auto_trigger": false,
        "dependent_app_ids": [],
        "container_registry_id": 18,
        "vcs_branch": "main",
        "docker_file": "./Dockerfile",
        "port": "8070",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "2",
        "maximum_replicas": "3",
        "ingress_enabled": "false",
        "record_prefix": ".efed6b0e2f",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "false",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "100m",
        "cpu_limit": "500m",
        "memory_request": "100Mi",
        "memory_limit": "500Mi",
        "env_variables": [
          {
            "key": "REACT_APP_AWS_KEY",
            "is_secret": true,
            "value": "ddfrfervf.765cscd",
            "value_depend_on": null
          },
          {
            "key": "SKIP_PREFLIGHT_CHECK",
            "is_secret": true,
            "value": "false",
            "value_depend_on": null
          }
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "false",
        "create_cron": "false",
        "queue_worker_command": "",
        "cron_command": "",
        "health_check_path": "",
        "service_type": "existing load balancer (ALB)",
        "target_cpu_utilization": "0",
        "target_memory_utilization": "0",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "aws-managed",
            "nodegroup_name": "edge-service"
          }
        ],
        nodegroupsList: ["edge-service"],
        "app_host": "fixture-app.svc",
        "app_endpoint": "fixture-app.svc:8070",
        "app_endpoint_without_path": "fixture-app.svc:8070",
        "service_id": "152",
        "tag": 2,
        "repository_name": "raj-verma/fixture-app",
        "DOCKER_REPO_NAME": "",
        "cluster_unique_id": 0,
        "project_id": 0,
        "health_status": "release_failed",
        "container_registry_details": {
          "id": 18,
          "org": 2,
          "integration": 6,
          "status": true,
          "created_at": "2024-02-19T13:03:45.889908Z",
          "updated_at": "2024-02-19T13:03:45.889908Z",
          "created_by": "Raj Verma",
          "integration_type": "ecr",
          "alias": "atmosly-devops",
          "region": "us-east-2",
          "category_id": 3,
          "account_id": "897398831588",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
        },
        "endpoint_type": "Cluster IP",
        "dependent_apps_name": [],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              keys: "REACT_APP_AWS_REGION",
              value: "ap-south-1",
              isSecret: true,
            },
            {
              id: 1,
              key: 1,
              keys: "SKIP_PREFLIGHT_CHECK",
              value: "true",
              isSecret: true,
            },
          ],
          config: [
            {
              id: 0,
              key: 0,
              name: "config1",
              mountPath: "test"
            },
            {
              id: 1,
              key: 1,
              name: "config2",
              mountPath: ""
            },
          ],
          secrets: [],
          storage: [],
          tags: [
            {
              id: 0,
              key: 0,
              keys: "env",
              value: "prod"
            },
            {
              id: 1,
              key: 1,
              keys: "app-type",
              value: "fixture-app"
            },
          ]
        },
      },
      70: {
        "account_id": "raj-verma",
        "repo_url": "https://github.com/raj-verma/delivery-app.git",
        "repo_name": "raj-verma/delivery-app",
        "app_source_type": 1,
        "is_private": false,
        "source": "github",
        "envs": [],
        "branch": "main",
        "dockerFilePath": "",
        "cpu": null,
        "ram": null,
        "editing": false,
        "depends_on_app_ids": [],
        "depends_on_data_source_ids": [],
        "depends_on_queue_ids": [],
        "is_owner": false,
        "account_name": null,
        "pass_env_vars_at_build_time": true,
        "name": "delivery-app",
        "auto_trigger": false,
        "dependent_app_ids": [],
        "container_registry_id": 18,
        "vcs_branch": "main",
        "docker_file": "./Dockerfile",
        "port": "3030",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "2",
        "maximum_replicas": "3",
        "ingress_enabled": "false",
        "record_prefix": ".efed6b0e2f",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "false",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "100m",
        "cpu_limit": "500m",
        "memory_request": "100Mi",
        "memory_limit": "500Mi",
        "env_variables": [
          {
            "key": "REACT_APP_AWS_KEY",
            "is_secret": true,
            "value": "ddfrfervf.765cscd",
            "value_depend_on": null
          },
          {
            "key": "SKIP_PREFLIGHT_CHECK",
            "is_secret": true,
            "value": "false",
            "value_depend_on": null
          }
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "false",
        "create_cron": "false",
        "queue_worker_command": "",
        "cron_command": "",
        "health_check_path": "",
        "service_type": "existing load balancer (ALB)",
        "target_cpu_utilization": "0",
        "target_memory_utilization": "0",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "aws-managed",
            "nodegroup_name": "edge-service"
          }
        ],
        nodegroupsList: ["edge-service"],
        "app_host": "delivery-app.svc",
        "app_endpoint": "delivery-app.svc:3030",
        "app_endpoint_without_path": "delivery-app.svc:3030",
        "service_id": "152",
        "tag": 2,
        "repository_name": "raj-verma/delivery-app",
        "DOCKER_REPO_NAME": "",
        "cluster_unique_id": 0,
        "project_id": 0,
        "health_status": "release_failed",
        "container_registry_details": {
          "id": 18,
          "org": 2,
          "integration": 6,
          "status": true,
          "created_at": "2024-02-19T13:03:45.889908Z",
          "updated_at": "2024-02-19T13:03:45.889908Z",
          "created_by": "Raj Verma",
          "integration_type": "ecr",
          "alias": "atmosly-devops",
          "region": "us-east-2",
          "category_id": 3,
          "account_id": "897398831588",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
        },
        "endpoint_type": "Cluster IP",
        "dependent_apps_name": [],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              keys: "REACT_APP_AWS_REGION",
              value: "ap-south-1",
              isSecret: true,
            },
            {
              id: 1,
              key: 1,
              keys: "SKIP_PREFLIGHT_CHECK",
              value: "true",
              isSecret: true,
            },
          ],
          config: [
            {
              id: 0,
              key: 0,
              name: "config1",
              mountPath: "test"
            },
            {
              id: 1,
              key: 1,
              name: "config2",
              mountPath: ""
            },
          ],
          secrets: [],
          storage: [],
          tags: [
            {
              id: 0,
              key: 0,
              keys: "env",
              value: "prod"
            },
            {
              id: 1,
              key: 1,
              keys: "app-type",
              value: "delivery-app"
            },
          ]
        },
      },
    },
    1: {
      65: {
        "account_id": "raj-verma",
        "repo_url": "https://github.com/raj-verma/react_app.git",
        "repo_name": "raj-verma/react_app",
        "app_source_type": 1,
        "is_private": false,
        "source": "github",
        "envs": [],
        "branch": "main",
        "dockerFilePath": "",
        "cpu": null,
        "ram": null,
        "editing": false,
        "depends_on_app_ids": [],
        "depends_on_data_source_ids": [],
        "depends_on_queue_ids": [],
        "is_owner": false,
        "account_name": null,
        "pass_env_vars_at_build_time": true,
        "name": "frontend",
        "auto_trigger": false,
        "dependent_app_ids": [],
        "container_registry_id": 18,
        "vcs_branch": "main",
        "docker_file": "./Dockerfile",
        "port": "3000",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "1",
        "maximum_replicas": "2",
        "ingress_enabled": "false",
        "record_prefix": ".5a5d6b0f1c",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "false",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "100m",
        "cpu_limit": "500m",
        "memory_request": "100Mi",
        "memory_limit": "500Mi",
        "env_variables": [
          {
            "key": "REACT_APP_AWS_REGION",
            "is_secret": true,
            "value": "us-east-1",
            "value_depend_on": null
          },
          {
            "key": "SKIP_PREFLIGHT_CHECK",
            "is_secret": true,
            "value": "true",
            "value_depend_on": null
          }
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "false",
        "create_cron": "false",
        "queue_worker_command": "",
        "cron_command": "",
        "health_check_path": "",
        "service_type": "",
        "target_cpu_utilization": "0",
        "target_memory_utilization": "0",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "aws-managed",
            "nodegroup_name": "edge-service"
          }
        ],
        nodegroupsList: ["edge-service"],
        "app_host": "frontend.svc",
        "app_endpoint": "frontend.svc:3000",
        "app_endpoint_without_path": "frontend.svc:3000",
        "service_id": "152",
        "tag": 2,
        "repository_name": "raj-verma/react_app",
        "DOCKER_REPO_NAME": "",
        "cluster_unique_id": 0,
        "project_id": 0,
        "health_status": "release_failed",
        "container_registry_details": {
          "id": 18,
          "org": 2,
          "integration": 6,
          "status": true,
          "created_at": "2024-02-19T13:03:45.889908Z",
          "updated_at": "2024-02-19T13:03:45.889908Z",
          "created_by": "Raj Verma",
          "integration_type": "ecr",
          "alias": "atmosly-devops",
          "region": "us-east-2",
          "category_id": 3,
          "account_id": "897398831588",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
        },
        "endpoint_type": "Cluster IP",
        "dependent_apps_name": [],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              keys: "REACT_APP_AWS_REGION",
              value: "us-east-1",
              isSecret: true,
            },
            {
              id: 1,
              key: 1,
              keys: "SKIP_PREFLIGHT_CHECK",
              value: "true",
              isSecret: true,
            },
          ],
          config: [
            {
              id: 0,
              key: 0,
              name: "config1",
              mountPath: "/"
            },
            {
              id: 1,
              key: 1,
              name: "config2",
              mountPath: ""
            },
          ],
          secrets: [],
          storage: [],
          tags: [
            {
              id: 0,
              key: 0,
              keys: "env",
              value: "prod"
            },
            {
              id: 1,
              key: 1,
              keys: "type",
              value: "application"
            },
          ]
        },
      },
    },
    3: {
      23: {
        "app_source_type": 1,
        "account_id": "raj-verma",
        "repo_url": "https://github.com/raj-verma/roboshop-backend.git",
        "repo_name": "raj-verma/roboshop-backend",
        "is_private": false,
        "source": "github",
        "is_owner": false,
        "cpu": null,
        "ram": null,
        "account_name": null,
        "repo_id": "",
        "editing": false,
        "depends_on_app_ids": [
          "24",
          "23"
        ],
        "depends_on_data_source_ids": [
          "27",
          "25",
          "26"
        ],
        "depends_on_queue_ids": [],
        "pass_env_vars_at_build_time": "false",
        "name": "backend",
        "auto_trigger": true,
        "dependent_app_ids": [
          "23"
        ],
        "container_registry_id": 18,
        "port": "8000",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "1",
        "maximum_replicas": "3",
        "ingress_enabled": "false",
        "record_prefix": "/",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "true",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "500m",
        "cpu_limit": "1000m",
        "memory_request": "1000Mi",
        "memory_limit": "2000Mi",
        "env_variables": [
          {
            "value": "jaadskoi",
            "is_secret": false,
            "key": "DATABASE_NAME",
            "value_depend_on": "25"
          },
          {
            "value": "raj-verma",
            "is_secret": false,
            "key": "DATABASE_USER",
            "value_depend_on": "25"
          },
          {
            "value": "ajajk43l4u992_rt0we",
            "is_secret": false,
            "key": "DATABASE_PASS",
            "value_depend_on": "25"
          },
          {
            "value": "Host_sksj",
            "is_secret": false,
            "key": "DATABASE_HOST",
            "value_depend_on": "25"
          },
          {
            "value": "27016",
            "is_secret": false,
            "key": "DATABASE_PORT",
            "value_depend_on": "25"
          },
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "true",
        "create_cron": "true",
        "queue_worker_command": "celery -A roboshop-backend worker -l info --pool=threads",
        "cron_command": "python manage.py migrate && python manage.py loaddata && python manage.py crontab add && crontab - && /etc/init.d/cron start && env >> /home/app/webapp/roboshop/.env && tail -f /home/app/webapp/roboshop/.env",
        "health_check_path": "/",
        "service_type": "ClusterIP",
        "target_cpu_utilization": "80",
        "target_memory_utilization": "80",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "karpenter-managed",
            "nodegroup_name": "default",
            "nodegroup_unique_id": "4Bd3dEE666"
          }
        ],
        nodegroupsList: ["default"],
        "vcs_branch": "dev",
        "docker_file": "./Dockerfile",
        "app_host": "backend.svc",
        "app_endpoint": "backend.svc:8000",
        "app_endpoint_without_path": "backend.svc:8000",
        "architecture_type": 0,
        "service_id": "23",
        "tag": 1,
        "repository_name": "envv-backend",
        "DOCKER_REPO_NAME": "vampireking",
        "cluster_unique_id": 0,
        "project_id": 3,
        "health_status": "release-failed",
        "container_registry_details": {
          "id": 29,
          "org": 2,
          "integration": 6,
          "status": true,
          "created_at": "2024-02-19T13:03:45.889908Z",
          "updated_at": "2024-02-19T13:03:45.889908Z",
          "created_by": "Raj Verma",
          "integration_type": "dockerhub",
          "alias": "atmosly-devops",
          "region": "",
          "category_id": 3,
          "account_id": "897398831588",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/dockerhub.svg"
        },
        "endpoint_type": "ClusterIP",
        "dependent_apps_name": [
          "backend"
        ],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              value: "jaadskoi",
              isSecret: true,
              keys: "DATABASE_NAME",
              valueDependOn: "25"
            },
            {
              id: 1,
              key: 1,
              value: "raj-verma",
              isSecret: true,
              keys: "DATABASE_USER",
              valueDependOn: "25"
            },
            {
              id: 2,
              key: 2,
              value: "ajajk43l4u992_rt0we",
              isSecret: true,
              keys: "DATABASE_PASS",
              valueDependOn: "25"
            },
            {
              id: 3,
              key: 3,
              value: "Host_sksj",
              isSecret: true,
              keys: "DATABASE_HOST",
              valueDependOn: "25"
            },
            {
              id: 4,
              key: 4,
              value: "27016",
              isSecret: true,
              keys: "DATABASE_PORT",
              valueDependOn: "25"
            },
          ],
          config: [
            {
              id: 0,
              key: 0,
              name: "config1",
              mountPath: "/"
            },
            {
              id: 1,
              key: 1,
              name: "config2",
              mountPath: ""
            },
          ],
          secrets: [],
          storage: [],
          tags: [
            {
              id: 1,
              key: 1,
              keys: "type",
              value: "application"
            },
          ]
        }
      },
      24: {
        "app_source_type": 0,
        "container_registry_id": 28,
        "repository_name": "roboshop-frontend",
        "container_registry_type": 1,
        "cpu": 1000,
        "ram": 2000,
        "name": "frontend",
        "auto_trigger": false,
        "dependent_app_ids": [
          "24"
        ],
        "port": "3000",
        "enable_plaintext_env": "true",
        "enable_secret_env": "true",
        "minimum_replicas": "1",
        "maximum_replicas": "",
        "ingress_enabled": "false",
        "record_prefix": "/",
        "ingress_https_enabled": "true",
        "create_storage": "false",
        "ingress_host": "/",
        "enable_autoscaling": "false",
        "create_configmap": "false",
        "service_protocol": "TCP",
        "cpu_request": "500m",
        "cpu_limit": "1000m",
        "memory_request": "1000Mi",
        "memory_limit": "2000Mi",
        "env_variables": [
          {
            "value": "true",
            "is_secret": false,
            "key": "SKIP_PREFLIGHT_CHECK",
            "value_depend_on": null
          },
          {
            "value": "ap-south-1",
            "is_secret": false,
            "key": "REACT_APP_AWS_REGION",
            "value_depend_on": null
          },
          {
            "value": "Atmosly-AWS-Integration",
            "is_secret": false,
            "key": "REACT_APP_CLOUDFORMATION_STACK_NAME",
            "value_depend_on": null
          },
          {
            "value": "https://atmosly-cfn.s3.ap-south-1.amazonaws.com/atmosly-beta.yml",
            "is_secret": false,
            "key": "REACT_APP_STACK_TEMPLATE_URL",
            "value_depend_on": null
          },
          {
            "value": "APP_ENDPOINT",
            "is_secret": false,
            "key": "REACT_APP_GOOGLE_REDIRECT_URI",
            "value_depend_on": "24",
            "real_value": "frontend.svc:3000"
          },
          {
            "value": "379541400966.5969095667009",
            "is_secret": false,
            "key": "REACT_APP_SLACK_CLIENT_ID",
            "value_depend_on": null
          },
        ],
        "labels": {},
        "storages_config": [],
        "config_files_config": [],
        "secret_files_config": [],
        "create_queue_worker": "false",
        "create_cron": "false",
        "queue_worker_command": "",
        "cron_command": "",
        "health_check_path": "/",
        "service_type": "ClusterIP",
        "target_cpu_utilization": "0",
        "target_memory_utilization": "0",
        "create_secret": "false",
        "ingress_alb_enabled": "false",
        "ingress_path": "/",
        "list_of_nodegroups": [
          {
            "nodegroup_type": "karpenter-managed",
            "nodegroup_name": "default",
            "nodegroup_unique_id": "4DAA21fFcB"
          }
        ],
        "pass_env_vars_at_build_time": "false",
        "vcs_branch": null,
        "docker_file": "./Dockerfile",
        "image_tag": "1",
        "app_host": "frontend.svc",
        "app_endpoint": "frontend.svc:3000",
        "app_endpoint_without_path": "frontend.svc:3000",
        "depends_on_app_ids": [
          "23",
          "24"
        ],
        "depends_on_data_source_ids": [],
        "depends_on_queue_ids": [],
        "architecture_type": 0,
        "service_id": "24",
        "DOCKER_REPO_NAME": "767398031518.dkr.ecr.us-east-1.amazonaws.com",
        "DOCKER_REPO_URL": "767398031518.dkr.ecr.us-east-1.amazonaws.com",
        "DOCKER_REPO_USERNAME": "AWS",
        "DOCKER_REPO_PASSWORD": "jskjsklgjsdfklgirjosjofjlksioo",
        "tag": "1",
        "cluster_unique_id": "0",
        "project_id": 3,
        "health_status": "progressing",
        "container_registry_details": {
          "id": 28,
          "org": 2,
          "integration": 7,
          "status": true,
          "created_at": "2024-05-08T07:24:28.943248Z",
          "updated_at": "2024-09-04T05:49:15.887061Z",
          "created_by": "Aadesh Bhardwaj ",
          "integration_type": "ecr",
          "alias": "atmosly-testing",
          "region": "us-east-1",
          "category_id": 3,
          "account_id": "767398031518",
          "category": "Container Registry",
          "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/ecr.svg"
        },
        "endpoint_type": "ClusterIP",
        "dependent_apps_name": [
          "frontend"
        ],
        dataSource: {
          envs: [
            {
              id: 0,
              key: 0,
              value: "true",
              isSecret: true,
              keys: "SKIP_PREFLIGHT_CHECK",
              valueDependOn: null
            },
            {
              id: 1,
              key: 1,
              value: "ap-south-1",
              isSecret: true,
              keys: "REACT_APP_AWS_REGION",
              valueDependOn: null
            },
            {
              id: 2,
              key: 2,
              value: "Atmosly-Aws-Integration",
              isSecret: true,
              keys: "REACT_APP_CLOUDFORMATION_STACK_NAME",
              valueDependOn: null
            },
            {
              id: 3,
              key: 3,
              value: "APP_ENDPOINT",
              isSecret: true,
              keys: "REACT_APP_GOOGLE_REDIRECT_URI",
              valueDependOn: null
            },
            {
              id: 4,
              key: 4,
              value: "379541400966.5969095667009",
              isSecret: true,
              keys: "REACT_APP_SLACK_CLIENT_ID",
              valueDependOn: null
            },
          ],
          config: [],
          secrets: [],
          storage: [],
          tags: []
        }
      }
    }
  }
}

export const dummyPipelineData = {
  pipelineData: [
    {
      id: 0,
      releaseNumber: "B5C97949fF",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      serviceName: "frontend",
      cluster: "web-apps",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "This is my first commit",
      commitId: "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 1,
      releaseNumber: "3FdBC7e715",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      serviceName: "backend",
      cluster: "web-apps",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Added fix for signin.",
      commitId: "deec0004e871ae24a30e1bd49f20cebd703ba072",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 2,
      releaseNumber: "7KdBA8v686",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      serviceName: "wordpress",
      cluster: "web-apps",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Fixed table format",
      commitId: "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 3,
      releaseNumber: "34FGB7e237",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      serviceName: "fixture-app",
      cluster: "web-apps",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Fixed localhost changes.",
      commitId: "lnvv0967f297lo24a30e1bd49f20byls703lv592",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 4,
      releaseNumber: "3BdCc0x082",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      serviceName: "delivery-app",
      cluster: "web-apps",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "optimized the components",
      commitId: "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    // same env pipelines but retriggerd ones
    {
      id: 0,
      releaseNumber: "M9V28462pa",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      serviceName: "frontend",
      cluster: "web-apps",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Added error context.",
      commitId: "7ce855e6392957f4237d68b4da3b35dd8jd3b95u",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: "1-1",
      releaseNumber: "7gnBC3o737",
      envUniqueId: 0,
      envName: "atmosly-application",
      projectName: "warehousing",
      serviceName: "backend",
      cluster: "web-apps",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "atmosly-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Added lazy loading.",
      commitId: "bpsv3782p208ae19f30e1bd49f20cebd286cl351",
      status: "Active",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    // other env pipeline
    {
      id: 5,
      releaseNumber: "9HtKN7e0j8",
      envUniqueId: 2,
      envName: "inventory-app",
      projectName: "todo",
      serviceName: "node-app",
      cluster: "observability_cluster",
      branch: "main",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "inventory-app",
      triggeredBy: "Raj Verma",
      commitMessage: "Integrations changes",
      commitId: "kdc90004i971ae78x30e1bd49lp90ebd703ws867",
      status: "Failed",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 6,
      releaseNumber: "d73dfe9b0d",
      envUniqueId: 3,
      envName: "roboshop-env",
      projectName: "roboshop",
      serviceName: "backend",
      cluster: "observability_cluster",
      branch: "dev",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "roboshop-env",
      triggeredBy: "Raj Verma",
      commitMessage: "remove print statement",
      commitId: "93f53cc594e12081ec35622b9c6dd3849415838f",
      status: "Succeeded",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
    {
      id: 7,
      releaseNumber: "2EadFb17bB",
      envUniqueId: 3,
      envName: "roboshop-env",
      projectName: "roboshop",
      serviceName: "frontend",
      cluster: "observability_cluster",
      branch: "",
      cloudAccount: "897398831588",
      cloudLogo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
      namespace: "roboshop-env",
      triggeredBy: "Raj Verma",
      commitMessage: "",
      commitId: "",
      status: "Succeeded",
      createdAt: "2024-02-21T16:07:44.135360Z",
      updatedAt: "2024-02-21T16:07:44.135360Z",
      calculatedUpdatedAt: `${timeSince(new Date("2024-02-21T16:07:44.135360Z"))} ago`,
      organisation: "",
      isOpen: false,            // this is for 3 dots open/close
    },
  ],
  pipelineLogsData: {
    "B5C97949fF": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "frontend",
          build_number: "cd-360257",
          type: 2,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "This is my first commit",
          commit_id: "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 0,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 66,
          service_name: "frontend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "frontend",
          build_number: "ci-637520",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "This is my first commit",
          commit_id: "5ac188e7220950f4237d68b4da3b35dd9eb5c35d",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 0,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 66,
          service_name: "frontend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    "3FdBC7e715": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "backend",
          build_number: "cd-876945",
          type: 2,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Added fix for signin.",
          commit_id: "deec0004e871ae24a30e1bd49f20cebd703ba072",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 66,
          service_name: "backend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "backend",
          build_number: "ci-456068",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Added fix for signin.",
          commit_id: "deec0004e871ae24a30e1bd49f20cebd703ba072",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 67,
          service_name: "backend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.4h9aef34nm.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    // retriggerd pipelines for above pipelines
    "M9V28462pa": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "frontend",
          build_number: "cd-482659",
          type: 2,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Added error context.",
          commit_id: "7ce855e6392957f4237d68b4da3b35dd8jd3b95u",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 0,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 66,
          service_name: "frontend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "frontend",
          build_number: "ci-956294",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Added error context.",
          commit_id: "7ce855e6392957f4237d68b4da3b35dd8jd3b95u",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 0,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 66,
          service_name: "frontend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    "7gnBC3o737": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "backend",
          build_number: "cd-853659",
          type: 2,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Added lazy loading.",
          commit_id: "bpsv3782p208ae19f30e1bd49f20cebd286cl351",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 66,
          service_name: "backend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "backend",
          build_number: "ci-970652",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Added lazy loading.",
          commit_id: "bpsv3782p208ae19f30e1bd49f20cebd286cl351",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 67,
          service_name: "backend",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.4h9aef34nm.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    // part of main pipeline 
    "7KdBA8v686": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "wordpress",
          build_number: "cd-539486",
          type: 2,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Fixed table format",
          commit_id: "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 68,
          service_name: "wordpress",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "wordpress",
          build_number: "ci-976428",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Fixed table format",
          commit_id: "gyyhu0004e871ae24a30e1bd49f20cebd245ba087",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 68,
          service_name: "wordpress",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.4h9aef34nm.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    "34FGB7e237": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "fixture-app",
          build_number: "cd-589358",
          type: 2,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Fixed localhost changes.",
          commit_id: "lnvv0967f297lo24a30e1bd49f20byls703lv592",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 69,
          service_name: "fixture-app",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "fixture-app",
          build_number: "ci-204653",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Fixed localhost changes.",
          commit_id: "lnvv0967f297lo24a30e1bd49f20byls703lv592",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 69,
          service_name: "fixture-app",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.4h9aef34nm.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    "3BdCc0x082": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "delivery-app",
          build_number: "cd-372948",
          type: 2,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "optimized the components",
          commit_id: "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 70,
          service_name: "delivery-app",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "delivery-app",
          build_number: "ci-504755",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "optimized the components",
          commit_id: "ccrf3976e2p0ae24a30e9nk29o76cebd701vt409",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Succeeded",
          triggered_type: "Manual Triggered",
          environment: {
            id: 1,
            name: "atmosly-application",
            project: 0,
            namespace: "atmosly-app",
            env_unique_id: 0,
            project_name: "warehousing",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-east-1",
            cluster_name: "web-apps",
            cluster_unique_id: 0
          },
          service_id: 70,
          service_name: "delivery-app",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.4h9aef34nm.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    "9HtKN7e0j8": {
      pipeline_to_be_triggered: [1, 2],
      releases_list: [
        {
          id: 0,
          name: "node-app",
          build_number: "cd-428649",
          type: 2,
          status: "Failed",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Integrations changes",
          commit_id: "kdc90004i971ae78x30e1bd49lp90ebd703ws867",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Failed",
          triggered_type: "Manual Triggered",
          environment: {
            id: 0,
            name: "inventory-app",
            project: 2,
            namespace: "inventory-app",
            env_unique_id: 2,
            project_name: "todo",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-west-2",
            cluster_name: "observability_cluster",
            cluster_unique_id: 2
          },
          service_id: 20,
          service_name: "node-app",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://ft.2e5aef66ba.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        },
        {
          id: 1,
          name: "backend",
          build_number: "ci-6503727",
          type: 1,
          status: "Succeeded",
          created_at: "2024-02-21T16:07:44.135360Z",
          created_by: "Raj Verma",
          commit_message: "Integrations changes",
          commit_id: "kdc90004i971ae78x30e1bd49lp90ebd703ws867",
          updated_at: "2024-02-21T16:07:44.135360Z",
          release_status: "Failed",
          triggered_type: "Manual Triggered",
          environment: {
            id: 0,
            name: "inventory-app",
            project: 2,
            namespace: "inventory-app",
            env_unique_id: 2,
            project_name: "todo",
            account_id: "897398831588",
            account_name: "atmosly-devops",
            account_logo: "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            cluster_region: "us-west-2",
            cluster_name: "observability_cluster",
            cluster_unique_id: 2
          },
          service_id: 20,
          service_name: "node-app",
          health_status: "healthy",
          to_destroy: false,
          app_endpoint: "https://cd.fd6aej99xs.atmosly.in/",
          branch: "main",
          total_replicas: "1",
          active_replicas: null
        }
      ]
    },
    "d73dfe9b0d": {
      "pipeline_to_be_triggered": [
        1,
        2,
        3,
        4
      ],
      "releases_list": [
        {
          "id": 1,
          "name": "backend",
          "build_number": "cd-1260992",
          "type": 3,
          "status": "Succeeded",
          "created_at": "2024-02-21T16:07:44.135360Z",
          "created_by": "Raj Verma",
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "updated_at": "2024-02-21T16:07:44.135360Z",
          "release_status": "Succeeded",
          "triggered_type": "Manual Triggered",
          "environment": {
            "id": 3,
            "name": "roboshop-env",
            "project": 3,
            "namespace": "roboshop-env",
            "env_unique_id": "3",
            "project_name": "roboshop",
            "account_id": "767398031518",
            "account_name": "atmosly-testing",
            "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            "cluster_region": "us-east-2",
            "cluster_name": "web-apps",
            "cluster_unique_id": "0"
          },
          "service_id": 23,
          "service_name": "backend",
          "health_status": "healthy",
          "to_destroy": false,
          "app_endpoint": "-",
          "branch": "dev",
          "total_replicas": "1",
          "active_replicas": null
        },
        {
          "id": 2878,
          "name": "backend",
          "build_number": "cd-6505767",
          "type": 4,
          "status": "Succeeded",
          "created_at": "2024-02-21T16:07:44.135360Z",
          "created_by": "Raj Verma",
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "updated_at": "2024-02-21T16:07:44.135360Z",
          "release_status": "Succeeded",
          "triggered_type": "Manual Triggered",
          "environment": {
            "id": 3,
            "name": "roboshop-env",
            "project": 3,
            "namespace": "roboshop-env",
            "env_unique_id": "3",
            "project_name": "roboshop",
            "account_id": "767398031518",
            "account_name": "atmosly-testing",
            "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            "cluster_region": "us-east-2",
            "cluster_name": "web-apps",
            "cluster_unique_id": "0"
          },
          "service_id": 23,
          "service_name": "backend",
          "health_status": "healthy",
          "to_destroy": false,
          "app_endpoint": "-",
          "branch": "dev",
          "total_replicas": "1",
          "active_replicas": null
        },
        {
          "id": 2877,
          "name": "backend",
          "build_number": "cd-5176368",
          "type": 2,
          "status": "Succeeded",
          "created_at": "2024-02-21T16:07:44.135360Z",
          "created_by": "Raj Verma",
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "updated_at": "2024-02-21T16:07:44.135360Z",
          "release_status": "Succeeded",
          "triggered_type": "Manual Triggered",
          "environment": {
            "id": 3,
            "name": "roboshop-env",
            "project": 3,
            "namespace": "roboshop-env",
            "env_unique_id": "3",
            "project_name": "atmosly",
            "account_id": "767398031518",
            "account_name": "atmosly-testing",
            "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            "cluster_region": "us-east-2",
            "cluster_name": "web-apps",
            "cluster_unique_id": "0"
          },
          "service_id": 23,
          "service_name": "backend",
          "health_status": "healthy",
          "to_destroy": false,
          "app_endpoint": "-",
          "branch": "dev",
          "total_replicas": "1",
          "active_replicas": null
        },
        {
          "id": 2876,
          "name": "backend",
          "build_number": "ci-8700802",
          "type": 1,
          "status": "Succeeded",
          "created_at": "2024-02-21T16:07:44.135360Z",
          "created_by": "Raj Verma",
          "pipeline_branch": "dev",
          "commit_message": "remove print statement",
          "commit_id": "93f53cc594e12081ec35622b9c6dd3849415838f",
          "updated_at": "2024-02-21T16:07:44.135360Z",
          "release_status": "Succeeded",
          "triggered_type": "Manual Triggered",
          "environment": {
            "id": 3,
            "name": "roboshop-env",
            "project": 3,
            "namespace": "roboshop-env",
            "env_unique_id": "3",
            "project_name": "roboshop",
            "account_id": "767398031518",
            "account_name": "atmosly-testing",
            "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            "cluster_region": "us-east-2",
            "cluster_name": "web-apps",
            "cluster_unique_id": "0"
          },
          "service_id": 23,
          "service_name": "backend",
          "health_status": "healthy",
          "to_destroy": false,
          "app_endpoint": "-",
          "branch": "dev",
          "total_replicas": "1",
          "active_replicas": null
        }
      ]
    },
    "2EadFb17bB": {
      "pipeline_to_be_triggered": [
        2
      ],
      "releases_list": [
        {
          "id": 2924,
          "name": "frontend",
          "build_number": "cd-2789757",
          "type": 2,
          "status": "Succeeded",
          "created_at": "2024-02-21T16:07:44.135360Z",
          "created_by": "Raj Verma",
          "pipeline_branch": "",
          "commit_message": "",
          "commit_id": "",
          "updated_at": "2024-02-21T16:07:44.135360Z",
          "release_status": "Succeeded",
          "triggered_type": "Manual Triggered",
          "environment": {
            "id": 271,
            "name": "roboshop-env",
            "project": 3,
            "namespace": "roboshop-env",
            "env_unique_id": "3",
            "project_name": "roboshop",
            "account_id": "767398031518",
            "account_name": "atmosly-testing",
            "account_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg",
            "cluster_region": "us-east-2",
            "cluster_name": "web-apps",
            "cluster_unique_id": "0"
          },
          "service_id": 24,
          "service_name": "frontend",
          "health_status": "healthy",
          "to_destroy": false,
          "app_endpoint": "-",
          "branch": null,
          "total_replicas": "1",
          "active_replicas": null
        }
      ]
    }
  }
}

export const dummyMarketplaceData = {
  "user_repo": [
    {
      "id": 0,
      "repo_type": "PRIVATE",
      "repo_name": "prom-app",
      "repo_url": "https://prometheus.github.io/prometheus/",
      "username": "",
      "icon": logoFav,
      "charts": [
        {
          id: 0,
          key: "prom-app",
          name: "alertmanager",
          appVersion: "v0.27.0",
          chartVersion: "1.9.0",
          icon: "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png",
          navigate: "/marketplace/deploy",
        },
        {
          id: 1,
          key: "prom-app",
          name: "notifier",
          appVersion: "v1.5.0",
          chartVersion: "0.3.0",
          icon: "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png",
          navigate: "/marketplace/deploy",
        },
        {
          id: 2,
          key: "prom-app",
          name: "jiralert",
          appVersion: "v1.3.0",
          chartVersion: "1.7.0",
          icon: "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png",
          navigate: "/marketplace/deploy",
        },
      ],
      "isChecked": false,
    },
    {
      "id": 1,
      "repo_type": "PRIVATE",
      "repo_name": "elastic-repo",
      // "repo_url": "https://kubernetes.github.io/ingress-nginx",
      "repo_url": "https://elastic.github.io/elastic/",
      "username": "admin",
      "icon": logoFav,
      "charts": [
        {
          id: 0,
          key: "elastic-repo",
          name: "apm-attacher",
          appVersion: "1.2.1",
          chartVersion: "1.0.0",
          icon: "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png",
          navigate: "/marketplace/deploy",
        },
        {
          id: 1,
          key: "elastic-repo",
          name: "apm-server",
          appVersion: "8.5.1",
          chartVersion: "8.5.1",
          icon: "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png",
          navigate: "/marketplace/deploy",
        },
        {
          id: 2,
          key: "elastic-repo",
          name: "eck-agent",
          appVersion: "1.3",
          chartVersion: "0.9.1",
          icon: "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png",
          navigate: "/marketplace/deploy",
        },
      ],
      "isChecked": false,
    },
  ],
  "atmosly_charts": [
    {
      "id": 0,
      "appName": "Atmosly",
      "appVersion": "1.2.1",
      "chartVersion": "1.3",
      "icon": logoFav,
    }
  ],
  "bitnami_charts": [
    {
      "id": null,
      "appName": "airflow",
      "appVersion": "2.8.2",
      "chartVersion": "16.8.3",
      "icon": "https://bitnami.com/assets/stacks/airflow/img/airflow-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "apache",
      "appVersion": "2.4.57",
      "chartVersion": "9.6.5",
      "icon": "https://bitnami.com/assets/stacks/apache/img/apache-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "argo-cd",
      "appVersion": "2.10.2",
      "chartVersion": "5.9.2",
      "icon": "https://bitnami.com/assets/stacks/argo-cd/img/argo-cd-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "argo-workflows",
      "appVersion": "3.5.5",
      "chartVersion": "6.7.2",
      "icon": "https://bitnami.com/assets/stacks/argo-workflows/img/argo-workflows-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "aspnet-core",
      "appVersion": "8.0.2",
      "chartVersion": "5.6.2",
      "icon": "https://bitnami.com/assets/stacks/aspnet-core/img/aspnet-core-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "cassandra",
      "appVersion": "4.0.7",
      "chartVersion": "9.7.7",
      "icon": "https://bitnami.com/assets/stacks/cassandra/img/cassandra-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "cert-manager",
      "appVersion": "1.11.1",
      "chartVersion": "0.9.6",
      "icon": "https://bitnami.com/assets/stacks/cert-manager/img/cert-manager-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "clickhouse",
      "appVersion": "24.2.1",
      "chartVersion": "5.2.3",
      "icon": "https://bitnami.com/assets/stacks/clickhouse/img/clickhouse-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "common",
      "appVersion": "2.9.2",
      "chartVersion": "2.9.2",
      "icon": "https://bitnami.com/downloads/logos/bitnami-mark.png"
    },
    {
      "id": null,
      "appName": "wordpress",
      "appVersion": "6.4.3",
      "chartVersion": "19.4.3",
      "icon": "https://bitnami.com/assets/stacks/wordpress/img/wordpress-stack-220x234.png"
    },
    {
      "id": null,
      "appName": "zookeeper",
      "appVersion": "3.8.0",
      "chartVersion": "9.2.7",
      "icon": "https://bitnami.com/assets/stacks/zookeeper/img/zookeeper-stack-220x234.png"
    }
  ],
  "elastic_charts": [
    {
      "id": null,
      "appName": "apm-attacher",
      "appVersion": "N/A",
      "chartVersion": "1.0.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "apm-server",
      "appVersion": "8.5.1",
      "chartVersion": "8.5.1",
      "icon": "https://helm.elastic.co/icons/apm.png"
    },
    {
      "id": null,
      "appName": "eck-agent",
      "appVersion": "N/A",
      "chartVersion": "0.9.1",
      "icon": "https://images.contentstack.io/v3/assets/bltefdd0b53724fa2ce/blt77c2da6e0198746e/620ac24e6662ca0a6f617114/icon-agent-32-color.svg"
    },
    {
      "id": null,
      "appName": "eck-beats",
      "appVersion": "N/A",
      "chartVersion": "0.9.1",
      "icon": "https://helm.elastic.co/icons/beats.png"
    },
    {
      "id": null,
      "appName": "eck-elasticsearch",
      "appVersion": "N/A",
      "chartVersion": "0.9.1",
      "icon": "https://helm.elastic.co/icons/elasticsearch.png"
    },
    {
      "id": null,
      "appName": "eck-fleet-server",
      "appVersion": "N/A",
      "chartVersion": "0.9.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "eck-kibana",
      "appVersion": "N/A",
      "chartVersion": "0.9.1",
      "icon": "https://helm.elastic.co/icons/kibana.png"
    },
    {
      "id": null,
      "appName": "eck-logstash",
      "appVersion": "N/A",
      "chartVersion": "0.9.1",
      "icon": "https://helm.elastic.co/icons/logstash.png"
    },
    {
      "id": null,
      "appName": "eck-operator",
      "appVersion": "2.9.0",
      "chartVersion": "2.9.0",
      "icon": "https://helm.elastic.co/icons/eck.png"
    },
    {
      "id": null,
      "appName": "eck-operator-crds",
      "appVersion": "2.9.0",
      "chartVersion": "2.9.0",
      "icon": "https://helm.elastic.co/icons/eck.png"
    },
    {
      "id": null,
      "appName": "eck-stack",
      "appVersion": "N/A",
      "chartVersion": "0.9.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "elasticsearch",
      "appVersion": "8.5.1",
      "chartVersion": "8.5.1",
      "icon": "https://helm.elastic.co/icons/elasticsearch.png"
    },
    {
      "id": null,
      "appName": "filebeat",
      "appVersion": "8.5.1",
      "chartVersion": "8.5.1",
      "icon": "https://helm.elastic.co/icons/beats.png"
    },
    {
      "id": null,
      "appName": "kibana",
      "appVersion": "8.5.1",
      "chartVersion": "8.5.1",
      "icon": "https://helm.elastic.co/icons/kibana.png"
    },
    {
      "id": null,
      "appName": "logstash",
      "appVersion": "8.5.1",
      "chartVersion": "8.5.1",
      "icon": "https://helm.elastic.co/icons/logstash.png"
    },
    {
      "id": null,
      "appName": "metricbeat",
      "appVersion": "8.5.1",
      "chartVersion": "8.5.1",
      "icon": "https://helm.elastic.co/icons/beats.png"
    },
    {
      "id": null,
      "appName": "pf-host-agent",
      "appVersion": "8.9.2",
      "chartVersion": "8.9.2",
      "icon": null
    },
    {
      "id": null,
      "appName": "profiling-collector",
      "appVersion": "8.12.2",
      "chartVersion": "8.12.2",
      "icon": "https://static-www.elastic.co/v3/assets/bltefdd0b53724fa2ce/blt6ec3007768940247/63337a1f4d11fa0cfdb55244/illustration-deployment-3-arrows.png"
    },
    {
      "id": null,
      "appName": "profiling-symbolizer",
      "appVersion": "8.12.2",
      "chartVersion": "8.12.2",
      "icon": "https://static-www.elastic.co/v3/assets/bltefdd0b53724fa2ce/blt6ec3007768940247/63337a1f4d11fa0cfdb55244/illustration-deployment-3-arrows.png"
    }
  ],
  "prometheus_charts": [
    {
      "id": null,
      "appName": "alertmanager",
      "appVersion": "v0.27.0",
      "chartVersion": "1.9.0",
      "icon": "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png"
    },
    {
      "id": null,
      "appName": "alertmanager-snmp-notifier",
      "appVersion": "v1.5.0",
      "chartVersion": "0.3.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "jiralert",
      "appVersion": "v1.3.0",
      "chartVersion": "1.7.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "kube-prometheus-stack",
      "appVersion": "0.38.1",
      "chartVersion": "9.4.9",
      "icon": "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png"
    },
    {
      "id": null,
      "appName": "kube-state-metrics",
      "appVersion": "2.9.2",
      "chartVersion": "5.9.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prom-label-proxy",
      "appVersion": "v0.8.1",
      "chartVersion": "0.7.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus",
      "appVersion": "2.13.1",
      "chartVersion": "9.7.5",
      "icon": "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png"
    },
    {
      "id": null,
      "appName": "prometheus-adapter",
      "appVersion": "v0.5.0",
      "chartVersion": "v0.5.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-blackbox-exporter",
      "appVersion": "v0.24.0",
      "chartVersion": "8.9.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-cloudwatch-exporter",
      "appVersion": "0.8.0",
      "chartVersion": "0.9.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-conntrack-stats-exporter",
      "appVersion": "v0.4.17",
      "chartVersion": "0.5.9",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-consul-exporter",
      "appVersion": "0.4.0",
      "chartVersion": "1.0.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-couchdb-exporter",
      "appVersion": "1.0",
      "chartVersion": "1.0.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-druid-exporter",
      "appVersion": "v0.11.0",
      "chartVersion": "1.1.0",
      "icon": "https://github.com/opstree/druid-exporter/blob/master/static/druid-exporter-logo.svg"
    },
    {
      "id": null,
      "appName": "prometheus-elasticsearch-exporter",
      "appVersion": "v1.7.0",
      "chartVersion": "5.6.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-fastly-exporter",
      "appVersion": "v7.6.1",
      "chartVersion": "0.3.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-ipmi-exporter",
      "appVersion": "v1.8.0",
      "chartVersion": "0.3.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-json-exporter",
      "appVersion": "v0.6.0",
      "chartVersion": "0.9.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-kafka-exporter",
      "appVersion": "v1.7.0",
      "chartVersion": "2.9.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-memcached-exporter",
      "appVersion": "v0.14.2",
      "chartVersion": "0.3.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-modbus-exporter",
      "appVersion": "0.4.0",
      "chartVersion": "0.1.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-mongodb-exporter",
      "appVersion": "0.40.0",
      "chartVersion": "3.5.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-mysql-exporter",
      "appVersion": "v0.15.1",
      "chartVersion": "2.5.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-nats-exporter",
      "appVersion": "0.9.3",
      "chartVersion": "2.9.3",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-nginx-exporter",
      "appVersion": "0.11.0",
      "chartVersion": "0.2.1",
      "icon": "https://raw.githubusercontent.com/cncf/artwork/master/prometheus/icon/color/prometheus-icon-color.svg"
    },
    {
      "id": null,
      "appName": "prometheus-node-exporter",
      "appVersion": "1.5.0",
      "chartVersion": "4.9.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-opencost-exporter",
      "appVersion": "1.108.0",
      "chartVersion": "0.1.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-operator",
      "appVersion": "0.38.1",
      "chartVersion": "9.3.2",
      "icon": "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png"
    },
    {
      "id": null,
      "appName": "prometheus-operator-admission-webhook",
      "appVersion": "0.70.0",
      "chartVersion": "0.9.0",
      "icon": "https://github.com/prometheus-operator/prometheus-operator/raw/main/Documentation/logos/prometheus-operator-logo.png"
    },
    {
      "id": null,
      "appName": "prometheus-operator-crds",
      "appVersion": "v0.71.2",
      "chartVersion": "9.0.1",
      "icon": "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png"
    },
    {
      "id": null,
      "appName": "prometheus-pgbouncer-exporter",
      "appVersion": "1.18.0",
      "chartVersion": "0.1.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-pingdom-exporter",
      "appVersion": "20190610-1",
      "chartVersion": "2.5.0",
      "icon": "https://raw.githubusercontent.com/cncf/artwork/master/prometheus/icon/color/prometheus-icon-color.svg"
    },
    {
      "id": null,
      "appName": "prometheus-pingmesh-exporter",
      "appVersion": "v1.2.1",
      "chartVersion": "0.4.0",
      "icon": "https://raw.githubusercontent.com/prometheus/prometheus.github.io/master/assets/prometheus_logo-cb55bb5c346.png"
    },
    {
      "id": null,
      "appName": "prometheus-postgres-exporter",
      "appVersion": "v0.15.0",
      "chartVersion": "5.3.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-pushgateway",
      "appVersion": "v1.7.0",
      "chartVersion": "2.7.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-rabbitmq-exporter",
      "appVersion": "v0.29.0",
      "chartVersion": "1.9.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-redis-exporter",
      "appVersion": "v1.58.0",
      "chartVersion": "6.2.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-smartctl-exporter",
      "appVersion": "v0.11.0",
      "chartVersion": "0.7.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-snmp-exporter",
      "appVersion": "v0.25.0",
      "chartVersion": "5.1.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-stackdriver-exporter",
      "appVersion": "v0.14.1",
      "chartVersion": "4.4.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-statsd-exporter",
      "appVersion": "v0.22.8",
      "chartVersion": "0.9.1",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-systemd-exporter",
      "appVersion": "0.6.0",
      "chartVersion": "0.2.0",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-to-sd",
      "appVersion": "0.5.2",
      "chartVersion": "0.4.2",
      "icon": null
    },
    {
      "id": null,
      "appName": "prometheus-windows-exporter",
      "appVersion": "0.25.1",
      "chartVersion": "0.3.1",
      "icon": null
    }
  ]
}

export const dummyMarketplaceHistoryData = [
  {
    id: 0,
    appName: "feature-flag-service",
    chartProvider: "bitnami",
    chartName: "apache",
    chartType: "others",
    status: "DEPLOYED",
    chartVersion: "16.8.3",
    appHealth: "HEALTHY",
    namespace: "atmosly.in",
    clusterName: "web-apps",
    clusterUniqueId: 0,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
  {
    id: 1,
    chartProvider: "bitnami",
    chartName: "airflow",
    appName: "trace-collector",
    chartType: "others",
    status: "DEPLOY_FAILED",
    appHealth: "UNHEALTHY",
    chartVersion: "10.6.2",
    namespace: "atmosly.in",
    clusterName: "web-apps",
    clusterUniqueId: 0,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
  {
    id: 2,
    chartProvider: "atmosly",
    chartName: "apm-attacher",
    appName: "atmosly-helm",
    chartType: "atmosly-managed",
    status: "DEPLOYED",
    appHealth: "HEALTHY",
    chartVersion: "10.0.0",
    namespace: "atmosly.in",
    clusterName: "web-apps",
    clusterUniqueId: 0,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
  {
    id: 3,
    chartProvider: "bitnami",
    chartName: "common",
    appName: "system-health",
    chartType: "user-managed",
    status: "DEPLOY_FAILED",
    appHealth: "UNHEALTHY",
    chartVersion: "2.16.1",
    namespace: "atmosly.in",
    clusterName: "web-apps",
    clusterUniqueId: 0,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
  {
    id: 4,
    appName: "inventory-service",
    chartProvider: "bitnami",
    chartName: "apache",
    chartType: "others",
    status: "DEPLOYED",
    chartVersion: "16.8.3",
    appHealth: "HEALTHY",
    namespace: "atmosly.in",
    clusterName: "app-server",
    clusterUniqueId: 1,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
  {
    id: 5,
    chartProvider: "bitnami",
    chartName: "airflow",
    appName: "data-lake",
    chartType: "others",
    status: "DEPLOY_FAILED",
    appHealth: "UNHEALTHY",
    chartVersion: "10.6.2",
    namespace: "atmosly.in",
    clusterName: "app-server",
    clusterUniqueId: 1,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
  {
    id: 6,
    chartProvider: "atmosly",
    chartName: "apm-attacher",
    appName: "load-tester",
    chartType: "atmosly-managed",
    status: "DEPLOYED",
    appHealth: "HEALTHY",
    chartVersion: "10.0.0",
    namespace: "atmosly.in",
    clusterName: "app-server",
    clusterUniqueId: 1,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
  {
    id: 7,
    chartProvider: "bitnami",
    chartName: "common",
    appName: "etl-jobs",
    chartType: "user-managed",
    status: "DEPLOY_FAILED",
    appHealth: "UNHEALTHY",
    chartVersion: "2.16.1",
    namespace: "atmosly.in",
    clusterName: "app-server",
    clusterUniqueId: 1,
    createdAt: "2024-02-20T13:05:45.889908Z",
    updatedAt: "2024-02-20T13:05:45.889908Z",
    deploymentDate: "2024-02-20T13:05:45.889908Z",
    calculatedUpdatedAt: `${timeSince(new Date("2024-02-20T13:05:45.889908Z"))} ago`,
    createdBy: "Raj Verma",
  },
]

export const dummyNotificationSettingData = {
  "is_email": false,
  "Cluster": [
    {
      "notification_id": 1,
      "title": "Cluster Created",
      "description": "Get notified when cluster is successfully created.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 2,
      "title": "Cluster Updated",
      "description": "Get notified when cluster is successfully updated.",
      "is_subscribed": false,
      "is_active": false
    },
    {
      "notification_id": 3,
      "title": "Cluster destroyed",
      "description": "Get notified when a cluster is destroyed.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 4,
      "title": "Cluster failed/unhealthy",
      "description": "Get notified when cluster creation is failed or cluster is unhealthy.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 25,
      "title": "Nodegroup Created",
      "description": "Get notified when cluster nodegroup is created",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 26,
      "title": "Nodegroup Updated",
      "description": "Get notified when cluster nodegroup is updated",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 27,
      "title": "Nodegroup Deleted",
      "description": "Get notified when cluster nodegroup is deleted",
      "is_subscribed": false,
      "is_active": true
    }
  ],
  "User": [
    {
      "notification_id": 5,
      "title": "User Registered",
      "description": "Get notified when a user is successfully registered.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 6,
      "title": "User Deleted",
      "description": "Get notified when a user is deleted.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 7,
      "title": "User Record Updated",
      "description": "Get notified when a user 's role is Updated.",
      "is_subscribed": false,
      "is_active": true
    }
  ],
  "Integration": [
    {
      "notification_id": 8,
      "title": "Integration Updates",
      "description": "Get notified when account is successfully integrated, account integration is failed and account is disconnected.",
      "is_subscribed": false,
      "is_active": true
    }
  ],
  "Project": [
    {
      "notification_id": 9,
      "title": "Project created",
      "description": "Get notified when project is successfully created.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 10,
      "title": "Project updated",
      "description": "Get notified when project is updated.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 11,
      "title": "Project deleted",
      "description": "Get notified when project is deleted.",
      "is_subscribed": false,
      "is_active": true
    }
  ],
  "Environment": [
    {
      "notification_id": 12,
      "title": "Environment deployed",
      "description": "Get notified when environment is successfully deployed.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 13,
      "title": "Environment updated",
      "description": "Get notified when environment is updated.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 14,
      "title": "Environment deleted",
      "description": "Get notified when environment is deleted.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 15,
      "title": "Environment failed/ unhealthy",
      "description": "Get notified when environment get failed or unhealthy.",
      "is_subscribed": false,
      "is_active": false
    },
    {
      "notification_id": 16,
      "title": "CICD pipeline - success",
      "description": "Get notified when pipeline is success.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 17,
      "title": "CICD pipeline - fail",
      "description": "Get notified when pipeline is fail.",
      "is_subscribed": false,
      "is_active": true
    }
  ],
  "Cost": [
    {
      "notification_id": 18,
      "title": "Daily budget notification",
      "description": "Get notified when daily cost exceed the budget.",
      "is_subscribed": false,
      "is_active": false
    },
    {
      "notification_id": 19,
      "title": "Monthly Cost Estimation",
      "description": "Get notification for monthly cost estimation.",
      "is_subscribed": false,
      "is_active": false
    }
  ],
  "Security Scan": [
    {
      "notification_id": 20,
      "title": "Security Scan",
      "description": "get notified when security scan completed.",
      "is_subscribed": false,
      "is_active": false
    }
  ],
  "Marketplace": [
    {
      "notification_id": 21,
      "title": "Helm Repo added",
      "description": "Get notified when helm chart is successfully added.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 22,
      "title": "Helm Repo updated",
      "description": "Get notified when helm chart is updated.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 23,
      "title": "Helm Repo deleted",
      "description": "Get notified when helm chart is deleted.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 24,
      "title": "Helm Chart deployed",
      "description": "Get notified when helm chart is deployed.",
      "is_subscribed": false,
      "is_active": true
    },
    {
      "notification_id": 28,
      "title": "Helm Chart deleted",
      "description": "Get notified when cluster nodegroup is deleted",
      "is_subscribed": false,
      "is_active": true
    }
  ]
}

export const dummyGuardrailData = {
  guardrailData: [
    {
      idx: 0,
      id: 1,
      name: "Environment Scale Up",
      title: "Environment Scale Up",
      isEnabled: false,
      theme: "Run on Schedule",
      action: "Environment Scale Up",
      createdBy: "Atmosly",
      createdAt: new Date(),
      updatedAt: new Date(),
      calculatedUpdatedAt: `${timeSince(new Date(new Date()))} ago`,
      owners: [],
      nextRunTime: new Date(),
      isOpen: false,
    },
    {
      idx: 1,
      id: 2,
      name: "Environment Scale Down",
      title: "Environment Scale Down",
      isEnabled: false,
      theme: "Run on Schedule",
      action: "Environment Scale Down",
      createdBy: "Atmosly",
      createdAt: new Date(),
      updatedAt: new Date(),
      calculatedUpdatedAt: `${timeSince(new Date(new Date()))} ago`,
      owners: [],
      nextRunTime: new Date(),
      isOpen: false,
    },
    {
      idx: 2,
      id: 3,
      name: "Cluster Destroy",
      title: "Cluster Destroy",
      isEnabled: false,
      theme: "Run on Schedule",
      action: "Cluster Destroy",
      createdBy: "Atmosly",
      createdAt: new Date(),
      updatedAt: new Date(),
      calculatedUpdatedAt: `${timeSince(new Date(new Date()))} ago`,
      owners: [],
      nextRunTime: new Date(),
      isOpen: false,
    }
  ],
  guardrailInfo: {
    0: {
      editing: false,
      view: true,
      status: false,
      id: 1,
      guardrailId: 1,
      guardrailName: "Environment Scale Up",
      description: "",
      owners: [],
      info: {
        id: 1,
        actionsList: [
          {
            "id": 1,
            "name": "Environment Scale Up",
            "description": "You can up the environments and schedule it.",
            "module": "environment"
          },
          {
            "id": 2,
            "name": "Environment Scale Down",
            "description": "You can down the environments and can schedule for later. ",
            "module": "environment"
          },
          {
            "id": 3,
            "name": "Environment Destroy",
            "description": "You can destroy the environments and can schedule for later. ",
            "module": "environment"
          },
          {
            "id": 4,
            "name": "Cluster Destroy",
            "description": "You can schedule destroy of multiple clusters",
            "module": "cluster"
          }
        ],
        name: "Environment Scale Up",
        //name: data.data.name,
        module: "environment",
        scheduledDate: new Date(),
        scheduledTime: new Date(new Date()).toLocaleString("en-US", { hour12: false, hour: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' }),
        repeatOn: [],
        repeatFrequency: 1,
        selectedFilters: [{ param: "name", filter: "is", value: "*" }], //[{param: "", filter: "", value: ""}]
        //filterOptions: data.data.filter_options,//{}
        repeatOccurrence: []
      }
    },
    1: {
      editing: false,
      view: true,
      status: false,
      id: 2,
      guardrailId: 2,
      guardrailName: "Environment Scale Down",
      description: "",
      owners: [],
      info: {
        id: 2,
        actionsList: [
          {
            "id": 1,
            "name": "Environment Scale Up",
            "description": "You can up the environments and schedule it.",
            "module": "environment"
          },
          {
            "id": 2,
            "name": "Environment Scale Down",
            "description": "You can down the environments and can schedule for later. ",
            "module": "environment"
          },
          {
            "id": 3,
            "name": "Environment Destroy",
            "description": "You can destroy the environments and can schedule for later. ",
            "module": "environment"
          },
          {
            "id": 4,
            "name": "Cluster Destroy",
            "description": "You can schedule destroy of multiple clusters",
            "module": "cluster"
          }
        ],
        name: "Environment Scale Down",
        //name: data.data.name,
        module: "environment",
        scheduledDate: new Date(),
        scheduledTime: new Date(new Date()).toLocaleString("en-US", { hour12: false, hour: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' }),
        repeatOn: [],
        repeatFrequency: 1,
        selectedFilters: [{ param: "name", filter: "is", value: "*" }], //[{param: "", filter: "", value: ""}]
        //filterOptions: data.data.filter_options,//{}
        repeatOccurrence: []
      }
    },
    2: {
      editing: false,
      view: true,
      status: false,
      id: 3,
      guardrailId: 3,
      guardrailName: "Cluster Destroy",
      description: "",
      owners: [],
      info: {
        id: 4,
        actionsList: [
          {
            "id": 1,
            "name": "Environment Scale Up",
            "description": "You can up the environments and schedule it.",
            "module": "environment"
          },
          {
            "id": 2,
            "name": "Environment Scale Down",
            "description": "You can down the environments and can schedule for later. ",
            "module": "environment"
          },
          {
            "id": 3,
            "name": "Environment Destroy",
            "description": "You can destroy the environments and can schedule for later. ",
            "module": "environment"
          },
          {
            "id": 4,
            "name": "Cluster Destroy",
            "description": "You can schedule destroy of multiple clusters",
            "module": "cluster"
          }
        ],
        name: "Cluster Destroy",
        //name: data.data.name,
        module: "cluster",
        scheduledDate: new Date(),
        scheduledTime: new Date(new Date()).toLocaleString("en-US", { hour12: false, hour: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' }),
        repeatOn: [],
        repeatFrequency: 1,
        selectedFilters: [{ param: "display name", filter: "is", value: "*" }], //[{param: "", filter: "", value: ""}]
        //filterOptions: data.data.filter_options,//{}
        repeatOccurrence: []
      }
    }
  }
};

export const dummyCostData = {
  totalCosts: {
    costs: {
      totalCost: 369.27,
      awsCost: 369.27,
      gcpCost: 0,
      azureCost: 0,
    },
    totalCostChartData: [
      { name: 'AWS', cost: 369.27 },
      { name: 'GCP', cost: 0 },
      { name: 'AZURE', cost: 0 }
    ],
    cost_by_account_ids: [
      {
        name: "897398831588",
        cost: 369.27,
        imgSrc: 'https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg'
      },
    ],
    aggregated_services_cost: [
      {
        "service_name": "EC2 - Other",
        "cost": 88.228942333,
        "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg"
      },
      {
        "service_name": "Amazon Elastic Compute Cloud - Compute",
        "cost": 64.9540446634,
        "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg"
      },
      {
        "service_name": "Amazon Elastic Container Service for Kubernetes",
        "cost": 51.937868247,
        "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg"
      },
      {
        "service_name": "AWS Key Management Service",
        "cost": 27.870967616000001,
        "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg"
      },
      {
        "service_name": "AWS Secrets Manager",
        "cost": 23.9608424262,
        "integration_logo": "https://atmosly-media.s3.amazonaws.com/integration/logos/aws.svg"
      }
    ]
  },
  costByPeriod: {
    last7Days: [
      {
        "name": "30-Sep",
        "897398831588": 20.65,
      },
      {
        "name": "01-Oct",
        "897398831588": 68.54,
      },
      {
        "name": "02-Oct",
        "897398831588": 50.88,
      },
      {
        "name": "03-Oct",
        "897398831588": 137.06,
      },
      {
        "name": "04-Oct",
        "897398831588": 27.29,
      },
      {
        "name": "05-Oct",
        "897398831588": 33.02,
      },
      {
        "name": "06-Oct",
        "897398831588": 52.47,
      }
    ],
    thisMonth: [
      {
        "name": "01-Oct",
        "897398831588": 68.54,
      },
      {
        "name": "02-Oct",
        "897398831588": 50.88,
      },
      {
        "name": "03-Oct",
        "897398831588": 137.06,
      },
      {
        "name": "04-Oct",
        "897398831588": 27.29,
      },
      {
        "name": "05-Oct",
        "897398831588": 33.02,
      },
      {
        "name": "06-Oct",
        "897398831588": 52.47,
      }
    ],
    lastMonth: [
      {
        "name": "01-Sep",
        "897398831588": 2.51
      },
      {
        "name": "02-Sep",
        "897398831588": 16.81
      },
      {
        "name": "03-Sep",
        "897398831588": 21.63
      },
      {
        "name": "04-Sep",
        "897398831588": 16.23
      },
      {
        "name": "05-Sep",
        "897398831588": 18.44
      },
      {
        "name": "06-Sep",
        "897398831588": 13.17
      },
      {
        "name": "07-Sep",
        "897398831588": 1.13
      },
      {
        "name": "08-Sep",
        "897398831588": 1.44
      },
      {
        "name": "09-Sep",
        "897398831588": 18.52
      },
      {
        "name": "10-Sep",
        "897398831588": 25.05
      },
      {
        "name": "11-Sep",
        "897398831588": 24.78
      },
      {
        "name": "12-Sep",
        "897398831588": 27.22
      },
      {
        "name": "13-Sep",
        "897398831588": 31.75
      },
      {
        "name": "14-Sep",
        "897398831588": 2.17
      },
      {
        "name": "15-Sep",
        "897398831588": 2.16
      },
      {
        "name": "16-Sep",
        "897398831588": 30.14
      },
      {
        "name": "17-Sep",
        "897398831588": 25.47
      },
      {
        "name": "18-Sep",
        "897398831588": 21.69
      },
      {
        "name": "19-Sep",
        "897398831588": 16.55
      },
      {
        "name": "20-Sep",
        "897398831588": 29.90
      },
      {
        "name": "21-Sep",
        "897398831588": 4.24
      },
      {
        "name": "22-Sep",
        "897398831588": 2.54
      },
      {
        "name": "23-Sep",
        "897398831588": 10.17
      },
      {
        "name": "24-Sep",
        "897398831588": 20.60
      },
      {
        "name": "25-Sep",
        "897398831588": 15.28
      },
      {
        "name": "26-Sep",
        "897398831588": 28.91
      },
      {
        "name": "27-Sep",
        "897398831588": 21.01
      },
      {
        "name": "28-Sep",
        "897398831588": 19.27
      },
      {
        "name": "29-Sep",
        "897398831588": 2.86
      },
      {
        "name": "30-Sep",
        "897398831588": 8.17
      }
    ],
    last3Months: [
      {
        "name": "Jul",
        "897398831588": 1092.64
      },
      {
        "name": "Aug",
        "897398831588": 417.12
      },
      {
        "name": "Sep",
        "897398831588": 479.81
      },
    ],
    last12Months: [
      {
        "name": "Oct",
        "897398831588": 0.00
      },
      {
        "name": "Nov",
        "897398831588": 0.00
      },
      {
        "name": "Dec",
        "897398831588": 0.00
      },
      {
        "name": "Jan",
        "897398831588": 376.42
      },
      {
        "name": "Feb",
        "897398831588": 1399.50
      },
      {
        "name": "Mar",
        "897398831588": 1571.67
      },
      {
        "name": "Apr",
        "897398831588": 1680.19
      },
      {
        "name": "May",
        "897398831588": 913.08
      },
      {
        "name": "Jun",
        "897398831588": 1104.32
      },
      {
        "name": "Jul",
        "897398831588": 1092.64
      },
      {
        "name": "Aug",
        "897398831588": 417.13
      },
      {
        "name": "Sep",
        "897398831588": 479.81
      }
    ]

  },
  costForecast: [
    {
      name: '897398831588 (atmosly-devops)',
      'Oct forecast': 1264.57,
      'Sep': 1114.58,
      'Oct': 369.27,
      "nameForThirdBar": 'Oct forecast',
      "nameForFirstBar": 'Sep',
      "nameForSecondBar": 'Oct'
    },
  ]
}

export const dummyNodesData = [
  {
      "uniqueId": "i-0a7097a0e6e88666e",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "instanceId": "i-0a7097a0e6e88666e",
      "state": "running",
      "instanceType": "t3.medium",
      "NodeUptimeSeconds": "21 minutes",
      "lifecycle": "SPOT",
      "usedCPU": "46634169n",
      "allocatableCPU": "1930m",
      "capacityCPU": 2,
      "usedRam": "914728Ki",
      "allocatableRam": "3388292Ki",
      "capacityRam": "3943300Ki",
      "podCount": 11,
      "totalPods": "70",
      "nodegroupName": "atmosly-addons",
      "capacityType": "spot",
      "privateIp": "10.0.45.112",
      "amiId": "ami-0bf4789aa17269164",
      "operatingSystem": "Amazon Linux 2",
      "nodeLabels": {
          "Addons-Services": "true",
          "Infra-Services": "true",
          "beta.kubernetes.io/arch": "amd64",
          "beta.kubernetes.io/instance-type": "t3.medium",
          "beta.kubernetes.io/os": "linux",
          "eks.amazonaws.com/capacityType": "SPOT",
          "eks.amazonaws.com/nodegroup": "atmosly-addons",
          "eks.amazonaws.com/nodegroup-image": "ami-0bf4789aa17269164",
          "eks.amazonaws.com/sourceLaunchTemplateId": "lt-0c33fdcb323adce40",
          "eks.amazonaws.com/sourceLaunchTemplateVersion": "1",
          "failure-domain.beta.kubernetes.io/region": "us-east-1",
          "failure-domain.beta.kubernetes.io/zone": "us-east-1a",
          "k8s.io/cloud-provider-aws": "5e67092c3687950141d9ab7ab18fbc9e",
          "kubernetes.io/arch": "amd64",
          "kubernetes.io/hostname": "ip-10-0-45-112.ec2.internal",
          "kubernetes.io/os": "linux",
          "node.kubernetes.io/instance-type": "t3.medium",
          "topology.ebs.csi.aws.com/zone": "us-east-1a",
          "topology.k8s.aws/zone-id": "use1-az6",
          "topology.kubernetes.io/region": "us-east-1",
          "topology.kubernetes.io/zone": "us-east-1a"
      }
  },
  {
      "uniqueId": "i-0263583808c23cb02",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "instanceId": "i-0263583808c23cb02",
      "state": "running",
      "instanceType": "t3.medium",
      "NodeUptimeSeconds": "21 minutes",
      "lifecycle": "SPOT",
      "usedCPU": "70588378n",
      "allocatableCPU": "1930m",
      "capacityCPU": 2,
      "usedRam": "1246588Ki",
      "allocatableRam": "3388292Ki",
      "capacityRam": "3943300Ki",
      "podCount": 16,
      "totalPods": "70",
      "nodegroupName": "atmosly-addons",
      "capacityType": "spot",
      "privateIp": "10.0.37.52",
      "amiId": "ami-0bf4789aa17269164",
      "operatingSystem": "Amazon Linux 2",
      "nodeLabels": {
          "Addons-Services": "true",
          "Infra-Services": "true",
          "beta.kubernetes.io/arch": "amd64",
          "beta.kubernetes.io/instance-type": "t3.medium",
          "beta.kubernetes.io/os": "linux",
          "eks.amazonaws.com/capacityType": "SPOT",
          "eks.amazonaws.com/nodegroup": "atmosly-addons",
          "eks.amazonaws.com/nodegroup-image": "ami-0bf4789aa17269164",
          "eks.amazonaws.com/sourceLaunchTemplateId": "lt-0c33fdcb323adce40",
          "eks.amazonaws.com/sourceLaunchTemplateVersion": "1",
          "failure-domain.beta.kubernetes.io/region": "us-east-1",
          "failure-domain.beta.kubernetes.io/zone": "us-east-1a",
          "k8s.io/cloud-provider-aws": "5e67092c3687950141d9ab7ab18fbc9e",
          "kubernetes.io/arch": "amd64",
          "kubernetes.io/hostname": "ip-10-0-37-52.ec2.internal",
          "kubernetes.io/os": "linux",
          "node.kubernetes.io/instance-type": "t3.medium",
          "topology.ebs.csi.aws.com/zone": "us-east-1a",
          "topology.k8s.aws/zone-id": "use1-az6",
          "topology.kubernetes.io/region": "us-east-1",
          "topology.kubernetes.io/zone": "us-east-1a"
      }
  },
  {
      "uniqueId": "i-0099dfc75d2eba74c",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "instanceId": "i-0099dfc75d2eba74c",
      "state": "running",
      "instanceType": "t3.medium",
      "NodeUptimeSeconds": "21 minutes",
      "lifecycle": "SPOT",
      "usedCPU": "58400105n",
      "allocatableCPU": "1930m",
      "capacityCPU": 2,
      "usedRam": "1070136Ki",
      "allocatableRam": "3388292Ki",
      "capacityRam": "3943300Ki",
      "podCount": 12,
      "totalPods": "70",
      "nodegroupName": "atmosly-addons",
      "capacityType": "spot",
      "privateIp": "10.0.44.117",
      "amiId": "ami-0bf4789aa17269164",
      "operatingSystem": "Amazon Linux 2",
      "nodeLabels": {
          "Addons-Services": "true",
          "Infra-Services": "true",
          "beta.kubernetes.io/arch": "amd64",
          "beta.kubernetes.io/instance-type": "t3.medium",
          "beta.kubernetes.io/os": "linux",
          "eks.amazonaws.com/capacityType": "SPOT",
          "eks.amazonaws.com/nodegroup": "atmosly-addons",
          "eks.amazonaws.com/nodegroup-image": "ami-0bf4789aa17269164",
          "eks.amazonaws.com/sourceLaunchTemplateId": "lt-0c33fdcb323adce40",
          "eks.amazonaws.com/sourceLaunchTemplateVersion": "1",
          "failure-domain.beta.kubernetes.io/region": "us-east-1",
          "failure-domain.beta.kubernetes.io/zone": "us-east-1a",
          "k8s.io/cloud-provider-aws": "5e67092c3687950141d9ab7ab18fbc9e",
          "kubernetes.io/arch": "amd64",
          "kubernetes.io/hostname": "ip-10-0-44-117.ec2.internal",
          "kubernetes.io/os": "linux",
          "node.kubernetes.io/instance-type": "t3.medium",
          "topology.ebs.csi.aws.com/zone": "us-east-1a",
          "topology.k8s.aws/zone-id": "use1-az6",
          "topology.kubernetes.io/region": "us-east-1",
          "topology.kubernetes.io/zone": "us-east-1a"
      }
  }
]

export const dummyPodsData = [
  {
      "namespace": "atmosly",
      "name": "argo-cd-argocd-application-controller-0",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.33.78",
      "containers": [
          {
              "name": "application-controller",
              "image": "quay.io/argoproj/argocd:v2.11.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-cd-argocd-applicationset-controller-6b587f968c-dhnjj",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.47.143",
      "containers": [
          {
              "name": "applicationset-controller",
              "image": "quay.io/argoproj/argocd:v2.11.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-cd-argocd-dex-server-855c7b48f-k9rmt",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.43.79",
      "containers": [
          {
              "name": "dex-server",
              "image": "ghcr.io/dexidp/dex:v2.36.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-cd-argocd-notifications-controller-6c85fd7464-q7r2q",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.47.102",
      "containers": [
          {
              "name": "notifications-controller",
              "image": "quay.io/argoproj/argocd:v2.11.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-cd-argocd-redis-656c69dc4d-x6ndv",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.137",
      "containers": [
          {
              "name": "redis",
              "image": "public.ecr.aws/docker/library/redis:7.0.11-alpine",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-cd-argocd-repo-server-79bff469b-ztrbb",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.36.216",
      "containers": [
          {
              "name": "repo-server",
              "image": "quay.io/argoproj/argocd:v2.11.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-cd-argocd-server-b5c4f9bc5-5k7hs",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.46.212",
      "containers": [
          {
              "name": "server",
              "image": "quay.io/argoproj/argocd:v2.11.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-workflows-server-5499cf7b8c-wgnb8",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.34.89",
      "containers": [
          {
              "name": "argo-server",
              "image": "quay.io/argoproj/argocli:v3.4.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "atmosly",
      "name": "argo-workflows-workflow-controller-6df966fb6c-rfkx7",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.41.103",
      "containers": [
          {
              "name": "controller",
              "image": "quay.io/argoproj/workflow-controller:v3.4.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "cert-manager",
      "name": "cert-manager-7b9f57f598-n5swr",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "28 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.79",
      "containers": [
          {
              "name": "cert-manager-controller",
              "image": "quay.io/jetstack/cert-manager-controller:v1.15.1",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "cert-manager",
      "name": "cert-manager-cainjector-7b7cd75fc-hnfh9",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "28 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.44.143",
      "containers": [
          {
              "name": "cert-manager-cainjector",
              "image": "quay.io/jetstack/cert-manager-cainjector:v1.15.1",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "cert-manager",
      "name": "cert-manager-webhook-658bf6d7c7-hxdzp",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "28 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.34.131",
      "containers": [
          {
              "name": "cert-manager-webhook",
              "image": "quay.io/jetstack/cert-manager-webhook:v1.15.1",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "karpenter",
      "name": "karpenter-7d8d64dc77-5vnln",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.32.23",
      "containers": [
          {
              "name": "controller",
              "image": "public.ecr.aws/karpenter/controller:v0.32.10@sha256:6aa27692a28a5eb8aaaef9618e0e31c2d0044b535b589227b0be687f7f9098e4",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "karpenter",
      "name": "karpenter-7d8d64dc77-hbxkg",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.42.159",
      "containers": [
          {
              "name": "controller",
              "image": "public.ecr.aws/karpenter/controller:v0.32.10@sha256:6aa27692a28a5eb8aaaef9618e0e31c2d0044b535b589227b0be687f7f9098e4",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "aws-node-27pf8",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "25 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.112",
      "containers": [
          {
              "name": "aws-node",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/amazon-k8s-cni:v1.19.0-eksbuild.1",
              "restarts": 0
          },
          {
              "name": "aws-eks-nodeagent",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/amazon/aws-network-policy-agent:v1.1.5-eksbuild.1",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "aws-node-ldgfw",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "25 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.37.52",
      "containers": [
          {
              "name": "aws-node",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/amazon-k8s-cni:v1.19.0-eksbuild.1",
              "restarts": 0
          },
          {
              "name": "aws-eks-nodeagent",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/amazon/aws-network-policy-agent:v1.1.5-eksbuild.1",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "aws-node-t9nv4",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "25 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.44.117",
      "containers": [
          {
              "name": "aws-node",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/amazon-k8s-cni:v1.19.0-eksbuild.1",
              "restarts": 0
          },
          {
              "name": "aws-eks-nodeagent",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/amazon/aws-network-policy-agent:v1.1.5-eksbuild.1",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "aws-node-termination-handler-bxqpx",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.44.117",
      "containers": [
          {
              "name": "aws-node-termination-handler",
              "image": "public.ecr.aws/aws-ec2/aws-node-termination-handler:v1.19.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "aws-node-termination-handler-t89wb",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.37.52",
      "containers": [
          {
              "name": "aws-node-termination-handler",
              "image": "public.ecr.aws/aws-ec2/aws-node-termination-handler:v1.19.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "aws-node-termination-handler-v68rk",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.112",
      "containers": [
          {
              "name": "aws-node-termination-handler",
              "image": "public.ecr.aws/aws-ec2/aws-node-termination-handler:v1.19.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "cluster-autoscaler-aws-cluster-autoscaler-5f79fdd477-d2h7m",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.33.212",
      "containers": [
          {
              "name": "aws-cluster-autoscaler",
              "image": "registry.k8s.io/autoscaling/cluster-autoscaler:v1.30.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "coredns-586b798467-dhfll",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "38 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.38.16",
      "containers": [
          {
              "name": "coredns",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/coredns:v1.11.1-eksbuild.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "coredns-586b798467-wwkd9",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "38 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.37.234",
      "containers": [
          {
              "name": "coredns",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/coredns:v1.11.1-eksbuild.8",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "ebs-csi-controller-5bbd784c94-28shj",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.35.105",
      "containers": [
          {
              "name": "ebs-plugin",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/aws-ebs-csi-driver:v1.36.0",
              "restarts": 0
          },
          {
              "name": "csi-provisioner",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-provisioner:v5.1.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "csi-attacher",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-attacher:v4.7.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "csi-snapshotter",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-snapshotter:v8.1.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "csi-resizer",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-resizer:v1.12.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "liveness-probe",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/livenessprobe:v2.14.0-eks-1-31-5",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "ebs-csi-controller-5bbd784c94-fg7db",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.35.242",
      "containers": [
          {
              "name": "ebs-plugin",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/aws-ebs-csi-driver:v1.36.0",
              "restarts": 0
          },
          {
              "name": "csi-provisioner",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-provisioner:v5.1.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "csi-attacher",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-attacher:v4.7.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "csi-snapshotter",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-snapshotter:v8.1.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "csi-resizer",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-resizer:v1.12.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "liveness-probe",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/livenessprobe:v2.14.0-eks-1-31-5",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "ebs-csi-node-dwkzv",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.42.202",
      "containers": [
          {
              "name": "ebs-plugin",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/aws-ebs-csi-driver:v1.36.0",
              "restarts": 0
          },
          {
              "name": "node-driver-registrar",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-node-driver-registrar:v2.12.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "liveness-probe",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/livenessprobe:v2.14.0-eks-1-31-5",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "ebs-csi-node-hm8xc",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.44.10",
      "containers": [
          {
              "name": "ebs-plugin",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/aws-ebs-csi-driver:v1.36.0",
              "restarts": 0
          },
          {
              "name": "node-driver-registrar",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-node-driver-registrar:v2.12.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "liveness-probe",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/livenessprobe:v2.14.0-eks-1-31-5",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "ebs-csi-node-lls4g",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.44.51",
      "containers": [
          {
              "name": "ebs-plugin",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/aws-ebs-csi-driver:v1.36.0",
              "restarts": 0
          },
          {
              "name": "node-driver-registrar",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/csi-node-driver-registrar:v2.12.0-eks-1-31-5",
              "restarts": 0
          },
          {
              "name": "liveness-probe",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/livenessprobe:v2.14.0-eks-1-31-5",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "kube-proxy-gsqmz",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "32 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.44.117",
      "containers": [
          {
              "name": "kube-proxy",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/kube-proxy:v1.30.0-minimal-eksbuild.3",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "kube-proxy-kf7zs",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "31 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.112",
      "containers": [
          {
              "name": "kube-proxy",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/kube-proxy:v1.30.0-minimal-eksbuild.3",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "kube-proxy-r5bsd",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "32 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.37.52",
      "containers": [
          {
              "name": "kube-proxy",
              "image": "602401143452.dkr.ecr.us-east-1.amazonaws.com/eks/kube-proxy:v1.30.0-minimal-eksbuild.3",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "metrics-server-59d7d594f5-k8fhb",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.32.114",
      "containers": [
          {
              "name": "metrics-server",
              "image": "registry.k8s.io/metrics-server/metrics-server:v0.6.4",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "reloader-reloader-6bfb44865f-4szfd",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "29 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.42.173",
      "containers": [
          {
              "name": "reloader-reloader",
              "image": "ghcr.io/stakater/reloader:v1.0.115",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "vertical-pod-autoscaler-admission-controller-75d5f8fbbf-f9q22",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "28 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.41.105",
      "containers": [
          {
              "name": "admission-controller",
              "image": "registry.k8s.io/autoscaling/vpa-admission-controller:0.14.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "vertical-pod-autoscaler-recommender-7f668c8579-scfq6",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "28 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.40.95",
      "containers": [
          {
              "name": "recommender",
              "image": "registry.k8s.io/autoscaling/vpa-recommender:0.14.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kube-system",
      "name": "vertical-pod-autoscaler-updater-55d4dff575-b2zkj",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "28 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.45",
      "containers": [
          {
              "name": "updater",
              "image": "registry.k8s.io/autoscaling/vpa-updater:0.14.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "kubernetes-dashboard",
      "name": "kubernetes-dashboard-6bf4bd4bfc-x9flm",
      "status": "Running",
      "node_name": "ip-10-0-37-52.ec2.internal",
      "age": "26 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.122",
      "containers": [
          {
              "name": "kubernetes-dashboard",
              "image": "kubernetesui/dashboard:v2.7.0",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "nginx",
      "name": "nginx-ingress-nginx-controller-8bd8c44b4-k79n8",
      "status": "Running",
      "node_name": "ip-10-0-45-112.ec2.internal",
      "age": "27 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.32.140",
      "containers": [
          {
              "name": "controller",
              "image": "registry.k8s.io/ingress-nginx/controller:v1.11.0@sha256:a886e56d532d1388c77c8340261149d974370edca1093af4c97a96fb1467cb39",
              "restarts": 0
          }
      ]
  },
  {
      "namespace": "nginx",
      "name": "nginx-ingress-nginx-controller-8bd8c44b4-khflh",
      "status": "Running",
      "node_name": "ip-10-0-44-117.ec2.internal",
      "age": "28 minutes ago",
      "cpu_usage": " ",
      "memory_usage": " ",
      "max_cpu": " ",
      "max_memory": " ",
      "pod_ip": "10.0.45.10",
      "containers": [
          {
              "name": "controller",
              "image": "registry.k8s.io/ingress-nginx/controller:v1.11.0@sha256:a886e56d532d1388c77c8340261149d974370edca1093af4c97a96fb1467cb39",
              "restarts": 0
          }
      ]
  }
]
