import styled from 'styled-components';
import { palette } from 'styled-theme';

const BoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid ${palette('border', 0)};
  margin: 0 0 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .isoWarningText {
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    color: ${palette('error', 0)};
    // padding-left: ${props => props['data-rtl'] === 'rtl' ? 'inherit' : '13px'};
    // padding-right: ${props => props['data-rtl'] === 'rtl' ? '13px' : 'inherit'};
    margin: 15px 0;
    position: relative;
    display: flex;
    align-items: center;
  }

  .isoHelperText {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    color: ${palette('error', 0)};
    padding-left: ${props =>
    props['data-rtl'] === 'rtl' ? 'inherit' : '13px'};
    padding-right: ${props =>
    props['data-rtl'] === 'rtl' ? '13px' : 'inherit'};
    margin: 15px 0;
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: '*';
      color: ${palette('error', 0)};
      padding-right: 3px;
      font-size: 14px;
      line-height: 1;
      position: absolute;
      top: 2px;
      left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
      right: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
    }
  }

  .isoHelperText.labels{
    font-size: 14px;
    margin: 3px 0px;
    padding-left: 0;

    &:before {
      content: '';
      top: 0;
      padding-right: 0;
    }
  }
  
  .isoHelperText.nomargin{
    margin: 0;
    &:before {
      top: auto;
    }
  }

  /* Tooltip container */
  .tooltip {
    position: relative;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  @media only screen and (max-width: 767px) {
    padding: 20px;
    ${'' /* margin: 0 10px 30px; */};
  }

  &.half {
    width: calc(50% - 34px);
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .readmeScroll{
    height: 600px !important;
    margin-bottom: 15px;

    div:first-child{
      position: relative !important;
      inset: 0px !important;
      overflow: hidden !important;
      overflow-y: scroll !important;
      margin: 10px 10px;
      margin-right: -17px !important;
      height: inherit !important;
      // max-height: 400px !important;

      div{
        display: inherit !important;
        margin-top: 10px !important;
      }

      .readmeWrapper{
        margin-top: 0 !important;
        // margin-right: 20px !important;

        .custom-readme{
          margin: 0 !important;

          p:last-child{
            margin-bottom: 17px !important;
          }
        }
      }
    }
  }
  
  .yamlScroll{
    // height: auto !important;

    div:first-child{
      position: relative !important;
      inset: 0px !important;
      overflow: hidden !important;
      overflow-y: scroll !important;
      margin: 10px 10px;
      margin-right: -22px !important;
      max-height: 400px !important;

      // div{
      //   display: inherit !important;
      //   margin-top: 10px !important;
      // }

      .codeViewer{
        .isoLayoutContentWrapper{
          padding: 0;
          overflow: hidden !important;

          .isoBoxWrapper{
            margin: 0;
            overflow: hidden;
            padding: 0;

            div:first-child{
              margin: 0;
            }

            .isoExampleWrapper{
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &.customMarketplaceYaml{
    .codeViewer{
      display: flex;
      justify-content: center;
      align-items: start;
      margin: 0px;
      max-height: 400px;

      .isoLayoutContentWrapper{
        padding: 20px 20px;
        // max-height: 400px;
        // overflow: hidden !important;

        .cm-theme-light{
          // max-height: 400px;
        }
      }
    }
  }

  &.project-name{
    border-radius: 20px;
    height: 699px;
    margin-top: 35px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

    // @media screen and (max-width: 1280px) {
    //   height: 347px;
    // }
    
    @media screen and (max-height: 560px) {
      height: 347px;
    }

    .custom-reactFlow-workflow{
      .react-flow__attribution.bottom{
        // display: none;
        // font-size: 5px;
      }
    }
    
    &.custom-reactFlow-workflow-disabled{
      .react-flow__pane{
        cursor: pointer;
      }
    }
  }

  &.marketplaceListCards{
    border: 1px solid rgb(208, 213, 221);
    border-radius: 20px;
    position: relative;
    padding: 0px;
    width: 300px;
    height: fit-content;
    // margin-right: 36px;

    .top-content{
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-top: 0px;
      padding: 20px;
      padding-bottom: 14px;

      .header-card{
        // width: 50%;

        .title-img{
          width: 45px;
          height: 45px;
          border-radius: 100%;
          padding: 10px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        }

        .header-title{
          display: flex;
          flex-direction: column;
          justify-content: start;
          margin-top: 14px;

          .header-text{
            color: #1F1F1F;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }

          .header-subText{
            color: #9B9B9B;
            font-family: Nunito Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }

      .header-status{
        display: inline-flex;
        // height: 29px;
        padding: 4px 14px;
        justify-content: center;
        align-items: center;
        // gap: 6px;
        flex-shrink: 0;
        border-radius: 17px;

        .status-text{
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .middle-content{
      padding: 20px;
      padding-top: 0px;

      .middle-content-container{
        margin-top: 10px;
        display: flex;

        .middle-content-text{
          width: 100px;
          color: #292929;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }

        .middle-content-subtext{ 
          color: #9B9B9B;
          font-family: Nunito Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
  
  &.marketplaceListRightDrawer{
    position: relative;
    padding: 125px 40px 40px 35px;
    width: 300px;
    height: fit-content;
    margin-bottom: 0;

    .top-content{
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-top: 0px;
      padding: 20px;
      padding-bottom: 14px;

      .header-card{
        // width: 50%;

        .title-img{
          width: 70px;
          height: 70px;
          border-radius: 100%;
          padding: 10px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        }

        .header-title{
          display: flex;
          flex-direction: column;
          justify-content: start;
          margin-top: 30px;

          .header-text{
            color: #1F1F1F;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
          }

          .header-subText{
            color: #9B9B9B;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin-top: 8px;
          }
        }
      }

      .header-status{
        display: inline-flex;
        // height: 29px;
        padding: 4px 14px;
        justify-content: center;
        align-items: center;
        // gap: 6px;
        flex-shrink: 0;
        border-radius: 17px;
        margin-left: 20px;

        .status-text{
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .middle-content{
      padding: 20px;
      padding-top: 0px;

      .middle-content-container{
        margin-top: 10px;
        display: flex;

        .middle-content-text{
          width: 100px;
          color: #292929;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }

        .middle-content-subtext{ 
          color: #9B9B9B;
          font-family: Nunito Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  &.dashboard-card{
    .integration-status-fail-pass{
      margin-top: 20px;
      margin-bottom: 30px;

      @media screen and (max-width: 1280px) {
        display: block;
        margin-top: 50px;
        // margin-bottom: 30px;
      }
      
      // @media screen and (min-width: 1734px) {
      // }
      
      // @media screen and (max-width: 1024px) {
      // }

      .fail-pass-container{
        display: flex;
        justify-content: center;
        align-items: center;

        // @media screen and (max-width: 1280px) {
        //   margin-right: 0;
        //   // margin-bottom: 30px;
        // }
        
        // @media screen and (min-width: 1734px) {
        //   // height: 230px;
        // }
        
        // @media screen and (max-width: 1024px) {
        //   // height: 230px;
        // }
      }
      
      .fail-pass-container.first{
        margin-right: 50px;

        @media screen and (max-width: 1280px) {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }

      .integration-status-footer{
        margin-top: 10px;
        z-index: 1;
        position: relative;

        @media screen and (max-width: 1280px) {
          margin-top: 43px;
        }
      }

      .statusBadge{
        width: 15px;
        height: 15px;
        border-radius: 100%;
        margin-right: 8px;
      }

      .statusBadge.success{
        background: #53A551;
      }
      
      .statusBadge.fail{
        background: #D20A0A;
      }
      
      .statusText{
        color: #9B9B9B;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .statusCountValue{
        color: #1E1B39;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
    
    .service-status-fail-pass{
      .know-more{
        font-family: "Nunito Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: rgb(143, 143, 143);
        display: flex;
        align-items: end;
      }

      .statusBadgeContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        border-radius: 8px;
        border: solid 1px #e5e5ef;

        .statusBadge{
          width: 8.3px;
          height: 8.3px;
          border-radius: 100%;
          margin-right: 8px;
        }

        .statusBadge.success{
          background: #53A551;
        }
        
        .statusBadge.fail{
          background: #D20A0A;
        }
        
        .statusText{
          color: #1e1b39;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .integration-status-dashboard-filters{
      height: 230px;

      @media screen and (max-width: 1280px) {
        height: 160px;
      }
      
      @media screen and (min-width: 1734px) {
        // height: 230px;
      }
      
      @media screen and (max-width: 1024px) {
        // height: 230px;
      }

      .integration-status-pieChart-container{

      }
    }
  }
`;

export { BoxWrapper };
