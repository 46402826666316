import React, { createContext, useEffect, useReducer, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { googleLogout } from '@react-oauth/google';
import initialData from "../redux/scrumBoard/data";
import { fetchingReducer, formatErrors, timeSince } from "./helper";
import api from "../api";
import { dummyProfileData } from "./tryWithoutLogin";
import IntlMessages from "../components/utility/intlMessages";

export const AppContext = createContext({});

let { clusters, columns, tasks } = initialData;

const initialCluster = {
  id: "",
  title: "",
  accountId: "",
  integration: "",
  alias: "",
  region: "",
  accountType: "",
  zonesAvailable: [],
  selectedCluster: {},
  isDataFetched: false,
  vpc: "",
  vpcData: {},
  availability_zones: [],
  prerequisiteInfo: {},
  prod: {
    tags: [{ name: "", value: "" }],
    selectedAddons: [],
    naz: [],
    publicSubnets: [],
    privateSubnets: [],
    subnets: [],
    hostedZones: [],
    vpcCidr: "10.0.0.0/16",
    flowLogMax: "60",    // flow_log_max_aggregation_interval
    logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
    clusterLogRetention: "90",    // cluster_log_retention_in_days
    endpointPublicAccess: true,
    endpointPrivateAccess: true,
    publicAccessCidr: "0.0.0.0/0",
    versionsAvailable: [],
    addonsData: {},
    eks: "",
    serverInsType: "",
    instanceTypeMin: "",
    instanceTypeMax: "",
    backups: false,
    natGatway: true,
    vpns: true,
    vpcFlowLogs: true,    // enable_flow_log
    enableLogTypes: true,
    addonIds: {},
    apiServer: false,
    audit: false,
    authenticator: false,
    controllerManager: false,
    scheduler: false,
    addons: {
      cert_manager_enabled: false,
      cert_manager_letsencrypt_email: "",
      cert_manager_install_letsencrypt_http_issuers: false,
      enable_karpenter: false,
      karpenter_provisioner_enabled: false,
      enable_cluster_autoscaler: true,
      enable_cluster_propotional_autoscaler: false,
      enable_single_az_ebs_gp3_storage_class: true,
      create_efs_storage_class: false,
      enable_amazon_eks_aws_ebs_csi_driver: true,
      enable_amazon_eks_vpc_cni: true,
      create_service_monitor_crd: true,
      enable_reloader: false,
      enable_metrics_server: true,
      enable_ingress_nginx: true,
      enable_external_secrets: false,
      enable_keda: false,
      enable_istio: false,
      enable_aws_node_termination_handler: true,
      nginx_alb_controler: false,
      nginx_ingress_controler: true,
      pgl_stack: true,
      k8s_dashboard: true,
      argoFlow: true,

      delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
    },
    pglStack: {
      appName: "",
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      loki: true,
      cloudWatchCollector: true,
      refreshInterval: "30",
      objExpiration: "90",
      hostedZonesList: [],
    },
    k8sDashboard: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    },
    argoFlow: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    }
  },
  "non-prod": {
    tags: [{ name: "", value: "" }],
    selectedAddons: [],
    naz: [],
    publicSubnets: [],
    privateSubnets: [],
    subnets: [],
    hostedZones: [],
    vpcCidr: "10.0.0.0/16",
    flowLogMax: "600",     // flow_log_max_aggregation_interval
    logRetention: "60",     // flow_log_cloudwatch_log_group_retention_in_days
    clusterLogRetention: "90",  // cluster_log_retention_in_days
    eks: "",
    serverInsType: "",
    instanceTypeMin: "",
    instanceTypeMax: "",
    endpointPublicAccess: true,
    endpointPrivateAccess: true,
    publicAccessCidr: "0.0.0.0/0",
    versionsAvailable: [],
    addonsData: {},
    backups: false,
    natGatway: true,
    vpns: true,
    vpcFlowLogs: false,    // enable_flow_log
    enableLogTypes: false,
    addonIds: {},
    apiServer: false,
    audit: false,
    authenticator: false,
    controllerManager: false,
    scheduler: false,
    addons: {
      cert_manager_enabled: false,
      cert_manager_letsencrypt_email: "",
      cert_manager_install_letsencrypt_http_issuers: false,
      enable_karpenter: false,
      karpenter_provisioner_enabled: false,
      enable_cluster_autoscaler: true,
      enable_cluster_propotional_autoscaler: false,
      enable_single_az_ebs_gp3_storage_class: true,
      create_efs_storage_class: false,
      enable_amazon_eks_aws_ebs_csi_driver: true,
      enable_amazon_eks_vpc_cni: true,
      create_service_monitor_crd: true,
      enable_reloader: false,
      enable_metrics_server: true,
      enable_ingress_nginx: true,
      enable_external_secrets: false,
      enable_keda: false,
      enable_istio: false,
      enable_aws_node_termination_handler: true,
      nginx_alb_controler: false,
      nginx_ingress_controler: true,
      pgl_stack: true,
      k8s_dashboard: true,
      argoFlow: true,

      delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
    },
    pglStack: {
      appName: "",
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      loki: true,
      cloudWatchCollector: false,
      refreshInterval: "30",
      objExpiration: "90",
      hostedZonesList: [],
    },
    k8sDashboard: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    },
    argoFlow: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    }
  },
  dev: {
    tags: [{ name: "", value: "" }],
    selectedAddons: [],
    naz: [],
    publicSubnets: [],
    privateSubnets: [],
    subnets: [],
    hostedZones: [],
    vpcCidr: "",
    flowLogMax: "0",
    logRetention: "60",
    clusterLogRetention: "90",
    endpointPublicAccess: true,
    endpointPrivateAccess: true,
    publicAccessCidr: "0.0.0.0/0",
    versionsAvailable: [],
    addonsData: {},
    eks: "",
    serverInsType: "",
    instanceTypeMin: "",
    instanceTypeMax: "",
    backups: false,
    natGatway: false,
    vpns: false,
    vpcFlowLogs: true,
    enableLogTypes: false,
    addonIds: {},
    apiServer: false,
    audit: false,
    authenticator: false,
    controllerManager: false,
    scheduler: false,
    addons: {
      cert_manager_enabled: true,
      cert_manager_letsencrypt_email: "",
      cert_manager_install_letsencrypt_http_issuers: false,
      enable_karpenter: false,
      karpenter_provisioner_enabled: false,
      enable_cluster_autoscaler: true,
      enable_cluster_propotional_autoscaler: false,
      enable_single_az_ebs_gp3_storage_class: true,
      create_efs_storage_class: false,
      enable_amazon_eks_aws_ebs_csi_driver: true,
      enable_amazon_eks_vpc_cni: true,
      create_service_monitor_crd: true,
      enable_reloader: false,
      enable_metrics_server: true,
      enable_ingress_nginx: true,
      enable_external_secrets: false,
      enable_keda: false,
      enable_istio: false,
      enable_aws_node_termination_handler: true,
      nginx_alb_controler: false,
      nginx_ingress_controler: true,
      pgl_stack: false,
      k8s_dashboard: false,
      argoFlow: false,

      delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
    },
    updateAddons: {},
    pglStack: {
      appName: "",
      hostname: "",
      loki: false,
      cloudWatchCollector: false,
      hostname: "",
      objExpiration: "",
      hostedZonesList: [],
    },
    k8sDashboard: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    },
    argoFlow: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    }
  },
  existingVpc: {
    tags: [{ name: "", value: "" }],
    selectedAddons: [],
    naz: [],
    publicSubnets: [],
    privateSubnets: [],
    subnets: [],
    hostedZones: [],
    vpcCidr: "10.0.0.0/16",
    flowLogMax: "60",    // flow_log_max_aggregation_interval
    logRetention: "60",    // flow_log_cloudwatch_log_group_retention_in_days
    clusterLogRetention: "90",    // cluster_log_retention_in_days
    endpointPublicAccess: true,
    endpointPrivateAccess: true,
    publicAccessCidr: "0.0.0.0/0",
    versionsAvailable: [],
    addonsData: {},
    eks: "",
    serverInsType: "",
    instanceTypeMin: "",
    instanceTypeMax: "",
    backups: false,
    natGatway: true,
    vpns: true,
    vpcFlowLogs: false,    // enable_flow_log
    enableLogTypes: true,
    addonIds: {},
    apiServer: false,
    audit: false,
    authenticator: false,
    controllerManager: false,
    scheduler: false,
    addons: {
      cert_manager_enabled: false,
      cert_manager_letsencrypt_email: "",
      cert_manager_install_letsencrypt_http_issuers: false,
      enable_karpenter: false,
      karpenter_provisioner_enabled: false,
      enable_cluster_autoscaler: true,
      enable_cluster_propotional_autoscaler: false,
      enable_single_az_ebs_gp3_storage_class: true,
      create_efs_storage_class: false,
      enable_amazon_eks_aws_ebs_csi_driver: true,
      enable_amazon_eks_vpc_cni: true,
      create_service_monitor_crd: true,
      enable_reloader: false,
      enable_metrics_server: true,
      enable_ingress_nginx: true,
      enable_external_secrets: false,
      enable_keda: false,
      enable_istio: false,
      enable_aws_node_termination_handler: true,
      nginx_alb_controler: false,
      nginx_ingress_controler: true,
      pgl_stack: true,
      k8s_dashboard: true,
      argoFlow: true,

      delete_k8s_dashboard: false //this field will be false by default. It will be true when the user manually disables the enabled k8s dashboard while updating the cluster
    },
    pglStack: {
      appName: "",
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      loki: true,
      cloudWatchCollector: true,
      refreshInterval: "30",
      objExpiration: "90",
      hostedZonesList: [],
    },
    k8sDashboard: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    },
    argoFlow: {
      recordPrefix: "",
      hostname: "atmosly.in",
      zoneId: "",
      dnsRecordType: "",
      hostedZonesList: [],
    }
  }
}

const initialProject = {
  id: "",
  name: "",
  title: "",
  created_at: new Date(),
  progress: "",
  selectedServiceId: "",
  tasks: [],
  editing: false,
  //accountDetails: {},
  importDetails: {},
  columns: {
    'app': {
      id: 'app',
      board_id: 'board-1',
      task_orders: [],
      title: 'APPLICATIONS',
      editing: false,
    },
    'dataSource': {
      id: 'dataSource',
      board_id: 'board-1',
      task_orders: [],
      title: 'DATA SOURCES',
      editing: false,
      file: null
    },
    // 'queues': {
    //   id: 'queues',
    //   board_id: 'board-2',
    //   task_orders: [],
    //   title: 'QUEUES',
    //   editing: false,
    // },
    // 'thirdParties': {
    //   id: 'thirdParties',
    //   board_id: 'board-1',
    //   task_orders: [],
    //   title: 'THIRD PARTIES',
    //   editing: false,
    // },
  },
}

let convertToFrontend = {
  "application": "app",
  "data_source": "dataSource",
  "queue": "queues",
  "third_party_app": "thirdParties",
}

let convertToBackend = {
  "app": "application",
  "dataSource": "data_source",
  "queues": "queue",
  "thirdParties": "third_party_app",
}

const projectBoards = [
  // {
  //   id: 1,
  //   name: "ATMOSLY",
  //   title: "ATMOSLY",
  //   created_at: new Date(),
  //   progress: "50%",
  //   category: "Admin",
  //   open_to_company: "squareops",
  //   tasks: [
  //     {
  //       id: 0,
  //       name: "App-1",
  //       branch: "",
  //       description: 'Take Out the garbage 1',
  //       source: "GitHub",
  //       dockerFilePath: "docker/file/path",
  //       repoUrl: "https://github.com",
  //       columnId: 'app',
  //       editing: false,
  //       created_at: '2019-01-01',
  //       updated_at: '',
  //     },
  //     {
  //       id: 1,
  //       name: "Mysql",
  //       dataSource: "Mysql",
  //       version: "",
  //       serviceType: "",
  //       instanceType: "",
  //       // dockerFilePath: "docker/file/path",
  //       // description: 'Let Fix The Task manager using Redux 2',
  //       columnId: 'dataSource',
  //       editing: false,
  //       created_at: '2019-01-01',
  //       updated_at: '',
  //     },
  //     {
  //       id: 4,
  //       name: "Kafka",
  //       queue: "Kafka",
  //       columnId: 'queues',
  //       editing: false,
  //       created_at: '2019-01-01',
  //       updated_at: '',
  //     },
  //     {
  //       id: 6,
  //       name: "Google Analitics",
  //       // tpName: "Google Analitics",
  //       url: "http://google.com/",
  //       description: "This is my third party description",
  //       columnId: 'thirdParties',
  //       editing: false,
  //       created_at: '2019-01-01',
  //       updated_at: '',
  //     },
  //   ],
  //   columns: {
  //     'app': {
  //       id: 'app',
  //       board_id: 'board-1',
  //       task_orders: [0],
  //       title: 'APPLICATIONS',
  //       editing: false,
  //     },
  //     'dataSource': {
  //       id: 'dataSource',
  //       board_id: 'board-1',
  //       task_orders: [3, 1, 2],
  //       title: 'DATA SOURCES',
  //       editing: false,
  //     },
  //     'queues': {
  //       id: 'queues',
  //       board_id: 'board-2',
  //       task_orders: [4, 5],
  //       title: 'QUEUES',
  //       editing: false,
  //     },
  //     'thirdParties': {
  //       id: 'thirdParties',
  //       board_id: 'board-1',
  //       task_orders: [6],
  //       title: 'THIRD PARTIES',
  //       editing: false,
  //     },
  //   },
  // }
  // }
];

let tempNodeData = [
  {
    id: "1",
    type: "sourceNode",
    data: { label: "Source", toolsOrder: [0, 1, 2] },
    position: { x: 0, y: 50 },
    style: { borderRadius: "20px" },
    sourcePosition: "right",
  },
  // {
  //     id: "2",
  //     type: "selectorNode",
  //     data: { onChange: onChange, color: initBgColor },
  //     style: { border: "1px solid #777", padding: 10 },
  //     position: { x: 300, y: 50 },
  // },
  {
    id: "2",
    type: "buildNode",
    data: { label: "Build", toolsOrder: [0, 1, 2] },
    position: { x: 400, y: 0 },
    style: { borderRadius: "20px" },
    // targetPosition: "right",
  },
  {
    id: "3",
    type: "deployNode",
    data: { label: "Deploy", toolsOrder: [0, 1, 2] },
    position: { x: 800, y: 50 },
    style: { borderRadius: "20px" },
    // sourcePosition: "left",
  },
];


let tempEdgesData = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: false,
    style: { stroke: "#ecf4fb", strokeWidth: 3 },
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    animated: false,
    style: { stroke: "#ecf4fb", strokeWidth: 3 },
  },
  // {
  //     id: "e3-4",
  //     source: "3",
  //     target: "4",
  //     animated: true,
  //     style: { stroke: "#fff" },
  // },
  // {
  //     id: "e2a-3",
  //     source: "2",
  //     target: "3",
  //     sourceHandle: "a",
  //     animated: true,
  //     style: { stroke: "#fff" },
  // },
  // {
  //     id: "e2b-4",
  //     source: "2",
  //     target: "4",
  //     sourceHandle: "b",
  //     animated: true,
  //     style: { stroke: "#fff" },
  // },
]

export const ContextProvider = (props) => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState({
    drawerType: '',
    drawerProps: {
      data: null,
      columnId: null,
    },
  });
  const [openModel, setOpenModel] = useState({
    modalType: '',
    modalProps: {
      data: null,
      columnId: null,
    },
  });

  // const [dummyClusterListData, setDummyClusterListData] = useState(dummy_cluster_data);
  // const [dummyClusterData, setDummyClusterData] = useState(dummy_cluster_overview);
  const [fetching, dispatch] = useReducer(fetchingReducer, {});
  const [refreshUserDetails, setRefreshUserDetails] = useState(false);
  const [checkTryWithoutLogin, setCheckTryWithoutLogin] = useState(false);
  const [profileData, setProfileData] = useState();
  const [tooltip, setTooltip] = useState(false);
  const [triggeredAddRepo, setTriggeredAddRepo] = useState(false);
  const [trigerNodgroups, setTrigerNodgroups] = useState(false);
  const [triggerMarketplace, setTriggerMarketplace] = useState(false);
  const [trigerMarketplaceList, setTrigerMarketplaceList] = useState(false);
  const [trigerInviteUser, setTrigerInviteUser] = useState(false);
  const [nodgroupsUpdateData, setNodgroupsUpdateData] = useState();
  const [marketplaceData, setMarketplaceData] = useState({
    allData: [],
    deployData: {
      appName: "",
      cluster: "",
      clusterName: "",
      nameSpace: "",
      nodegroups: "",
      chartVersion: "",
      chartValues: "",
      deployContent: false,
      cancelContent: false,
      clickedCancel: false,
    },
    selectedCharts: []
  });
  const [checkRefreshSignup, setCheckRefreshSignup] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const [sideOpenDrawer, setSideOpenDrawer] = useState(false);
  const [refreshBoardList, setRefreshBoardList] = useState(false);
  const [isStill, setIsStill] = useState(true);
  const [allClusters, setAllClusters] = useState([...clusters]);
  const [fetchingClusterPrerequisites, setFetchingClusterPrerequisites] = useState(false);
  const [currentCluster, setCurrentCluster] = useState(JSON.parse(JSON.stringify({ ...initialCluster })));
  const [updateCluster, setUpdateCluster] = useState(JSON.parse(JSON.stringify({ ...initialCluster })));
  const [oneClickCluster, setOneClickCluster] = useState(JSON.parse(JSON.stringify({ ...initialCluster })));
  const [toggleClusterOverviewLogs, setToggleClusterOverviewLogs] = useState("Cluster Details");
  const [createProject, setCreateProject] = useState(false);
  const [refreshProjectData, setRefreshProjectData] = useState(false);
  const [initialWorkflowData, setInitialWorkflowData] = useState();
  // const [workflowData, setWorkflowData] = useState({
  //   // preBuildToolsData: [
  //   //   {toolName: "Secret Detection", img: "", description: "Click to configure", secretDetectionTrigger: false, onTop: true, order: 0},
  //   //   {toolName: "Trivy", img: "", description: "Click to configure", secretDetectionTrigger: false, onTop: false, order: 1},
  //   // ],
  //   // postBuildToolsData: [],
  // });
  const [workflowData, setWorkflowData] = useState(null);
  const [workflowTypeExecution, setWorkflowTypeExecution] = useState({
    source: true,
    build: false,
    deploy: false,
  });
  const [currentWorkflowData, setCurrentWorkflowData] = useState({});
  const [workflowStatus, setWorkflowStatus] = useState(false);
  const [keepLookingForWorkflowStatus, setKeepLookingForWorkflowStatus] = useState(true);
  const [keepLookingForWorkflowStepsStatus, setKeepLookingForWorkflowStepsStatus] = useState(true);
  const [workflowStepsStatus, setWorkflowStepsStatus] = useState({});
  const [workflowNodeData, setWorkflowNodeData] = useState(JSON.parse(JSON.stringify(tempNodeData)));
  const [workflowEdgesData, setWorkflowEdgesData] = useState(JSON.parse(JSON.stringify(tempEdgesData)));
  const [enableWorkflowSaveButton, setEnableWorkflowSaveButton] = useState(false);
  const [workflowExecuteNow, setWorkflowExecuteNow] = useState(false);
  const [workflowSaveButton, setWorkflowSaveButton] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(JSON.parse(JSON.stringify(initialProject)));
  const [currentColumns, setCurrentColumns] = useState(columns);
  const [currentTasks, setCurrentTasks] = useState(tasks);
  const [clusterTabSelected, setClusterTabSelected] = useState(false);
  const [bootstrapLogs, setBootstrapLogs] = useState(false);
  const [gitHubAccessToken, setGitHubAccessToken] = useState(localStorage.getItem("gitHubAccessToken") || null);
  const [gitLabAccessToken, setGitLabAccessToken] = useState(localStorage.getItem("gitLabAccessToken") || null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOneClick, setIsOneClick] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [selectedAppId, setSelectedAppId] = useState();
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState("");
  const [rbacFormVisible, setRbacformVisible] = useState(false);
  const [callHelmEdit, setCallHelmEdit] = useState(false); //to call edit helm method
  const [callHelmDelete, setCallHelmDelete] = useState(false); //to call delete helm method
  const [callHelmCompare, setCallHelmCompare] = useState(false); // to compare helms
  const [callHelmPreview, setCallHelmPreview] = useState(false); //to give a preview of updated helms
  const [callHelmRollback, setCallHelmRollback] = useState(false); //to rollback helm to the selected version
  const [callYamlApi, setCallYamlApi] = useState(false); //call yaml api

  let location = useLocation();


  useEffect(() => {
    const cluster = isUpdate ? localStorage.getItem("updateCluster") : isOneClick ? localStorage.getItem("oneClickCluster") : localStorage.getItem("Cluster");
    const project = localStorage.getItem("Project");
    const workflow = localStorage.getItem("Workflow");
    const clusterTabSelected = localStorage.getItem("clusterTabSelected");
    const marketplace = localStorage.getItem("marketplaceData");

    // console.log('storedAuth in context :', storedAuth);
    // console.log('cluster in context :', storedAuth);
    // console.log('clusterTabSelected in context :', storedAuth);


    if (JSON.parse(cluster)) {
      if (isUpdate) {
        console.log("Enters...update", cluster);
        setUpdateCluster({ ...JSON.parse(cluster) });
      }
      else if (isOneClick) {
        setOneClickCluster({ ...JSON.parse(cluster) });
      }
      else {
        console.log("Cluster creation...", cluster);
        setCurrentCluster({ ...JSON.parse(cluster) });
      }
    }
    if (JSON.parse(project)) {
      setCurrentProject({ ...JSON.parse(project) });
    }
    if (JSON.parse(workflow)) {
      setCurrentWorkflowData({ ...JSON.parse(workflow) });
    }
    if (clusterTabSelected) {
      setClusterTabSelected(clusterTabSelected);
    }
    if (marketplace) {
      setMarketplaceData({ ...JSON.parse(marketplace) });
    }
  }, [isUpdate, isOneClick]);

  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuth");
    console.log("Setting auth: ", storedAuth);

    if (storedAuth) {
      console.log('storedAuth inside if :', storedAuth);
      setIsAuth(storedAuth);
    }
  }, []);

  useEffect(() => {
    const update = JSON.parse(localStorage.getItem('isUpdate'));
    if (update) {
      setIsUpdate(update);
    }
    const oneclick = JSON.parse(localStorage.getItem('isOneClick'));
    if (oneclick) {
      setIsOneClick(oneclick);
    }
  }, []);

  useEffect(() => {
    console.log("Calling api again: ", isAuth)
    if (isAuth) {
      console.log("Profile api called");
      // if (localStorage.getItem('profile-data') === null) {
      api.get(process.env.REACT_APP_API_URI + '/api/users/user_details')
        .then((res) => {
          let data = res.data;
          if (data.status) {
            let myData = {
              id: data.data.id,
              firstName: data.data.first_name,
              lastName: data.data.last_name,
              email: data.data.email,
              phone: data.data.phone,
              organisation: data.data.organisation.display_name,
              roles: data.data.roles,
              permissions: data.data.permissions
            }

            // check for super user
            if (data.data.is_superuser) {
              myData["super"] = true;
            }
            myData.permissions = [...myData.permissions,/*"Can view secret",*/ /*"Can create secret",*/ /*"Can update secret"*/ /*,"Can delete secret"*/];
            console.log("myData :", myData);
            localStorage.setItem('profile-data', JSON.stringify(myData));
            setProfileData(data.data);
            setCheckRefreshSignup(true);
          }
          else {
            let err = formatErrors(data.error_details);
            console.log("Error in /user_details :", err);
            // alert(`Error: ${err}`);
          }
        })
        .catch((err) => {
          console.log("Found error in user_details api :", err);
          if (err === "Try without login") {
            setCheckTryWithoutLogin(true);
            setCheckRefreshSignup(true);
            setProfileData(dummyProfileData);
          }
        });
    }
  }, [isAuth, refreshUserDetails]);


  const clearContext = () => {
    setIsAuth(false);
    setOpenRightDrawer({
      drawerType: '',
      drawerProps: {
        data: null,
        columnId: null,
      },
    });
    setOpenModel({
      modalType: '',
      modalProps: {
        data: null,
        columnId: null,
      },
    });
    // setDummyClusterData(dummy_cluster_data);
    setRefreshUserDetails(false);
    setProfileData();
    setTriggeredAddRepo(false);
    setMarketplaceData({
      deployData: {
        appName: "",
        cluster: "",
        nameSpace: "",
        nodegroups: "",
        chartVersion: "",
        chartValues: "",
        deployContent: false,
        cancelContent: false,
        clickedCancel: false,
      }
    });
    setSideCollapsed(false);
    setSideOpenDrawer(false);
    setIsStill(true);
    setAllClusters([...clusters]);
    setCurrentCluster(JSON.parse(JSON.stringify({ ...initialCluster })));
    setUpdateCluster(JSON.parse(JSON.stringify({ ...initialCluster })));
    setOneClickCluster(JSON.parse(JSON.stringify({ ...initialCluster })));
    setToggleClusterOverviewLogs("Cluster Details");
    setAllProjects([]);
    setCurrentProject(JSON.parse(JSON.stringify(initialProject)));
    setRefreshProjectData(false);
    setCurrentColumns(columns);
    setCurrentTasks(tasks);
    setClusterTabSelected(false);
    setBootstrapLogs(false);
    setTooltip(false);
    setTriggeredAddRepo(false);
    setTrigerNodgroups(false);
    setTrigerMarketplaceList(false);
    setTrigerInviteUser(false);
    setCheckTryWithoutLogin(false);
    setNodgroupsUpdateData();
    setWorkflowData(null);
    setWorkflowTypeExecution({
      source: true,
      build: false,
      deploy: false,
    });
    setCurrentWorkflowData({});
    setWorkflowStatus(false);
    setKeepLookingForWorkflowStatus(false);
    setKeepLookingForWorkflowStepsStatus(false);
    setWorkflowStepsStatus({});
    setWorkflowNodeData(JSON.parse(JSON.stringify(tempNodeData)));
    setWorkflowEdgesData(JSON.parse(JSON.stringify(tempEdgesData)));
    setWorkflowExecuteNow(false);
    setWorkflowSaveButton(false);
    setEnableWorkflowSaveButton(false);
    setInitialWorkflowData();
    setGitHubAccessToken(localStorage.getItem("gitHubAccessToken") || null);
    setGitLabAccessToken(localStorage.getItem("gitLabAccessToken") || null);
    setRbacformVisible(false);
  }

  function logout(from) {
    googleLogout();
    setIsAuth(false);
    let appVersion = localStorage.getItem("appVersion");
    localStorage.clear();
    localStorage.setItem("appVersion", appVersion);
    clearContext();
    navigate('/signin', {
      state: { from: from }
    });
  }

  const shouldContinue = async (operations) => {
    const handleConfirm = () => {
      if (operations) {
        return new Promise((resolve) => {
          setOpenModel({
            modalType: "CONFIRM_ACTION",
            modalProps: {
              title: <IntlMessages id="Unsaved.message.title" />,
              subTitle: <IntlMessages id="Unsaved.message.subtitle" />,
              cancelButton: "Cancel",
              saveButton: "Confirm",
              onConfirm: () => {
                setCallHelmCompare(false);
                setCallHelmEdit(false);
                setCallHelmPreview(false);
                setCallHelmRollback(false);
                setOpenModel({
                  modalType: null,
                });
                resolve(true);
                // navigate(`/clusters/cluster/${location.pathname.split("/")[3]}/marketplace/${location.pathname.split("/")[5]}`);
              },
              onCancel: () => {
                setOpenModel({
                  modalType: null,
                });
                resolve(false);
              }
            }
          });
        })
      }
      else {
        return true;
      }
    }
    let isContinue = await handleConfirm();
    console.log("REsolving isContinue...", isContinue);
    return isContinue;
  }

  let vals = {
    logout,
    clearContext,
    initialCluster,
    initialProject,
    isAuth, setIsAuth,
    checkRefreshSignup, setCheckRefreshSignup,
    checkTryWithoutLogin, setCheckTryWithoutLogin,
    refreshUserDetails, setRefreshUserDetails,
    profileData, setProfileData,
    openRightDrawer, setOpenRightDrawer,
    openModel, setOpenModel,
    allClusters, setAllClusters,
    clusterTabSelected, setClusterTabSelected,
    fetchingClusterPrerequisites, setFetchingClusterPrerequisites,
    currentCluster, setCurrentCluster,
    allProjects, setAllProjects,
    createProject, setCreateProject,
    currentProject, setCurrentProject,
    refreshProjectData, setRefreshProjectData,
    tempNodeData, tempEdgesData,
    workflowData, setWorkflowData,
    workflowTypeExecution, setWorkflowTypeExecution,
    workflowNodeData, setWorkflowNodeData,
    workflowEdgesData, setWorkflowEdgesData,
    currentWorkflowData, setCurrentWorkflowData,
    workflowStatus, setWorkflowStatus,
    keepLookingForWorkflowStatus, setKeepLookingForWorkflowStatus,
    keepLookingForWorkflowStepsStatus, setKeepLookingForWorkflowStepsStatus,
    workflowStepsStatus, setWorkflowStepsStatus,
    workflowExecuteNow, setWorkflowExecuteNow,
    workflowSaveButton, setWorkflowSaveButton,
    initialWorkflowData, setInitialWorkflowData,
    enableWorkflowSaveButton, setEnableWorkflowSaveButton,
    currentColumns, setCurrentColumns,
    currentTasks, setCurrentTasks,
    bootstrapLogs, setBootstrapLogs,
    gitHubAccessToken, setGitHubAccessToken,
    gitLabAccessToken, setGitLabAccessToken,
    sideCollapsed, setSideCollapsed,
    sideOpenDrawer, setSideOpenDrawer,
    isStill, setIsStill,
    convertToFrontend, convertToBackend,
    triggeredAddRepo, setTriggeredAddRepo,
    trigerNodgroups, setTrigerNodgroups,
    triggerMarketplace, setTriggerMarketplace,
    trigerMarketplaceList, setTrigerMarketplaceList,
    trigerInviteUser, setTrigerInviteUser,
    tooltip, setTooltip,
    marketplaceData, setMarketplaceData,
    toggleClusterOverviewLogs, setToggleClusterOverviewLogs,
    refreshBoardList, setRefreshBoardList,
    nodgroupsUpdateData, setNodgroupsUpdateData,
    updateCluster, setUpdateCluster,
    isUpdate, setIsUpdate,
    isOneClick, setIsOneClick,
    oneClickCluster, setOneClickCluster,
    fetchingDetails, setFetchingDetails,
    selectedAppId, setSelectedAppId,
    rbacFormVisible, setRbacformVisible,
    callHelmEdit, setCallHelmEdit,
    callHelmDelete, setCallHelmDelete,
    callHelmCompare, setCallHelmCompare,
    callHelmPreview, setCallHelmPreview,
    callHelmRollback, setCallHelmRollback,
    currentWorkflowStatus, setCurrentWorkflowStatus,
    rbacFormVisible, setRbacformVisible,
    shouldContinue, fetching, dispatch,
    callYamlApi, setCallYamlApi
  }

  return (
    <AppContext.Provider value={vals}>
      {props.children}
    </AppContext.Provider>
  );
};